// @flow
import * as React from 'react';
import arrayMutators from 'final-form-arrays';
import { httpDelete, httpGet, httpPost } from 'app/service/http';
import {
  addRoleToUserSuccess,
  closeNewEmailModal,
  closePasswordResetModal,
  closeRolesModal,
  openNewEmailModal,
  openPasswordResetModal,
  removeRoleFromUserFailure,
  removeRoleFromUserSuccess,
  setNewEmailToUserSuccess,
  userLoadFailure,
  userLoading,
  userLoadSuccess,
  userSaveSuccess,
} from 'app/ui/user-manager/user/details/actions';
import { initialValues, reducer } from 'app/ui/user-manager/user/details/reducer';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import OverlayContext from 'app/ui/common/modal/OverlayContext';
import { Form } from 'react-final-form';
import { formatISODateToDate } from 'app/utils/format/dateTimeFormatter';
import RolesModal from 'app/ui/user-manager/user/roles/RolesModal';
import NewEmailModal from 'app/ui/user-manager/user/NewEmailModal';
import UserRolesSection from 'app/ui/user-manager/user/details/UserRolesSection';
import HorizontalFormRow from 'app/ui/components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from 'app/ui/components/horizontal-form/HorizontalFormLabel';
import HorizontalFormTextField from 'app/ui/components/horizontal-form/HorizontalFormTextField';
import HorizontalFormDropdownField from 'app/ui/components/horizontal-form/HorizontalFormDropdownField';
import { genderDropdownOptions } from 'app/ui/common/util/dropdownUtil';
import { FORM_ERROR } from 'final-form';
import { dateToISODate } from 'app/utils/date/dateUtil';
import SubmitErrors from 'app/ui/components/SubmitErrors';
import { validateUser } from 'app/ui/user-manager/user/userValidator';
import PasswordResetModal from 'app/ui/user-manager/user/PasswordResetModal';
import UserContext from 'app/ui/user-manager/user/context/UserContext';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import { mapErrorResponse } from 'app/utils/error/webGatewayResponseErrorMapper';
import { useParams } from 'react-router-dom';

const UserDetailsPage = () => {
  const { userId } = useParams();
  const { user } = React.useContext(UserContext);
  const [state, dispatch] = React.useReducer(reducer, initialValues);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { showSuccessOverlay, showErrorOverlay } = React.useContext(OverlayContext);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);

  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        dispatch(userLoading());
        const clonedUser = { ...user };
        clonedUser.roles = await httpGet(`/v1/user/${user.id}/role`);
        dispatch(userLoadSuccess(clonedUser));
      } catch (error) {
        dispatch(userLoadFailure(error));
      }
    };
    // wait for the UserContext to load the user and only then fetch the additional data
    if (user) {
      executeWithSpinner(fetchUserData());
    }
  }, [executeWithSpinner, user]);

  const submitUserPatchRequest = values => {
    const patchUser = async () => {
      try {
        const userProfileBody = {
          accountId: values.user.id,
          firstName: values.user.firstName ? values.user.firstName : '',
          lastName: values.user.lastName ? values.user.lastName : '',
          dateOfBirth: values.user.birthday ? dateToISODate(values.user.birthday) : null,
          gender: genderToUpdsFormat(values.user.gender),
        };

        const updatedUser = (await httpPost('/v1/user/profile', userProfileBody))[0];

        dispatch(
          userSaveSuccess({
            ...values.user,
            birthday: updatedUser.dateOfBirth ? updatedUser.dateOfBirth : '',
            firstName: updatedUser.firstName ? updatedUser.firstName : '',
            lastName: updatedUser.lastName ? updatedUser.lastName : '',
            gender: updatedUser.gender ? updatedUser.gender.charAt(0) : '',
          })
        );
      } catch (error) {
        return {
          [FORM_ERROR]: mapErrorResponse(error),
        };
      }
    };
    return executeWithSpinner(patchUser());
  };

  const genderToUpdsFormat = (gender: string) => {
    return gender === 'M'
      ? 'MALE'
      : gender === 'F'
      ? 'FEMALE'
      : gender === 'D'
      ? 'NON_BINARY'
      : null;
  };

  const removeRoleFromUser = (role: string) => {
    executeWithSpinner(
      httpDelete(`/v1/user/${userId}/role/${role}`)
        .then(roles => dispatch(removeRoleFromUserSuccess(roles)))
        .catch(error => dispatch(removeRoleFromUserFailure(error && error.message)))
    );
  };

  const removeRoleEntityPairFromUser = (role: string, entityId: string) => {
    executeWithSpinner(
      httpDelete(`/v1/user/${userId}/role/${role}/entity/${entityId}`)
        .then(roles => dispatch(removeRoleFromUserSuccess(roles)))
        .catch(error => dispatch(removeRoleFromUserFailure(error && error.message)))
    );
  };

  const resetForm = form => {
    // https://github.com/final-form/final-form/issues/151
    form.setConfig('keepDirtyOnReinitialize', false);
    form.reset();
    form.setConfig('keepDirtyOnReinitialize', true);
  };

  /**
   * This function is intended to be called after the OPS Admin has confirmed
   * that a Password Reset email should be sent to the User.
   */
  const sendPasswordResetEmail = async () => {
    try {
      dispatch(closePasswordResetModal());
      const postBody = { email: state.user.email };
      await executeWithSpinner(httpPost(`/v1/password-reset`, postBody));
      showSuccessOverlay('Password Reset email successfully sent to the user');
    } catch (error) {
      showErrorOverlay(`Password Reset failed. Message: ${error.message}`);
    }
  };

  const editMode = false;

  const userManagerUrl = window.location.hostname.includes('coffee')
    ? `https://monolith.staging.test.co.egym.coffee/manage/user/q?accountId=${user ? user.id : ''}`
    : `https://www.egym.com/manage/user/q?accountId=${user ? user.id : ''}`;

  return (
    <>
      {state.user && (
        <>
          <h5>User Information</h5>
          <p>
            Please change personal data of the user in the EGYM User Manager{' '}
            <a target="_blank" rel="noopener noreferrer" href={userManagerUrl}>
              {userManagerUrl}
            </a>
          </p>
          <Form
            onSubmit={submitUserPatchRequest}
            validate={validateUser}
            // When the 'keepDirtyOnReinitialize' flag is true,
            // then the form does not reset to the 'initialValues' while submitting or if there are submitErrors
            keepDirtyOnReinitialize
            initialValues={{
              user: {
                ...state.user,
                birthday: formatISODateToDate(state.user.birthday),
              },
              signupPage: state.signupPage,
            }}
            render={({ submitError, handleSubmit, submitting, pristine, form }) => (
              <form onSubmit={handleSubmit}>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Qualitrain User ID" />
                  <HorizontalFormTextField
                    controlId="user.id"
                    controlLabel="Qualitrain User ID"
                    placeholder="Qualitrain User ID"
                    columnWidth={6}
                    disabled={true}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Activation code" />
                  <HorizontalFormTextField
                    controlId="user.activationCode"
                    controlLabel="Activation code"
                    placeholder="Activation code"
                    columnWidth={4}
                    disabled={true}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Name" />
                  <HorizontalFormTextField
                    controlId="user.firstName"
                    controlLabel="First Name"
                    placeholder="First Name"
                    columnWidth={4}
                    disabled={!isEditable || state.user.email}
                  />
                  <HorizontalFormTextField
                    controlId="user.lastName"
                    controlLabel="Last Name"
                    placeholder="Last Name"
                    columnWidth={5}
                    disabled={!isEditable || state.user.email}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Date of Birth" />
                  <HorizontalFormTextField
                    controlId="user.birthday"
                    controlLabel="Date of Birth"
                    placeholder="DD-MM-YYYY"
                    columnWidth={4}
                    disabled={!isEditable || state.user.email}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Gender" />
                  <HorizontalFormDropdownField
                    controlId="user.gender"
                    controlLabel="Gender"
                    placeholder="Gender"
                    columnWidth={4}
                    disabled={!isEditable || state.user.email}
                    options={genderDropdownOptions()}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Billing Address" />
                  <HorizontalFormTextField
                    controlId="user.addressDto.street"
                    controlLabel="Street"
                    placeholder="Street"
                    columnWidth={3}
                    disabled={!editMode || !isEditable}
                  />
                  <HorizontalFormTextField
                    controlId="user.addressDto.houseNumber"
                    controlLabel="Nr."
                    placeholder="Nr."
                    columnWidth={1}
                    disabled={!editMode || !isEditable}
                  />
                  <HorizontalFormTextField
                    controlId="user.addressDto.zipCode"
                    controlLabel="ZIP"
                    placeholder="ZIP"
                    columnWidth={2}
                    disabled={!editMode || !isEditable}
                  />
                  <HorizontalFormTextField
                    controlId="user.addressDto.city"
                    controlLabel="City"
                    placeholder="City"
                    columnWidth={3}
                    disabled={!editMode || !isEditable}
                  />
                </HorizontalFormRow>
                {submitError && <SubmitErrors {...submitError} />}

                <HorizontalFormRow>
                  <div className="col-md-8 px-1" />
                  <div className="col-md-2 px-1">
                    <button
                      className="btn d-grid w-100 btn-secondary mb-2"
                      type="submit"
                      disabled={submitting || pristine || !isEditable}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-md-2 px-1">
                    <button
                      className="btn d-grid w-100 btn-primary mb-2"
                      onClick={() => resetForm(form)}
                      disabled={submitting || pristine || !isEditable}
                    >
                      Cancel
                    </button>
                  </div>
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Email Address" />
                  <HorizontalFormTextField
                    controlId="user.email"
                    controlLabel="Email Address"
                    placeholder="Email Address"
                    columnWidth={5}
                    disabled={true}
                    helpTextAfter={
                      state.user.newEmail
                        ? `pending email change request to ${state.user.newEmail}`
                        : ''
                    }
                  />
                  {state.user.email && (
                    <>
                      <div className="col-md-2 px-1">
                        <button
                          type="button"
                          className="btn d-grid w-100 btn-secondary mb-2"
                          onClick={() => dispatch(openNewEmailModal())}
                          disabled={!isEditable}
                        >
                          Change e-mail
                        </button>
                      </div>

                      <div className="col-md-2 px-1">
                        <button
                          type="button"
                          className="btn d-grid w-100 btn-secondary mb-2"
                          onClick={() => dispatch(openPasswordResetModal())}
                          disabled={!isEditable}
                        >
                          Reset Password
                        </button>
                      </div>
                    </>
                  )}
                </HorizontalFormRow>
                <p className="text-black-50">
                  When you change the email address, an double opt in Email is send to new email
                  address. Only after the Link in this double opt in Email is clicked by the user
                  the new Email is saved. Also an email is sent to the old Email address that
                  informs, that email address change request was made.
                </p>
              </form>
            )}
          />

          <Form
            onSubmit={() => {}}
            mutators={{ ...arrayMutators }}
            initialValues={{
              user: {
                ...state.user,
              },
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <UserRolesSection
                  removeRoleFromUser={removeRoleFromUser}
                  removeRoleEntityPairFromUser={removeRoleEntityPairFromUser}
                  dispatch={dispatch}
                  isEditable={isEditable}
                />
              </form>
            )}
          />
        </>
      )}
      {state.errorMessage && (
        <pre className="alert alert-danger">{JSON.stringify(state.errorMessage, null, 2)}</pre>
      )}
      {state.isRolesModalOpen && (
        <RolesModal
          isOpen={true}
          onRequestClose={() => dispatch(closeRolesModal())}
          onAddRoleToUserSuccess={role => dispatch(addRoleToUserSuccess(role))}
          selectedUser={state.user}
        />
      )}
      {state.isNewEmailModalOpen && (
        <NewEmailModal
          isOpen={true}
          onRequestClose={() => dispatch(closeNewEmailModal())}
          onSetNewEmailToUserSuccess={newEmail => dispatch(setNewEmailToUserSuccess(newEmail))}
          user={state.user}
        />
      )}
      {state.isPasswordResetModalOpen && (
        <PasswordResetModal
          onConfirm={sendPasswordResetEmail}
          onCancel={() => dispatch(closePasswordResetModal())}
        />
      )}
    </>
  );
};

export default UserDetailsPage;
