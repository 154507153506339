import React, { useEffect } from 'react';
import emoji from 'app/styles/img/joke-emoji.svg';
import AuthenticationContext from '../../common/authentication/AuthenticationContext';
import styles from './Joke.module.scss';
import jokes from './jokes.json';

const Joke = props => {
  const [joke, setJoke] = React.useState(null);
  const { isAuthenticated } = React.useContext(AuthenticationContext);

  useEffect(() => {
    if (!isAuthenticated()) {
      setJoke(jokes.data[Math.floor(Math.random() * jokes.data.length)]);
    }
  }, [isAuthenticated]);

  return (
    <div className="col-sm-6 px-1 bg-primary d-flex align-items-center justify-content-center ">
      <div className={`container mt-5 ${styles.contentWidth}`}>
        <div className="text-center">
          <div className={styles.title}>Dad Joke Of the Day</div>
          <div className={`text-white ${styles.joke}`}>{joke}</div>
          <img src={emoji} alt="Emoji" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Joke;
