import React from 'react';
import AllowlistMigrationMediator from './allowlist-migration/AllowlistMigrationMediator';

const AllowlistMigrationForm = ({ sfAccountCanonicalId }) => {
  const [showModal, setShowModal] = React.useState(false);
  const onClose = () => setShowModal(false);

  return (
    <div className="mb-4">
      <h5>Allowlist migration</h5>
      <p>
        This migration will allow you to move your existing allowlist to be now managed by HRIS.
      </p>
      <div className="col-sm-3 px-1 ps-0">
        <button className="btn btn-secondary w-100" onClick={() => setShowModal(true)}>
          Migrate Allowlist
        </button>
      </div>
      <AllowlistMigrationMediator
        isOpen={showModal}
        onClose={onClose}
        sfAccountCanonicalId={sfAccountCanonicalId}
      />
    </div>
  );
};

export default AllowlistMigrationForm;
