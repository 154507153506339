import { NetworkType } from '../context/PayoutContext';

/**
 * Extracts last segment from url path
 *
 * @param fullPath
 * @returns {string}
 * @constructor
 */
export const ExtractLastPathSegment = (fullPath: string) => {
  const path = fullPath.replace(/\/$/, ''); // Replace trailing slash from path
  return path.substring(path.lastIndexOf('/') + 1);
};

export const networkStringRepresentation = (network: NetworkType) => {
  switch (network) {
    case NetworkType.DACH:
      return 'DACH';
    case NetworkType.FR_BE:
      return 'France & Belgium';
    case NetworkType.US:
      return 'US';
    default:
      return '';
  }
};
