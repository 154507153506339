// @flow
import * as React from 'react';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { PaymentsDashboardProvider, usePaymentsDashboard } from './PaymentsDashboardContext';
import PaymentFilters from './PaymentFilters';
import PaymentPeriodSelector from './PaymentPeriodSelector';
import PaymentsDashboardTabs from './PaymentsDashboardTabs';

const PaymentsDashboard = () => {
  const { chargeStats, errorMessage } = usePaymentsDashboard();

  return (
    <>
      <PaymentPeriodSelector />
      <PaymentFilters />

      {chargeStats && <PaymentsDashboardTabs />}

      {errorMessage && (
        <div className="alert alert-danger mt-4">
          {errorMessage.message ? errorMessage.message : errorMessage}
        </div>
      )}
    </>
  );
};

const PaymentsDashboardWithContext = props => (
  <PaymentsDashboardProvider>
    <PaymentsDashboard {...props} />
  </PaymentsDashboardProvider>
);

export default withPageTemplate(PaymentsDashboardWithContext, {
  headerTitle: 'B2C Payment Overview',
});
