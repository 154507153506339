import React from 'react';
import { Form } from 'react-final-form';
import { OfferExpandableList } from './OfferExpandableList';

const LinkedOffer = ({ offers, headingText }) => {
  return (
    <div className="mt-4">
      <Form
        onSubmit={() => {}}
        initialValues={{}}
        render={({ submitError, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              <div className="text-primary">
                <h5>{headingText}</h5>
              </div>
              <div>
                <table className="table mt-2">
                  <thead className="table-light">
                    <tr>
                      <th>Offer ID</th>
                      <th>SF ACC ID</th>
                      <th>SC ACC Name</th>
                      <th>Price</th>
                      <th>Offer Type</th>
                      <th>Available From</th>
                      <th>Available To</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <OfferExpandableList offers={offers} withRemoveButton={false} />
                  </tbody>
                </table>
              </div>
            </div>
            {submitError && (
              <div className="alert alert-danger">{JSON.stringify(submitError, null, 2)}</div>
            )}
          </form>
        )}
      />
    </div>
  );
};

export default LinkedOffer;
