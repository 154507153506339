import React, { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import HorizontalFormRow from '../../components/horizontal-form/HorizontalFormRow';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import CompanyFeaturesContext, { COMPANY_FEATURES } from './context/CompanyFeaturesContext';

const ALWAYS_DISABLED_FEATURES = [
  {
    feature: COMPANY_FEATURES.CORPORATE_CONTROLLING.name,
    reason:
      'To change this feature, navigate to the HRIS & Eligibility Rules tab and check the HRIS connection status.',
  },
];

const FeatureForm = () => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const { features, enabledFeatures, updateFeatures, loading } = useContext(CompanyFeaturesContext);

  const pristineValidator = currentModifiedFeatures => {
    const enabledFetchedFeatures = features
      ?.filter(featureDetail => featureDetail.enabled)
      .map(featureDetail => featureDetail.feature);

    if (enabledFetchedFeatures?.length !== currentModifiedFeatures?.length) {
      return false;
    }

    const fetchFeatureSet = new Set(enabledFetchedFeatures);
    const modifyFeatureSet = new Set(currentModifiedFeatures);

    for (const feature of fetchFeatureSet) {
      if (!modifyFeatureSet.has(feature)) {
        return false;
      }
    }

    return true;
  };

  const createRequestBody = values => {
    return features.map(featureData => ({
      ...featureData,
      enabled: values.featureOptions.includes(featureData.feature),
    }));
  };

  const onSubmit = async values => {
    const request = createRequestBody(values);
    await updateFeatures(request);
  };

  const getDisabled = feature => {
    const disabledFeature = ALWAYS_DISABLED_FEATURES.find(({ feature: feat }) => feat === feature);
    const disabled = !isEditable || Boolean(disabledFeature);
    const showReason = disabled && isEditable && disabledFeature?.reason;
    return {
      disabled,
      reason: showReason && disabledFeature.reason,
    };
  };

  if (loading) {
    return <></>;
  }

  return (
    <Form
      initialValues={{ featureOptions: [...enabledFeatures] }}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        const customPristineChecker = pristineValidator(values?.featureOptions);
        return (
          <form onSubmit={handleSubmit}>
            <div className="mt-5">
              <h5>Features active for this company</h5>
              <br />
              {features?.map(({ feature }) => {
                const { name, description } = COMPANY_FEATURES[feature];
                const { disabled, reason: disabledReason } = getDisabled(feature);
                return (
                  <div key={feature}>
                    <HorizontalFormRow>
                      <label className={`${disabled ? 'text-muted' : ''}`}>
                        <Field
                          name="featureOptions"
                          component="input"
                          type="checkbox"
                          value={feature}
                          className={`simple-check-box me-3`}
                          disabled={disabled}
                        />
                        {name || feature}
                        {disabledReason && (
                          <i
                            className="fas fa-info-circle text-secondary cursor-pointer ps-3"
                            title={disabledReason}
                          ></i>
                        )}
                      </label>
                      <p className={`mt-1 col-sm-12 px-1 ${disabled ? 'text-muted' : ''}`}>
                        {description}
                      </p>
                      <br />
                    </HorizontalFormRow>
                  </div>
                );
              })}
            </div>
            <div className="col-sm-3">
              <button
                className="btn d-grid w-100 btn-secondary px-1"
                type="submit"
                disabled={customPristineChecker}
              >
                Save
              </button>
            </div>
            <br />
          </form>
        );
      }}
    />
  );
};

export default FeatureForm;
