// @flow
import * as React from 'react';
import { useState, useReducer, useCallback } from 'react';
import { httpGet, httpPost, httpPut, httpPostUpload, httpDelete } from 'app/service/http/index';
import {
  reducer,
  initialState,
  INIT_STATE,
  CREATE_SIGN_UP_PAGE_STATE,
  EDIT_SIGN_UP_PAGE_STATE,
} from 'app/ui/companies/self-signup-manager/reducer';
import {
  startCreatingNewSignupPage,
  saveNewSignupPageSuccess,
  startEditingExistingSignupPage,
  saveExistingSignupPageSuccess,
} from 'app/ui/companies/self-signup-manager/actions';
import SelfSignupForm from 'app/ui/companies/self-signup-manager/SelfSignupForm';
import { convertSignupPageFrontendModelToSignupPage } from 'app/ui/companies/self-signup-manager/converter';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { notificationService } from 'app/service/notification';
import type { SFAddress } from '../offer-manager/offer-details/types';

const SelfSignupManager = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [token, setToken] = useState('');
  const [offers, setOffers] = useState([]);
  const [offerIdToAdd, setOfferIdToAdd] = useState('');
  const [coord, setCoord] = useState(null);
  const [locationValidationResult, setLocationValidationResult] = useState(null);
  const [locationValidationError, setLocationValidationError] = useState(null);
  const [multiEntitySignupPages, setMultiEntitySignupPages] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentUrlParams = new URLSearchParams(location.search.substring(1));
  const setErrorMessage = useCallback(errorMessage => {
    notificationService.push({
      message: errorMessage,
      variant: 'error',
    });
  }, []);

  /**
   * Handler for the "save" button when a NEW sign-up page is created for a company.
   */
  const createSelfSignupPage = signupPageModel => {
    const backendModel = convertSignupPageFrontendModelToSignupPage(signupPageModel, coord);
    const createPage = async () => {
      const errMessage = await checkSearchTermWithCoord(signupPageModel);
      if (errMessage) {
        setErrorMessage(errMessage);
        return;
      }
      try {
        let signupPagePostResponse;
        try {
          signupPagePostResponse = await httpPost('/v2/signup-page', backendModel);
        } catch (error) {
          // this error is ignored here, we go by the generic handling in service/http
        }
        setToken(signupPagePostResponse.token);
        if (signupPageModel.logo.file != null) {
          try {
            await httpPostUpload(
              `/v1/signup-page/${signupPagePostResponse.token}/logo`,
              signupPageModel.logo.file
            );
          } catch (error) {
            populateErrorMessage(error);
          }
        }

        if (offerIdToAdd) {
          linkSignupPageAndOffer(signupPagePostResponse.token, offerIdToAdd);
        }
        const signupPageGetResponse = await httpGet(
          `/v2/signup-page/${signupPagePostResponse.token}`
        );
        dispatch(saveNewSignupPageSuccess(signupPageGetResponse));

        //Prevents refresh into the creation mode
        const urlParams = new URLSearchParams();
        urlParams.set('token', signupPageGetResponse.token);
        navigate(`?${urlParams.toString()}`, { replace: true });
      } catch (error) {
        populateErrorMessage(error);
      }
    };
    return executeWithSpinner(createPage());
  };

  const populateErrorMessage = useCallback(
    error => {
      error && error.message
        ? setErrorMessage(error.message)
        : setErrorMessage(JSON.stringify(error));
    },
    [setErrorMessage]
  );

  const setCompanyNames = offersParam => {
    const promises = offersParam.map(offer =>
      httpGet(
        `/v1/salesforce/company?sfAccountCanonicalId=${offer.sfAccountCanonicalId}&minified=true`
      )
        .then(resp => ({ ...offer, sfAccountCanonicalName: resp.Name }))
        .catch(() => ({ ...offer, sfAccountCanonicalName: '' }))
    );

    Promise.all(promises).then(updatedOffers => setOffers(updatedOffers));
  };

  const loadOffersByToken = useCallback(
    signupToken => {
      httpGet(`/admin/v2/signup-page/${signupToken}/offer`)
        .then(offersResp => setCompanyNames(offersResp))
        .catch(error => populateErrorMessage(error));
    },
    [populateErrorMessage]
  );

  const linkSignupPageAndOffer = (token, offerIdToAdd) => {
    httpPost(`/v2/signup-page/${token}/offer/${offerIdToAdd}`)
      .then(resp => {
        setOfferIdToAdd('');
        loadOffersByToken(token);
      })
      .catch(error => populateErrorMessage(error));
  };

  const getMultiEntitySingupPages = useCallback(
    signupToken => {
      httpGet(`/v1/multi-entity-signup-page/signup-page-token/${signupToken}`)
        .then(resp => {
          setMultiEntitySignupPages(resp);
        })
        .catch(error => populateErrorMessage(error));
    },
    [setMultiEntitySignupPages, populateErrorMessage]
  );

  const unlinkSignupPageAndOffer = offerId => {
    httpDelete(`/v2/signup-page/${token}/offer/${offerId}`)
      .then(() => loadOffersByToken(token))
      .catch(error => populateErrorMessage(error));
  };

  /**
   * Handler for the "save" button when an EXISTING sign-up page is updated for a company.
   */
  const updateSelfSignupPage = signupPageModel => {
    const backendModel = convertSignupPageFrontendModelToSignupPage(signupPageModel, coord);
    const updatePage = async () => {
      const errMessage = await checkSearchTermWithCoord(signupPageModel);
      if (errMessage) {
        setErrorMessage(errMessage);
        return;
      }
      try {
        await httpPut(`/v2/signup-page/${backendModel.token}`, backendModel);

        if (signupPageModel.logo.file != null) {
          await httpPostUpload(
            `/v1/signup-page/${signupPageModel.token}/logo`,
            signupPageModel.logo.file
          );
        }
        const signupPageGetResponse = await httpGet(`/v2/signup-page/${signupPageModel.token}`);
        dispatch(saveExistingSignupPageSuccess(signupPageGetResponse));
      } catch (error) {
        populateErrorMessage(error);
      }
    };
    return executeWithSpinner(updatePage());
  };

  React.useEffect(() => {
    const searchForToken = tokenArg => {
      executeWithSpinner(
        httpGet(`/v2/signup-page/${tokenArg}`, {})
          .then(response => {
            dispatch(startEditingExistingSignupPage(response));
            if (response.localization) {
              const localization = JSON.parse(response.localization);
              if (localization.latitude || localization.longitude) {
                setCoord({
                  searchTerm: localization.searchTerm,
                  lat: localization.latitude,
                  lng: localization.longitude,
                });
              }
            }
            loadOffersByToken(tokenArg);
            getMultiEntitySingupPages(tokenArg);
          })
          .catch(error => populateErrorMessage(error))
      );
    };

    const paramToken = currentUrlParams.get('token');
    if (state.pageState === INIT_STATE && token === '') {
      if (paramToken) {
        setToken(paramToken);
        searchForToken(paramToken);
      } else {
        let page = undefined;
        if (location.state && location.state.name) {
          const address: SFAddress = location.state.address;
          page = {
            name: location.state.name,
            searchTerm: `${address.street}, ${address.postalCode} ${address.city} `,
          };
          if (address.latitude && address.longitude) {
            setCoord({
              searchTerm: page.searchTerm,
              lat: address.latitude,
              lng: address.longitude,
            });
          }
        }
        dispatch(startCreatingNewSignupPage(page));
      }
    }

    if (location.state && location.state.offerId) {
      setOfferIdToAdd(location.state.offerId);
    }
  }, [
    currentUrlParams,
    executeWithSpinner,
    state.pageState,
    token,
    loadOffersByToken,
    populateErrorMessage,
    getMultiEntitySingupPages,
    location.state,
  ]);

  const validateLocation = location => {
    setCoord(null);
    setLocationValidationResult(null);
    setLocationValidationError(null);
    executeWithSpinner(
      httpGet(`/v1/geocoding/resolve?location=${location}`)
        .then(resp => {
          if (resp) {
            setLocationValidationResult({ ...resp, searchTerm: location });
          } else {
            setLocationValidationError('Address not found');
          }
        })
        .catch(error => setLocationValidationError(error.message))
    );
  };

  const checkSearchTermWithCoord = async signupPageModel => {
    const searchTermNotValidated =
      signupPageModel.searchTerm &&
      signupPageModel.searchTerm !== coord.searchTerm &&
      coord.lng &&
      coord.lat;

    if (searchTermNotValidated) {
      const resp = await httpGet(`/v1/geocoding/resolve?location=${signupPageModel.searchTerm}`);

      if (resp === undefined) {
        setCoord(null);
        setLocationValidationResult(null);
        return `Error: Cannot resolve the location: ${signupPageModel.searchTerm}`;
      }

      if (resp && (resp.longitude !== coord.lng || resp.latitude !== coord.lat)) {
        return 'Error: latitude/longitude does not match search terms';
      }
    }
  };

  return (
    <>
      {state.pageState === INIT_STATE && (
        <>
          {state.errorMessage && (
            <>
              <div className="alert alert-warning">{state.errorMessage}</div>
              <div className="alert alert-info">
                There was an unexpected error. Please start over in the search dialog.
              </div>
            </>
          )}
        </>
      )}
      {state.pageState === CREATE_SIGN_UP_PAGE_STATE && (
        <SelfSignupForm
          signupPageModel={state.signupPageModel}
          coord={coord}
          setCoord={setCoord}
          locationValidationResult={locationValidationResult}
          locationValidationError={locationValidationError}
          editMode={false}
          createSelfSignupPage={createSelfSignupPage}
          linkSignupPageAndOffer={() => linkSignupPageAndOffer(token, offerIdToAdd)}
          offerIdToAdd={offerIdToAdd}
          setOfferIdToAdd={setOfferIdToAdd}
          validateLocation={validateLocation}
          multiEntitySignupPages={multiEntitySignupPages}
        />
      )}
      {state.pageState === EDIT_SIGN_UP_PAGE_STATE && (
        <SelfSignupForm
          signupPageModel={state.signupPageModel}
          coord={coord}
          setCoord={setCoord}
          locationValidationResult={locationValidationResult}
          locationValidationError={locationValidationError}
          offers={offers}
          editMode={true}
          createSelfSignupPage={createSelfSignupPage}
          updateSelfSignupPage={updateSelfSignupPage}
          linkSignupPageAndOffer={() => linkSignupPageAndOffer(token, offerIdToAdd)}
          unlinkSignupPageAndOffer={unlinkSignupPageAndOffer}
          offerIdToAdd={offerIdToAdd}
          setOfferIdToAdd={setOfferIdToAdd}
          validateLocation={validateLocation}
          multiEntitySignupPages={multiEntitySignupPages}
        />
      )}
    </>
  );
};

export default withPageTemplate(SelfSignupManager, { headerTitle: 'Self Signup Page Manager' });
