import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import moment from 'moment';

const PaymentsDashboardContext = createContext();

const DACH = ['DE', 'AT', 'CH'];

export const PaymentsDashboardProvider = ({ children }) => {
  const [period, setPeriod] = useState(moment().format('YYYY-MM'));
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [chargeStats, setChargeStats] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const filteredStats = useMemo(() => {
    if (!chargeStats || !selectedCountries) {
      return [];
    }

    return chargeStats.aggregations.filter(
      aggregation =>
        selectedCountries.includes(aggregation.country) &&
        (!selectedProvider || aggregation.provider === selectedProvider)
    );
  }, [chargeStats, selectedCountries, selectedProvider]);

  useEffect(() => {
    if (chargeStats && !selectedCountries) {
      const preselectedCountries = [
        ...new Set(
          chargeStats.aggregations
            .map(aggregation => aggregation.country)
            .filter(country => DACH.includes(country))
        ),
      ];
      setSelectedCountries(preselectedCountries);
    }
  }, [chargeStats, selectedCountries]);

  return (
    <PaymentsDashboardContext.Provider
      value={{
        period,
        setPeriod,
        selectedCountries,
        setSelectedCountries,
        selectedProvider,
        setSelectedProvider,
        chargeStats,
        setChargeStats,
        filteredStats,
        errorMessage,
        setErrorMessage,
      }}
    >
      {children}
    </PaymentsDashboardContext.Provider>
  );
};

export const usePaymentsDashboard = () => useContext(PaymentsDashboardContext);
