// @flow
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import Login from 'app/ui/login/Login';
import UserPageTemplateWithRoutes from 'app/ui/user-manager/user/layout/UserPageTemplateWithSubRoutes';
import SearchUserPage from 'app/ui/user-manager/search-user/SearchUserPage';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  QUALITRAIN_OPS_VIEWER,
} from 'app/ui/common/authentication/roles';
import SearchGymsPage from 'app/ui/gyms/search-gyms/SearchGymsPage';
import GetQRCodes from 'app/ui/gyms/qrcodes/GetQRCodes';
import SignupPageSearchManager from 'app/ui/companies/SignupPageSearchManager';
import SelfSignupManager from 'app/ui/companies/self-signup-manager/SelfSignupManager';
import AddMembersManager from 'app/ui/companies/add-members/AddMembersManager';
import ListMembershipsPage from 'app/ui/membership/list-memberships/ListMembershipsPage';
import PaymentsDashboard from 'app/ui/payment/dashboard/PaymentsDashboard';
import Home from 'app/ui/home/Home';
import GymTemplateWithSubRoutes from 'app/ui/gyms/gym/layout/GymTemplateWithSubRoutes';
import CancelAllMembershipsManager from '../companies/cancel-all-memberships/CancelAllMembershipsManager';
import PayoutManager from 'app/ui/gyms/gym/payout/PayoutManager';
import UploadCheckinsManager from 'app/ui/gyms/gym/upload-checkin/UploadCheckinsManager';
import EgymUserImportPage from 'app/ui/user-manager/egym-import/EgymUserImportPage';
import MultiEntitySignupPagesSearch from '../companies/multi-entity-signup-pages/MultiEntitySignupPagesSearch';
import MultiEntitySignupPageManager from '../companies/multi-entity-signup-pages/MultiEntitySignupPageManager';
import OfferManagerTemplateWithSubRoutes from '../companies/offer-manager/layout/OfferManagerTemplateWithSubRoutes';
import HrisMembershipMappingManager from '../companies/hris-membership-mapping-tools/HrisMembershipMappingManager';
import ProtectedRoute from '../common/authentication/ProtectedRoute';
import MainLayoutWithRoutes from './MainLayoutWithRoutes';
import SettingManagerTemplateWithSubRoutes from '../companies/setting-manager/layout/SettingManagerTemplateWithSubRoutes';
import { NetworkSettingsContextProvider } from '../companies/offer-manager/context/NetworkSettingsContext';

const SecurityConfigs = {
  OPS: {
    ALL: [
      { role: QUALITRAIN_OPS_ADMIN },
      { role: QUALITRAIN_OPS_MASTER },
      { role: QUALITRAIN_OPS_VIEWER },
    ],
    ADMINS: [{ role: QUALITRAIN_OPS_ADMIN }, { role: QUALITRAIN_OPS_MASTER }],
    MASTER: [{ role: QUALITRAIN_OPS_MASTER }],
  },
};

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayoutWithRoutes />}>
      <Route path="/login" element={<Login />} />

      {/* User Manager */}
      <Route path="/users" element={<ProtectedRoute securityConfig={SecurityConfigs.OPS.ALL} />}>
        <Route index element={<SearchUserPage />} />
        <Route path="user/:userId/*" element={<UserPageTemplateWithRoutes />} />
        <Route
          path="egym-import"
          element={
            <ProtectedRoute securityConfig={SecurityConfigs.OPS.ADMINS}>
              <EgymUserImportPage />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* Gyms */}
      <Route path="/gyms/*" element={<ProtectedRoute securityConfig={SecurityConfigs.OPS.ALL} />}>
        <Route index element={<SearchGymsPage />} />
        <Route path="qrcodes/get" element={<GetQRCodes />} />
        <Route path="gym/:gymId/*" element={<GymTemplateWithSubRoutes />} />
        <Route
          path="payout-calculation/*"
          element={
            <ProtectedRoute securityConfig={SecurityConfigs.OPS.MASTER}>
              <PayoutManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="upload-checkin"
          element={
            <ProtectedRoute securityConfig={SecurityConfigs.ADMINS}>
              <UploadCheckinsManager />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* Companies */}
      <Route
        path="/companies/*"
        element={<ProtectedRoute securityConfig={SecurityConfigs.OPS.ALL} />}
      >
        <Route
          path="setting-manager/:sfAccountCanonicalId/*"
          element={<SettingManagerTemplateWithSubRoutes />}
        />
        <Route
          path="search"
          element={
            <ProtectedRoute securityConfig={SecurityConfigs.OPS.ALL}>
              <SignupPageSearchManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="self-signup-manager"
          element={
            <NetworkSettingsContextProvider>
              <SelfSignupManager />
            </NetworkSettingsContextProvider>
          }
        />
        <Route path="hris-membership-mapping/*" element={<HrisMembershipMappingManager />} />
        <Route
          path="add-members"
          element={
            <ProtectedRoute securityConfig={SecurityConfigs.OPS.ADMINS}>
              <AddMembersManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="cancel-all-memberships"
          element={
            <ProtectedRoute securityConfig={SecurityConfigs.OPS.ADMINS}>
              <CancelAllMembershipsManager />
            </ProtectedRoute>
          }
        />
        <Route path="offer-manager/:offerId/*" element={<OfferManagerTemplateWithSubRoutes />} />
        <Route path="multi-entity-signup-pages">
          <Route path="search" element={<MultiEntitySignupPagesSearch />} />
          <Route path=":id?" element={<MultiEntitySignupPageManager />} />
        </Route>
      </Route>

      {/* Exports */}
      <Route
        path="/exports/*"
        element={<ProtectedRoute securityConfig={SecurityConfigs.OPS.ALL} />}
      >
        <Route path="memberships/list" element={<ListMembershipsPage />} />
        <Route path="payments/list" element={<PaymentsDashboard />} />
        <Route index element={<Navigate to="memberships/list" />} />
      </Route>

      <Route index element={<Home />} />
    </Route>
  )
);

export default appRouter;
