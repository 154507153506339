// @flow
import React, { Fragment, useState } from 'react';
import { dateTimeToDateTimeWithSecondsCET } from '../../../../utils/date/dateUtil';
import PaymentLinkDetailsTable from './PaymentLinkDetailsTable';
import NewPaymentLinkButton from '../NewPaymentLinkButton';
import { useNavigate } from 'react-router-dom';
import type { ChargeRequest } from './types';

type Props = {
  chargeRequests: any,
};

const ChargeRequestsTable = ({ chargeRequests }: Props) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const navigate = useNavigate();

  const toggleExpandRow = chargeId => {
    const rows = expandedRows.includes(chargeId)
      ? expandedRows.filter(id => id !== chargeId)
      : expandedRows.concat(chargeId);
    setExpandedRows(rows);
  };

  const chargeLink = (chargeRequest: ChargeRequest) => {
    const provider = chargeRequest.provider && chargeRequest.provider.toLowerCase();
    switch (provider) {
      case 'stripe':
        return `${window._env_.REACT_APP_STRIPE_BASE_URL}/payments/${chargeRequest.pspReference}`;
      case 'adyen':
      default:
        return `${window._env_.REACT_APP_ADYEN_ADMIN_FRONTEND_BASE_URL}/ca/ca/accounts/showTx.shtml?pspReference=${chargeRequest.pspReference}&txType=Payment`;
    }
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Charge Reference ID</th>
            <th>Period</th>
            <th>Amount</th>
            <th>Updated At</th>
            <th>Status</th>
            <th>Status Message</th>
            <th>More details</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {chargeRequests.map(r => (
            <Fragment key={r.chargeId}>
              <tr>
                <td>{r.chargeId}</td>
                <td>{r.chargePeriod ? r.chargePeriod.month : null}</td>
                <td>{`${r.amount} ${r.currency}`}</td>
                <td>{dateTimeToDateTimeWithSecondsCET(r.updatedAt)}</td>
                <td>{r.status}</td>
                <td>{r.statusMessage}</td>
                <td>
                  {r.pspReference && (
                    <a href={chargeLink(r)} target="_blank" rel="noopener noreferrer">
                      {r.pspReference}
                    </a>
                  )}
                </td>
                <td align="center">
                  {r.paymentLinks && r.paymentLinks.length > 0 && (
                    <button
                      onClick={() => toggleExpandRow(r.chargeId)}
                      className="btn btn-secondary"
                    >
                      <i
                        className={`fas ${
                          expandedRows.includes(r.chargeId) ? 'fa-chevron-up' : 'fa-chevron-down'
                        }`}
                      />
                    </button>
                  )}
                </td>
              </tr>
              {expandedRows.includes(r.chargeId) && (
                <tr className="borderless-column" key={`link-${r.chargeId}`}>
                  <td colSpan={8}>
                    <PaymentLinkDetailsTable chargeRequest={r} />
                    <NewPaymentLinkButton chargeRequest={r} onComplete={() => navigate(0)} />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ChargeRequestsTable;
