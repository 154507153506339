// @flow
import * as React from 'react';
import { httpGet } from 'app/service/http/index';
import {
  addSfCompanyToSelectedSfCompanies,
  removeSfCompanyFromSelectedSfCompanies,
  searchForSfCompanyFailure,
  searchForSfCompanySuccess,
} from 'app/ui/user-manager/user/roles/actions';
import { isSfAccountCanonicalIdValid } from 'app/validation/common/sfIdValidator';

const CompanyRoleDetails = ({ state, dispatch }) => {
  const [companySearchTerm, setCompanySearchTerm] = React.useState('');

  const searchForSfCompany = sfAccountCanonicalId => {
    if (!isSfAccountCanonicalIdValid(sfAccountCanonicalId)) {
      dispatch(searchForSfCompanyFailure('Incorrect SF account ID. Expected format: ABC-123456'));
      return;
    }

    httpGet(`/v1/salesforce/company?sfAccountCanonicalId=${sfAccountCanonicalId}&minified=true`)
      .then(response => {
        dispatch(searchForSfCompanySuccess(response));
      })
      .catch(error => {
        dispatch(searchForSfCompanyFailure(error && error.message));
      });
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h6 className="card-title">Search for company</h6>
          <div className="mb-3 d-flex flex-wrap mx-1 input-group">
            <input
              id="sfCompanyId"
              type="text"
              className="form-control"
              value={companySearchTerm}
              placeholder="SalesForce Account ID"
              onChange={event => setCompanySearchTerm(event.target.value)}
            />
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => searchForSfCompany(companySearchTerm)}
            >
              Search
            </button>
          </div>
          <p className="text-start text-muted fst-italic">
            <small>Example: ACC-123456</small>
          </p>
          {state.searchResultSfCompany && (
            <table className="table table-borderless table-sm">
              <tbody>
                <tr key={state.searchResultSfCompany.CanonicalId__c}>
                  <td>{state.searchResultSfCompany.CanonicalId__c}</td>
                  <td>{state.searchResultSfCompany.Name}</td>
                  <td>
                    <button
                      type="button"
                      className="float-end bg-transparent border-0 fs-4 fw-bold"
                      aria-label="Add"
                      onClick={() =>
                        dispatch(addSfCompanyToSelectedSfCompanies(state.searchResultSfCompany))
                      }
                    >
                      <span aria-hidden="true">+</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body">
          <h6 className="card-title">Selected companies</h6>
          {state.selectedSfCompanies && (
            <table className="table table-borderless table-sm">
              <tbody>
                {state.selectedSfCompanies.map(sfCompany => (
                  <tr key={sfCompany.CanonicalId__c}>
                    <td>{sfCompany.CanonicalId__c}</td>
                    <td>{sfCompany.Name}</td>
                    <td>
                      <button
                        type="button"
                        className="float-end bg-transparent border-0 fs-4 fw-bold"
                        aria-label="Close"
                        onClick={() =>
                          dispatch(removeSfCompanyFromSelectedSfCompanies(sfCompany.CanonicalId__c))
                        }
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyRoleDetails;
