import React from 'react';

const AdyenPaymentProfile = () => (
  <div className="col-12 col-md-6">
    <div className="mb-2">
      <label className="fw-bold">Provider:</label>
      <span className="mx-2">Adyen</span>
    </div>
    <div className="mb-2">
      <label className="fw-bold">Account:</label>
      <span className="mx-2">Qualitrain_GmbH_ECOM</span>
    </div>
  </div>
);

export default AdyenPaymentProfile;
