// @flow
import * as React from 'react';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PayoutSubRouter from 'app/ui/gyms/gym/payout/PayoutSubRouter';
import { PayoutContextProvider } from '../context/PayoutContext';

const PayoutManager = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  return (
    <PayoutContextProvider>
      {currentLocation === location.pathname && <PayoutSubRouter />}
    </PayoutContextProvider>
  );
};

export default withPageTemplate(PayoutManager, { headerTitle: 'Gym Payout Calculation' });
