import moment from 'moment';

export type MembershipOfferType = 'FREE' | 'STANDARD' | 'PLUS_ONE';

export type MembershipOfferTier = {
  id: number,
  name: string,
  price?: number,
  currency?: string,
  availableFrom: string,
  availableTo?: string,
};

export type MembershipOffer = {|
  id?: number,
  b2cPayment?: boolean,
  customTermsDescription?: string,
  employeeInternalIdentifierLabel?: string,
  employeeInternalIdentifierValidationRegex?: string,
  employeeInternalIdentifierValidationDescription?: string,
  priceAmount?: number,
  priceCurrency?: string,
  signupDeadline?: string,
  created?: string,
  availableFrom?: string,
  availableTo?: string,
  sfAccountCanonicalId?: string,
  type?: MembershipOfferType,
  sfAccountCountryCode?: string,
  membershipOfferTiers?: MembershipOfferTier[],
|};

/**
 * Returns the active offer with the most recent available from
 */
export const getActiveOffer = (signupPageOffers: MembershipOffer[]) => {
  if (signupPageOffers) {
    const signupPageOffersCopy = [...signupPageOffers];
    signupPageOffersCopy.sort(function (a, b) {
      return (b?.id || 0) - (a?.id || 0);
    });
    return signupPageOffersCopy[0];
  }
};

// used to determine how to display price when membershipOffer has membershipOfferTiers
export const offerPrice = offer => {
  if (offer?.membershipOfferTiers?.length === 1)
    return `${offer.membershipOfferTiers[0].price} ${offer.membershipOfferTiers[0].currency}`;
  return '-';
};

export const isActiveMembershipOffer = (membershipOffer, now = moment.now()) => {
  if (!membershipOffer) return false;
  return (
    (!membershipOffer.availableFrom || moment(membershipOffer.availableFrom).isBefore(now)) &&
    (!membershipOffer.availableTo || moment(membershipOffer.availableTo).isAfter(now))
  );
};

export const isFutureMembershipOffer = (membershipOffer, now = moment.now()) => {
  if (!membershipOffer) return false;
  return (
    (!membershipOffer.availableFrom || moment(membershipOffer.availableFrom).isAfter(now)) &&
    (!membershipOffer.availableTo || moment(membershipOffer.availableTo).isAfter(now))
  );
};
