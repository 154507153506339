export const OVERVIEW_STEP = 'payout-calculation';
export const FETCH_DATA_STEP = 'fetch-salesforce-data';
export const ADJUST_REVENUES_STEP = 'adjust-revenues';
export const COMPLETE_REVIEW_STEP = 'payout-result';

export const PayoutStepsInfo = new Map([
  [
    OVERVIEW_STEP,
    {
      number: 1,
      label: 'Overview',
      to_fetch_salesforce_data: `fetch-salesforce-data`,
      to_adjust_revenue: `adjust-revenues`,
      to_payout_result: `payout-result`,
    },
  ],
  [
    FETCH_DATA_STEP,
    {
      number: 2,
      label: 'Fetch data from SF',
      to: `../adjust-revenues`,
      from: `/gyms/payout-calculation/overview`,
    },
  ],
  [
    ADJUST_REVENUES_STEP,
    {
      number: 3,
      label: 'Adjust revenues',
      to: `../payout-result`,
      from: `../fetch-salesforce-data`,
    },
  ],
  [
    COMPLETE_REVIEW_STEP,
    {
      number: 4,
      label: 'Complete/Review payout',
      from: `../adjust-revenues`,
    },
  ],
]);
