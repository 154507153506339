// @flow
import * as React from 'react';
import { httpPost } from 'app/service/http';
import { Link } from 'react-router-dom';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import EmojiRain from '../components/emoji-rain/EmojiRain';
import EmojiRainContext from '../components/emoji-rain/EmojiRainContext';
import styles from './HeaderNavigation.module.scss';

const loggingOut = (executeWithSpinner, logout) => {
  const postBody = {};
  return executeWithSpinner(
    httpPost('/v1/logout', postBody).then(() => {
      logout();
    })
  );
};

const HeaderNavigation = () => {
  const { isAuthenticated, user, logout } = React.useContext(AuthenticationContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { setEmojiRainTheme } = React.useContext(EmojiRainContext);

  return (
    <>
      <EmojiRain />
      {isAuthenticated() && (
        <>
          <nav className={`navbar navbar-light navbar-expand-sm pe-0 ps-0`}>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <select
                  className={`form-control w-25 ${styles.iconSelect}`}
                  onChange={event => setEmojiRainTheme(event.target.value)}
                >
                  <option value={'default'}>😂</option>
                  <option value={'en'}>🇬🇧</option>
                  <option value={'de'}>🇩🇪</option>
                  <option value={'fr'}>🇫🇷</option>
                  <option value={'it'}>🇮🇹</option>
                </select>
              </li>
              <li role="separator" className="d-none d-md-block mt-2 mb-2 border-end" />
              <li className="nav-item">
                <Link to="/login" className="nav-link btn">
                  <i className={`fas fa-user`} /> {`${user.firstName} ${user.lastName}`}
                </Link>
              </li>
              <li role="separator" className="d-none d-md-block mt-2 mb-2 border-end" />
              <li className="nav-item">
                <button
                  className="nav-link btn"
                  aria-label="Logout"
                  onClick={() => loggingOut(executeWithSpinner, logout)}
                >
                  <i className="fas fa-sign-out-alt" /> Logout
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default HeaderNavigation;
