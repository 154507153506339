import moment from 'moment';

export class SFContract {
  constructor(
    startDate: string,
    endDate: string,
    formOfContract: string,
    b2cPrice: number,
    currency: string
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.formOfContract = formOfContract;
    this.b2cPrice = b2cPrice;
    this.currency = currency;
  }

  get isB2C(): boolean {
    if (CONTRACT_FROMS.withB2C.includes(this.formOfContract)) {
      return true;
    } else if (CONTRACT_FROMS.withoutB2C.includes(this.formOfContract)) {
      return false;
    } else {
      return undefined;
    }
  }
}

export type SFAddress = {
  city: string,
  country: string,
  countryCode: string,
  postalCode: string,
  street: string,
  latitude: number,
  longitude: number,
};

const CONTRACT_FROMS = {
  withB2C: ['Einzelabrechnung mit B2C Payment', 'Fixum B2C Payment'],
  withoutB2C: [
    'Staffel',
    'Einzelabrechnung',
    'Fixum (mit Kontingent)',
    'Fixum (ohne Kontingent)',
    'OG - UG',
  ],
};

export type Network = 'DACH' | 'FR_BE';

export type MembershipTier = {
  id: number,
  name: string,
  credits: number,
  types: string[],
  createdAt: string,
  updatedAt: string,
};

type NetworkSetting = {
  id: number,
  network: Network,
  billingType: 'FIXED' | 'FLEXIBLE',
  creditCapping: 'NONE' | 'TWICE_BALANCE',
  membershipTiers: MembershipTier[],
  createdAt: string,
  updatedAt: string,
};

export type NetworkSettings = {
  countries: string[],
  offers: {
    [key: NetworkType]: {
      STANDARD?: NetworkSetting,
      PLUS_ONE?: NetworkSetting,
      FREE?: NetworkSetting,
    },
  },
};

export class SFCompany {
  constructor(
    canonicalId: string,
    name: string,
    startOfContract: string,
    billingAddress: SFAddress,
    shippingAddress: SFAddress,
    contracts: SFContract[]
  ) {
    this.canonicalId = canonicalId;
    this.name = name;
    this.startOfContract = startOfContract;
    this.billingAddress = billingAddress;
    this.shippingAddress = shippingAddress;
    this.contracts = contracts;
  }

  get closestContract(): SFContract {
    const contracts = this.contracts
      ?.filter(contract => !contract.endDate || moment(contract.endDate).isAfter(moment()))
      ?.sort((a, b) => (moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1));
    return contracts?.[0];
  }

  // eslint-disable-next-line no-use-before-define
  static parse(sfResponse: Object): SFCompany[] {
    return sfResponse?.records
      ? sfResponse.records.map(record => {
          const contracts = record.Contracts
            ? record.Contracts.records.map(contract => {
                return new SFContract(
                  contract.StartDate,
                  contract.EndDate,
                  contract.QT_FormOfContract__c,
                  contract.WP_Employee_Contribution_Gross__c,
                  contract.CurrencyISOCode
                );
              })
            : [];

          return new SFCompany(
            record.CanonicalId__c,
            record.Name,
            record.QT_StartOfContract__c,
            record.BillingAddress,
            record.ShippingAddress,
            contracts
          );
        })
      : [];
  }
}
