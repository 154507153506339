import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import {
  currencyDropdownOptions,
  daysDropdownOptions,
  monthsDropdownOptions,
  offerNetworkOptions,
  offerTypeOptions,
  requestUserPaymentDetailsOptions,
} from '../../../common/util/dropdownUtil';
import HorizontalFormLabel from '../../../components/horizontal-form/HorizontalFormLabel';
import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';
import HorizontalFormRow from '../../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import HorizontalFormTextAreaField from '../../../components/horizontal-form/HorizontalFormTextAreaField';
import type { MembershipOfferFrontendModel } from '../../../types';
import {
  dateToCET,
  getNextPossibleMembershipStartDate,
  getNextSignupDeadline,
  isUtcDateInPast,
} from '../../../../utils/date/dateUtil';
import moment from 'moment';
import { validate } from './validator';
import { offerDecorator } from './decorator';
import { OfferSignupPageList } from './OfferSignupPageList.jsx';
import { isSfAccountCanonicalIdValid } from 'app/validation/common/sfIdValidator';
import type { AuthenticatedUser } from '../../../common/authentication/types';
import type { NetworkSettings } from './types';
import { SFCompany } from './types';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';

import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import OfferTiersForm from './OfferTiersForm';
import { getCurrency, getNetworkByCountryCode, isUserOpsMaster } from './utils';

type Props = {
  saveOffer?: Function,
  membershipOffer: MembershipOfferFrontendModel,
  company?: SFCompany,
  loadCompany?: Function,
  editMode: boolean,
  user: AuthenticatedUser,
  networkSettings: NetworkSettings,
};

let timeout = 0;

const regexValidationExternalWebsiteUrl = 'https://www.regextester.com';
const regexValidationSamplesUrl =
  'https://egym.atlassian.net/wiki/spaces/WA/pages/1161199964/RegEx+validation+of+personal+ID+field';

const OfferForm = ({
  saveOffer,
  membershipOffer,
  company,
  loadCompany,
  editMode,
  user,
  networkSettings,
}: Props) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const lastSfContractFetched = useRef(company?.canonicalId || '');
  const formRef = useRef();
  const [companyLoading, setCompanyLoading] = useState(false);
  const countryCode = company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode;

  const networkOptions = useMemo(() => offerNetworkOptions(), []);

  useEffect(() => {
    if (editMode && membershipOffer.sfAccountCanonicalId === company?.canonicalId) {
      return;
    }

    formRef.current.mutators.setCompanyDetails(company);
  }, [company, membershipOffer, editMode]);

  const gymlibCountryCodes = window._env_.REACT_APP_GYMLIB_COUNTRY_CODES.split(',');
  const showLinkedSingupPages =
    editMode &&
    membershipOffer &&
    !gymlibCountryCodes.includes(membershipOffer.sfAccountCountryCode);

  const setCompanyDetails = (company, state) => {
    const newCompany = company?.[0];
    const currentSfAccountCanonicalValue =
      formRef.current.getFieldState('sfAccountCanonicalId').value;
    const companyNameField = state.fields['sfAccountCompanyName'];
    const countryCodeField = state.fields['sfAccountCountryCode'];
    const availableFromField = state.fields['availableFrom'];
    const b2cPaymentField = state.fields['b2cPayment'];
    const deadlineMonth = state.fields['deadlineMonth'];
    const deadlineDay = state.fields['deadlineDay'];
    const amountField = state.fields['amount'];
    const currencyField = state.fields['currency'];
    const networkField = state.fields['network'];

    if (!newCompany && currentSfAccountCanonicalValue) {
      companyNameField.change('');
      countryCodeField.change('');
      availableFromField.change('');
      b2cPaymentField.change('');
      amountField?.change('');
      currencyField.change('');
      networkField.change('');
    } else if (currentSfAccountCanonicalValue) {
      companyNameField.change(newCompany.name);
      const countryCode = newCompany.billingAddress && newCompany.billingAddress.countryCode;
      if (countryCode) {
        countryCodeField.change(countryCode);
      }

      const closestContract = newCompany.closestContract;
      const isB2C = closestContract ? closestContract.isB2C : undefined;
      b2cPaymentField.change(isB2C !== undefined ? isB2C : '');
      availableFromField.change(dateToCET(newCompany.startOfContract));
      if (countryCode && gymlibCountryCodes.includes(countryCode)) {
        deadlineMonth.change('0');
        deadlineDay.change('0');
        b2cPaymentField.change(true);
      }
      if (countryCode === 'US') {
        b2cPaymentField.change(false);
      }

      const network = getNetworkByCountryCode(countryCode, networkSettings);
      networkField.change(network || '');

      if (editMode) {
        // to remove once prices are stored per offer tier for edit form too
        amountField.change(closestContract?.b2cPrice);
        currencyField.change(
          getCurrency(
            newCompany,
            newCompany?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode
          )
        );
      } else {
        if (closestContract?.b2cPrice) {
          const currentSettings = networkSettings[network];
          const currentOfferSettings = currentSettings?.offers[membershipOffer.type || 'STANDARD'];

          // for now, we can retrieve price from salesforce only DACH market unique formula
          if (network === 'DACH' && currentOfferSettings?.membershipTiers?.length === 1) {
            const uniquePriceField =
              state.fields[`offerTiers[${currentOfferSettings.membershipTiers[0].id}].price`];
            if (uniquePriceField) {
              uniquePriceField.change(closestContract.b2cPrice);
            }

            currencyField.change(
              getCurrency(
                newCompany,
                newCompany?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode
              )
            );
          }
        }
      }
    }
  };

  const reloadCompany = useCallback(
    sfAccountCanonicalId => {
      if (
        !companyLoading &&
        sfAccountCanonicalId &&
        sfAccountCanonicalId !== lastSfContractFetched.current
      ) {
        lastSfContractFetched.current = sfAccountCanonicalId;
        if (timeout) {
          clearTimeout(timeout);
          setCompanyLoading(false);
        }

        if (isSfAccountCanonicalIdValid(sfAccountCanonicalId)) {
          setCompanyLoading(true);
          timeout = setTimeout(() => {
            loadCompany(sfAccountCanonicalId, !editMode).finally(() => {
              setCompanyLoading(false);
            });
          }, 1000);
        }
      }
    },
    [lastSfContractFetched, companyLoading, loadCompany, setCompanyLoading, editMode]
  );

  return (
    <div className="mt-4">
      <Form
        onSubmit={saveOffer}
        validate={values => validate(editMode, values, membershipOffer)}
        initialValuesEqual={() => true}
        initialValues={{
          ...membershipOffer,
          type: membershipOffer.type || 'STANDARD',
          sfAccountCompanyName: company?.name || '',
          sfAccountCountryCode:
            company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode,
          nextPossibleMembershipStartDate: getNextPossibleMembershipStartDate(
            moment(),
            parseInt(membershipOffer.deadlineMonth),
            parseInt(membershipOffer.deadlineDay)
          ).format('DD-MM-YYYY'),
          nextSignupDeadline: getNextSignupDeadline(
            moment(),
            parseInt(membershipOffer.deadlineDay)
          ).format('DD-MM-YYYY'),
          signUpPageExpirationDate: dateToCET(membershipOffer.expiration),
          availableFrom: editMode ? dateToCET(membershipOffer.availableFrom) : '',
          availableTo: dateToCET(membershipOffer.availableTo),
          isOfferEditable: membershipOffer.type === 'PLUS_ONE' ? isUserOpsMaster(user) : true,
          viewOnlyOfferId: editMode && membershipOffer ? membershipOffer.id : '',
          currency: getCurrency(
            company,
            company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode
          ),
          network: '',
        }}
        decorators={[offerDecorator]}
        mutators={{ setCompanyDetails }}
        render={({ submitError, handleSubmit, submitting, pristine, values, form }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit}>
              <h4 className="mt-3 mb-3">{editMode ? 'Update offer' : 'Create a offer'}</h4>
              {editMode && (
                <div className="card card-body col-xl-10 mb-2">
                  <HorizontalFormRow className="mb-0">
                    <HorizontalFormLabel controlLabel="Offer id" />
                    <HorizontalFormTextField
                      controlId="viewOnlyOfferId"
                      controlLabel="viewOnlyOfferId"
                      placeholder="Offer Id"
                      columnWidth={4}
                      disabled={true}
                      default
                    />
                  </HorizontalFormRow>
                </div>
              )}
              <div className="card card-body col-xl-10 mb-2">
                <h6>Company Information</h6>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Salesforce account canonical ID" />
                  <HorizontalFormTextField
                    controlId="sfAccountCanonicalId"
                    controlLabel="sfAccountCanonicalId"
                    placeholder="SalesForce Account ID"
                    columnWidth={4}
                    disabled={!values.isOfferEditable || editMode}
                    helpTextAfter={'Example: ACC-123456'}
                  />
                  <FormSpy
                    subscription={{ dirtyFields: true, values: true }}
                    onChange={props => reloadCompany(props.values.sfAccountCanonicalId)}
                  />
                </HorizontalFormRow>
                {!editMode && (
                  <HorizontalFormRow>
                    <HorizontalFormLabel controlLabel="Network" />
                    <HorizontalFormDropdownField
                      controlId="network"
                      controlLabel="network"
                      options={networkOptions}
                      columnWidth={4}
                      className="mb-0"
                      disabled
                    />
                  </HorizontalFormRow>
                )}
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Salesforce account name" />
                  <HorizontalFormTextField
                    controlId="sfAccountCompanyName"
                    controlLabel="sfAccountCompanyName"
                    placeholder="Company Name"
                    columnWidth={4}
                    loading={companyLoading}
                    disabled
                  />
                </HorizontalFormRow>
                <HorizontalFormRow className="mb-0">
                  <HorizontalFormLabel controlLabel="Salesforce country code" />
                  <HorizontalFormTextField
                    controlId="sfAccountCountryCode"
                    controlLabel="sfAccountCountryCode"
                    placeholder="SalesForce country code"
                    columnWidth={4}
                    loading={companyLoading}
                    disabled
                  />
                </HorizontalFormRow>
              </div>

              <div className="card card-body col-xl-10 mb-2">
                <h6>Offer General Settings</h6>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Offer Type" />
                  <HorizontalFormDropdownField
                    controlId="type"
                    controlLabel="type"
                    options={offerTypeOptions(
                      isUserOpsMaster(user),
                      values.isOfferEditable,
                      values.network
                    )}
                    columnWidth={4}
                    className="mb-0"
                    disabled={!values.isOfferEditable || !isEditable}
                    required={true}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Request user payment details" />
                  <HorizontalFormDropdownField
                    controlId="b2cPayment"
                    controlLabel="Request user payment details"
                    options={requestUserPaymentDetailsOptions()}
                    columnWidth={4}
                    className="mb-0"
                    disabled={
                      countryCode === 'US' ||
                      gymlibCountryCodes.includes(countryCode) ||
                      !values.isOfferEditable ||
                      values.type === 'FREE' ||
                      !isEditable
                    }
                    loading={companyLoading}
                  />
                </HorizontalFormRow>
                {
                  // to remove once prices are stored per offer tier for edit form too
                  editMode ? (
                    <HorizontalFormRow className="mb-0">
                      <HorizontalFormLabel controlLabel="Price Incl VAT & currency" />
                      <HorizontalFormTextField
                        controlId="amount"
                        controlLabel="amount"
                        placeholder="price"
                        columnWidth={2}
                        className="mb-2"
                        disabled={!values.isOfferEditable || values.type === 'FREE' || !isEditable}
                        loading={companyLoading}
                      />
                      <HorizontalFormDropdownField
                        controlId="currency"
                        controlLabel="currency"
                        options={currencyDropdownOptions()}
                        columnWidth={2}
                        className="mb-2"
                        disabled={true}
                      />
                      <small className="price.currency-help-text-after offset-md-3 col-sm-6 px-1">
                        Please be aware that the price is the amount that is paid by the employee.
                        It should not be changed after the company has had first signups. Please
                        double check before editing.
                      </small>
                    </HorizontalFormRow>
                  ) : (
                    <OfferTiersForm
                      editMode={editMode}
                      offerType={values.type}
                      network={values.network}
                      networkSettings={networkSettings}
                      loading={companyLoading}
                    />
                  )
                }
              </div>
              <div className="card card-body col-xl-10 mb-2">
                <h6>Offer Signup Page Settings</h6>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Company specific Personal Identifier (optional)" />
                  <HorizontalFormTextField
                    controlId="employeeInternalIdentifierLabel"
                    controlLabel="Company specific Personal Identifier (optional)"
                    placeholder=""
                    columnWidth={9}
                    helpTextAfter="If empty, the field asking for personal identifier will not appear on the landing page"
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="RegEx validation for Personal Identifier (optional)" />
                  <HorizontalFormTextField
                    controlId="employeeInternalIdentifierValidationRegex"
                    controlLabel="RegEx validation for Personal Identifier (optional)"
                    placeholder=""
                    columnWidth={9}
                    helpTextAfter={
                      <div>
                        <span>If empty, the personal identifier field will not be validated.</span>
                        <br />
                        <span>Please test your validation here </span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={regexValidationExternalWebsiteUrl}
                        >
                          {regexValidationExternalWebsiteUrl}
                        </a>
                        <br />
                        <span>Please find </span>
                        <a target="_blank" rel="noreferrer" href={regexValidationSamplesUrl}>
                          some examples here
                        </a>
                      </div>
                    }
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="RegEx validation Description (optional)" />
                  <HorizontalFormTextAreaField
                    controlId="employeeInternalIdentifierValidationDescription"
                    controlLabel="RegEx validation Description (optional)"
                    placeholder=""
                    columnWidth={9}
                    rows={3}
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Special Conditions (optional)" />
                  <HorizontalFormTextAreaField
                    controlId="customTermsDescription"
                    controlLabel="Special Offer (optional)"
                    columnWidth={9}
                    rows={3}
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Signup deadline" />
                  <HorizontalFormDropdownField
                    controlId="deadlineMonth"
                    controlLabel="deadlineMonth"
                    options={monthsDropdownOptions()}
                    columnWidth={3}
                    className="mb-0"
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                  <HorizontalFormDropdownField
                    controlId="deadlineDay"
                    controlLabel="deadlineDay"
                    options={daysDropdownOptions()}
                    columnWidth={3}
                    className="mb-0"
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                  <div className="col-sm-2 px-1 mb-3 justify-content-center d-flex flex-column">
                    <div className=" ">before membership starts</div>
                  </div>
                </HorizontalFormRow>
                <div className="alert alert-info text-black-50 offset-md-3 col-sm-8">{`Next signup deadline will be ${values.nextSignupDeadline} to signup for ${values.nextPossibleMembershipStartDate}`}</div>
                {/* Submit Errors */}
                {submitError && (
                  <div className="alert alert-danger">{JSON.stringify(submitError, null, 2)}</div>
                )}
                <HorizontalFormRow className="mb-0">
                  <HorizontalFormLabel controlLabel="Availability" />
                  <div className="d-flex flex-wrap mx-1 mt-sm-2 me-1">From</div>
                  <HorizontalFormTextField
                    controlId="availableFrom"
                    controlLabel="availableFrom"
                    placeholder="DD-MM-YYYY HH:mm"
                    disabled={!values.isOfferEditable || !isEditable}
                    loading={companyLoading}
                  />
                  <div className="d-flex flex-wrap mx-1 mt-sm-2 ms-3 me-1">To</div>
                  <HorizontalFormTextField
                    controlId="availableTo"
                    controlLabel="availableTo"
                    placeholder="DD-MM-YYYY HH:mm"
                    disabled={
                      !values.isOfferEditable ||
                      (membershipOffer.availableTo &&
                        isUtcDateInPast(membershipOffer.availableTo)) ||
                      !isEditable
                    }
                  />
                </HorizontalFormRow>
              </div>
              <div className="mt-3 mb-5">
                {isEditable && (
                  <FormSpy subscription={{ errors: true }}>
                    {({ errors }) => (
                      <button
                        className="btn d-grid btn-secondary col-sm-3 px-1"
                        type="submit"
                        disabled={
                          !values.isOfferEditable ||
                          submitting ||
                          pristine ||
                          Object.keys(errors).length > 0
                        }
                      >
                        Save
                      </button>
                    )}
                  </FormSpy>
                )}
              </div>
              {showLinkedSingupPages && (
                <div className="card card-body col-xl-10 mb-5">
                  <OfferSignupPageList offerId={membershipOffer.id} company={company} />
                </div>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

export default OfferForm;
