import React from 'react';
import Modal from '../../../../../common/modal/Modal';
import { Form } from 'react-final-form';
import HorizontalFormTextField from '../../../../../components/horizontal-form/HorizontalFormTextField';

const RemoteIntegrationIdModal = ({ isOpen, onClose, onAction }) => {
  const onSubmit = value => {
    onAction(value?.remoteIntegrationId);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      modalTitle={'Step1/4: Require Remote Integration ID'}
    >
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="my-4">
              <div className="mb-1">
                <label htmlFor="remoteIntegrationId" className={'text-primary'}>
                  Enter the Remote Integration ID for migration allowlist to HRIS.
                </label>
                <HorizontalFormTextField
                  controlId="remoteIntegrationId"
                  controlLabel="Remote Integration ID"
                  placeholder="Remote Integration ID"
                  helpTextAfter={'example: personio:CBNMt7dSNCzBdnRTx87dev4E'}
                />
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn d-grid w-100 btn-secondary w-50"
                  type="submit"
                  disabled={pristine}
                >
                  Create Integration
                </button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default RemoteIntegrationIdModal;
