import React from 'react';
import RemoteIntegrationIdModal from './RemoteIntegrationIdModal';
import EligibilityRulesModal from './EligibilityRulesModal';
import MatchPlanModal from './MatchPlanModal';
import MergeModal from './MergeModal';
import { httpGet, httpPost } from '../../../../../../service/http';
import { downloadBlob } from '../../../../../../utils/file/fileUtil';
import { notificationService } from '../../../../../../service/notification';
import SpinnerContext from '../../../../../common/spinner/SpinnerContext';

const Steps = {
  STEP_1: 'ACCEPT_REMOTE_INTEGRATION_ID',
  STEP_2: 'ACCEPT_ELIGIBILITY_RULES',
  STEP_3: 'PERFORM_MATCH',
  STEP_4: 'PERFORM_MERGE',
};

const AllowlistMigrationMediator = ({ isOpen, onClose, sfAccountCanonicalId }) => {
  const [currentStep, setCurrentStep] = React.useState(Steps.STEP_1);
  const [integrationScope, setIntegrationScope] = React.useState(null);
  const [matchPlanMetadata, setMatchPlanMetadata] = React.useState(null);
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const createIntegrationScopeRequest = (remoteIntegrationId, sfAccountId) => {
    return {
      remoteIntegrationId: remoteIntegrationId,
      externalId: sfAccountId,
    };
  };

  const handleStep1 = async remoteIntegrationId => {
    const migrationIntegrationScope = await httpGet(
      `/v1/integration-scope/migration?externalId=${sfAccountCanonicalId}`,
      null,
      response => {
        if (response.ok) {
          return response.json();
        }
        return Promise.resolve();
      }
    );

    if (migrationIntegrationScope) {
      setIntegrationScope(migrationIntegrationScope);
      setCurrentStep(Steps.STEP_2);
      return;
    }

    const request = createIntegrationScopeRequest(remoteIntegrationId, sfAccountCanonicalId);
    httpPost(`/v1/integration-scope/migration`, request)
      .then(response => {
        setIntegrationScope(response);
        setCurrentStep(Steps.STEP_2);
      })
      .catch(error => {
        console.error('Error creating integration scope:', error);
      });
  };

  const handleStep2 = () => {
    setCurrentStep(Steps.STEP_3);
  };

  const handleStep3 = async () => {
    try {
      await downloadMatch();
      setCurrentStep(Steps.STEP_4);
    } catch (error) {
      await notificationService.push({
        message: 'Error downloading the csv file containing merge execution plan!',
        variant: 'error',
      });
    }
  };

  const downloadMatch = async () => {
    try {
      if (!integrationScope) {
        return;
      }
      const response = await fetch(
        `/v1/integration-scope/migration/match/${integrationScope?.id}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/octet-stream',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      setMatchPlanMetadata({
        currentAllowlistRecordsLinkedWithEgymAccounts: response.headers.get(
          'x-match-current-allowlist-records-linked-with-egym-accounts'
        ),
        currentAllowlistSize: response.headers.get('x-match-current-allowlist-size'),
        newAllowlistRecordsLinkedWithEgymAccounts: response.headers.get(
          'x-match-new-allowlist-records-linked-with-egym-accounts'
        ),
        newAllowlistSize: response.headers.get('x-match-new-allowlist-size'),
        numberOfMatchedAllowlistRecords: response.headers.get(
          'x-match-number-of-matched-allowlist-records'
        ),
      });

      const blob = await response.blob();
      downloadBlob(blob, 'match_execution_plan.csv');
    } catch (error) {
      throw new Error('Error downloading the file:', error);
    }
  };

  const handleStep4 = async () => {
    const requestBody = {
      migrationIntegrationScopeId: integrationScope?.id,
    };
    const performMigrationMerge = async () => {
      await httpPost(`/v1/integration-scope/migration/merge`, requestBody)
        .then(async () => {
          await notificationService.push({
            message: 'Successfully executed the merge execution plan!',
            variant: 'success',
          });
          onClose();
          window.location.reload();
        })
        .catch(error => {
          console.log('Error executing the merge execution plan:', error);
        });
    };

    await executeWithSpinner(performMigrationMerge());
  };

  return (
    <div>
      {currentStep === Steps.STEP_1 && (
        <RemoteIntegrationIdModal isOpen={isOpen} onClose={onClose} onAction={handleStep1} />
      )}
      {currentStep === Steps.STEP_2 && (
        <EligibilityRulesModal
          isOpen={isOpen}
          onClose={onClose}
          integrationScope={integrationScope}
          onAction={handleStep2}
        />
      )}
      {currentStep === Steps.STEP_3 && (
        <MatchPlanModal isOpen={isOpen} onClose={onClose} onAction={handleStep3} />
      )}
      {currentStep === Steps.STEP_4 && (
        <MergeModal
          isOpen={isOpen}
          onClose={onClose}
          onAction={handleStep4}
          metaData={matchPlanMetadata}
        />
      )}
    </div>
  );
};
export default AllowlistMigrationMediator;
