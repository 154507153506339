// @flow
import React, { useEffect, useMemo, useState } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { httpGet } from 'app/service/http';
import ChargeRequestsTable from './ChargeRequestsTable';
import { useParams } from 'react-router-dom';
import PaymentMethod from './paymentmethod/PaymentMethod';

const limitChargeRequests = 10;

const PaymentDetailsPage = () => {
  const { userId } = useParams();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentProfiles, setPaymentProfiles] = useState(null);
  const [chargeRequests, setChargeRequests] = useState(undefined);
  const [error, setError] = useState(undefined);
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  useEffect(() => {
    const loadPaymentDetails = async () => {
      try {
        const loaded = await httpGet(`/v1/user/${userId}/payment-method/history`);
        setPaymentMethods(loaded.sort((a, b) => (a.status === 'ACTIVE' ? -1 : 1)));
      } catch (error) {
        setError(error);
      }
    };
    const loadPaymentProfiles = async () => {
      try {
        const loaded = await httpGet(`/v1/user/${userId}/payment-profile`);
        setPaymentProfiles(loaded);
      } catch (error) {
        setError(error);
      }
    };
    const getLatestChargeRequestWithPspReference = async () => {
      try {
        const loaded = await httpGet(
          `/v1/payment/charge?userId=${userId}&pageSize=${limitChargeRequests}`
        );
        setChargeRequests(loaded.content);
      } catch (error) {
        setError(error);
      }
    };

    executeWithSpinner(
      Promise.all([
        loadPaymentDetails(),
        loadPaymentProfiles(),
        getLatestChargeRequestWithPspReference(),
      ])
    );
  }, [executeWithSpinner, userId]);

  const paymentMethodDetails = useMemo(() => {
    if (!paymentMethods || !paymentProfiles) return [];

    const profilesById = paymentProfiles.reduce((acc, profile) => {
      acc[profile.id] = profile;
      return acc;
    }, {});

    return paymentMethods.map(pm => ({
      ...pm,
      profile: profilesById[pm.paymentProfileId] || {},
    }));
  }, [paymentMethods, paymentProfiles]);

  return (
    <>
      <h6>Last Charge Requests</h6>
      <div className="mb-4">
        <i className="small">
          Showing a maximum of <b>{limitChargeRequests}</b> most recent charge requests.
        </i>
      </div>
      {chargeRequests && chargeRequests.length > 0 && (
        <div className="mt-2 mb-5">
          <ChargeRequestsTable chargeRequests={chargeRequests} />
        </div>
      )}

      <h6>Payment Method Details</h6>
      {paymentMethodDetails &&
        paymentMethodDetails.map(payment => (
          <PaymentMethod key={payment.id} paymentDetails={payment} />
        ))}

      <div>{error && <div className="alert alert-primary">{error}</div>}</div>
    </>
  );
};

export default PaymentDetailsPage;
