import type { MembershipOfferFrontendModel, MembershipOfferPayloadV2 } from '../../../types';
import {
  convertToPeriod,
  extractDayFromPeriod,
  extractMonthFromPeriod,
} from '../../../../utils/date/periodUtil';
import { convertCetDateToUtc } from 'app/utils/date/dateUtil';
import type { MembershipTier } from './types';

const DEFAULT_CURRENCY = 'EUR';

export const convertMembershipOfferToMembershipOfferFrontendModel = (
  membershipOffer: ?MembershipOfferFrontendModel
): MembershipOfferFrontendModel => {
  return {
    ...membershipOffer,
    amount: membershipOffer?.price ? membershipOffer.price.amount : undefined,
    currency: membershipOffer?.price ? membershipOffer.price.currency : DEFAULT_CURRENCY,
    deadlineDay: membershipOffer ? extractDayFromPeriod(membershipOffer.signupDeadline) : 20,
    deadlineMonth: membershipOffer ? extractMonthFromPeriod(membershipOffer.signupDeadline) : -1,
    sfAccountCanonicalId: membershipOffer?.sfAccountCanonicalId
      ? membershipOffer.sfAccountCanonicalId
      : undefined,
  };
};

export const convertMembershipOfferFrontendModelToMembershipOffer = (
  membershipOffer: MembershipOfferFrontendModel
): MembershipOfferFrontendModel => ({
  id: membershipOffer.id,
  b2cPayment: membershipOffer.b2cPayment,
  customTermsDescription: membershipOffer.customTermsDescription,
  employeeInternalIdentifierLabel: membershipOffer.employeeInternalIdentifierLabel,
  employeeInternalIdentifierValidationRegex:
    membershipOffer.employeeInternalIdentifierValidationRegex,
  employeeInternalIdentifierValidationDescription:
    membershipOffer.employeeInternalIdentifierValidationDescription,
  signupDeadline: convertToPeriod(membershipOffer.deadlineMonth, membershipOffer.deadlineDay),
  sfAccountCanonicalId: membershipOffer.sfAccountCanonicalId,
  type: membershipOffer.type,
  availableFrom: convertCetDateToUtc(membershipOffer.availableFrom, 'DD-MM-YYYY HH:mm'),
  availableTo: convertCetDateToUtc(membershipOffer.availableTo, 'DD-MM-YYYY HH:mm'),
  sfAccountCountryCode: membershipOffer.sfAccountCountryCode,
});

export const convertMembershipOfferFrontendModelToMembershipOfferV1 = (
  membershipOffer: MembershipOfferFrontendModel
): MembershipOfferFrontendModel => ({
  ...convertMembershipOfferFrontendModelToMembershipOffer(membershipOffer),
  price: {
    amount: membershipOffer.amount,
    currency: membershipOffer.currency.toUpperCase(),
  },
});

export const convertMembershipOfferFormToCreatePayload = (
  membershipOffer: MembershipOfferFrontendModel,
  expectedTiers: MembershipTier[]
): MembershipOfferPayloadV2 => {
  const commonMembershipValues =
    convertMembershipOfferFrontendModelToMembershipOffer(membershipOffer);
  const { offerTiers = [] } = membershipOffer;
  return {
    ...commonMembershipValues,
    network: membershipOffer.network,
    membershipOfferTiers: (expectedTiers || []).map(expectedTier => ({
      membershipTierId: expectedTier.id,
      price: offerTiers[expectedTier.id]?.price,
      currency: membershipOffer.currency.toUpperCase(),
      availableFrom: commonMembershipValues.availableFrom,
      availableTo: commonMembershipValues.availableTo,
    })),
  };
};
