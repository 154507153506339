// @flow
import React from 'react';

type Props = {
  className?: string,
  children: any,
};

const HorizontalFormRow = ({ className = '', children }: Props) => (
  <div className={`d-flex flex-wrap mx-1 mb-3 ${className}`}>{children}</div>
);

export default HorizontalFormRow;
