import React, { useContext } from 'react';
import { dateToCET } from '../../../../utils/date/dateUtil';
import NetworkSettingsContext from '../../offer-manager/context/NetworkSettingsContext';
import styles from './OfferTiersList.module.scss';

type Props = {
  offerTiers: MembershipOfferTier[],
};

export const OfferTiersList = ({ offerTiers }: Props) => {
  const { tiersById } = useContext(NetworkSettingsContext);

  return (
    <tr>
      <td colSpan="8">
        <div className="p-3">
          <div className="text-primary">
            <h5>Linked offer Tiers:</h5>
          </div>
          <table className={`table mt-2 ${styles.offerTiersTable}`}>
            <thead>
              <tr>
                <th>OfferTier ID</th>
                <th>Name</th>
                <th>Price</th>
                <th>Available From</th>
                <th>Available To</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {offerTiers && offerTiers.length > 0 ? (
                offerTiers.map((tier, index) => (
                  <tr key={tier.id || index}>
                    <td>{tier.id}</td>
                    <td>{tiersById.get(tier.membershipTierId)?.name.toUpperCase() || '-'}</td>
                    <td>
                      {tier.price} {tier.currency}
                    </td>
                    <td>{dateToCET(tier.availableFrom)}</td>
                    <td>{dateToCET(tier.availableTo)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className={styles.noAvailableOfferTiersText}>
                    No Membership OfferTiers available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  );
};
