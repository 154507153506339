// @flow
import * as React from 'react';

type Props = {
  isOpen: boolean,
  yesButtonText: string,
  noButtonText: string,
  onConfirm: Function,
  onCancel: Function,
  children: any,
  yesButtonHidden: boolean,
};

/**
 * Confirmation Dialog with "Yes" and "No" options.
 *
 * The text for the Yes/No buttons can be customized via the "yesButtonText" and "noButtonText" props.
 *
 * The content (i.e. the question that is asked) should be provided as a child element.
 *
 * The button accepts two functions: "onConfirm()" and "onCancel()"
 * which will be invoked when the "Yes" or the "No" button is clicked in the dialog, respectively.
 */
const ConfirmationModal = ({
  isOpen = false,
  modalTitle,
  yesButtonText = 'Yes',
  yesButtonDisabled = false,
  yesButtonHidden = false,
  noButtonText = 'No',
  onConfirm,
  onCancel,
  children,
}: Props) => {
  const modalClassName = `modal fade ${isOpen ? 'show' : ''}`;
  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    backgroundColor: 'rgba(0,0,0,0.3)',
    overflowX: 'hidden',
    overflowY: 'auto',
  };
  return (
    <div className={modalClassName} tabIndex="-1" role="dialog" style={modalStyle}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-primary">{modalTitle}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onCancel}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            {!yesButtonHidden && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onConfirm}
                disabled={yesButtonDisabled}
              >
                {yesButtonText}
              </button>
            )}
            <button type="button" className="btn btn-primary" onClick={onCancel}>
              {noButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
