import React from 'react';
import HorizontalFormRow from '../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from '../../components/horizontal-form/HorizontalFormLabel';
const CompanyInformation = ({ sfDetail }) => {
  return (
    <div>
      <h5>Company Information</h5>
      <HorizontalFormRow>
        <HorizontalFormLabel controlLabel="Salesforce account name" />
        <input
          className="form-control col-md-4 px-3 px-1 w-50"
          value={sfDetail?.sfAccountCompanyName}
          disabled={true}
          placeholder="Company Name"
        ></input>
      </HorizontalFormRow>
      <HorizontalFormRow>
        <HorizontalFormLabel controlLabel="Salesforce country code" />
        <input
          className="form-control col-md-4 px-3 px-1 w-50"
          value={sfDetail?.sfAccountCountryCode}
          disabled={true}
          placeholder="SalesForce country code"
        ></input>
      </HorizontalFormRow>
      <HorizontalFormRow>
        <HorizontalFormLabel controlLabel="Salesforce account canonical ID" />
        <input
          className="form-control col-md-4 px-3 px-1 w-50"
          value={sfDetail?.sfAccountCanonicalId}
          disabled={true}
          placeholder="SalesForce Account ID"
        ></input>
      </HorizontalFormRow>
    </div>
  );
};
export default CompanyInformation;
