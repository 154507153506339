import type { AuthenticatedUser } from '../../../common/authentication/types';
import { QUALITRAIN_OPS_MASTER } from '../../../common/authentication/roles';
import { SFCompany } from './types';
import type { MembershipTier, NetworkSettings } from './types';
import { NetworkType } from '../../../gyms/gym/context/PayoutContext';
import { MembershipOfferType } from '../../../../utils/offer/offerUtil';

export const isUserOpsMaster = (user: AuthenticatedUser) => {
  return user ? user.roles.some(role => role.authority === QUALITRAIN_OPS_MASTER) : false;
};

export const getCurrency = (company: SFCompany, countryCode) => {
  const contract = company?.closestContract;
  if (!contract?.currency) {
    switch (countryCode) {
      case 'US':
        return 'USD';
      default:
        return 'EUR';
    }
  }
  return contract.currency;
};

export const getNetworkByCountryCode = (
  countryCode: string,
  networkSettings: NetworkSettings = []
) => {
  return Object.keys(networkSettings).find(network => {
    if (networkSettings[network]?.countries?.length > 0)
      return networkSettings[network].countries.includes(countryCode);
    return null;
  });
};

export const sortTiers = (a: MembershipTier, b: MembershipTier) => {
  if (a.credits < b.credits && a.types.length <= b.types.length) return -1;
};

export const formatName = (tier: MembershipTier) => {
  const formattedTypes = tier.types.sort().join(' + ').toUpperCase();
  return tier.credits > 0 ? `${tier.credits} ${formattedTypes}` : formattedTypes;
};

export const getExpectedTiers = (
  networkSettings: NetworkSettings,
  network: NetworkType,
  offerType: MembershipOfferType | undefined
): MembershipTier[] => {
  const networkSetting = networkSettings[network];
  if (
    !networkSetting ||
    offerType === 'FREE' ||
    !networkSetting.offers ||
    !networkSetting.offers[offerType]
  )
    return [];
  return networkSetting.offers[offerType].membershipTiers || [];
};
