import React, { useMemo } from 'react';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import type { NetworkSettings } from './types';
import { MembershipOfferType } from '../../../../utils/offer/offerUtil';
import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';
import { currencyDropdownOptions } from '../../../common/util/dropdownUtil';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import { NetworkType } from '../../../gyms/gym/context/PayoutContext';
import { formatName, getExpectedTiers, sortTiers } from './utils';

type OfferTiersFormProps = {
  editMode: boolean,
  offerType: MembershipOfferType | undefined,
  network: NetworkType | undefined,
  networkSettings: NetworkSettings,
  loading?: boolean,
};

const OfferTiersForm = ({
  editMode,
  offerType = 'STANDARD',
  network,
  networkSettings,
  loading = false,
}: OfferTiersFormProps) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  const offerTiers = useMemo(() => {
    const currentNetwork = !network || network === '' ? NetworkType.DACH : network;
    if (!editMode && networkSettings) {
      return getExpectedTiers(networkSettings, currentNetwork, offerType)
        .map(tier => ({
          ...tier,
          label: formatName(tier),
        }))
        .sort(sortTiers);
    }
    return [];
  }, [networkSettings, editMode, network, offerType]);

  if (offerTiers.length === 0) return;

  return (
    <table className="table mt-2 mb-0">
      <thead className="thead-light">
        <tr>
          <td className="text-primary col-3 font-weight-bold">Offer tiers</td>
          <td className="text-primary font-weight-bold">Price</td>
          <td className="text-primary font-weight-bold">Currency</td>
        </tr>
      </thead>
      <tbody>
        {offerTiers.map(offerTier => (
          <tr key={offerTier.id}>
            <td
              className="align-content-center col-3 text-muted text-monospace"
              style={{ fontSize: '0.9rem' }}
            >
              {offerTier.label}
            </td>
            <td>
              <HorizontalFormTextField
                controlId={`offerTiers[${offerTier.id}].price`}
                controlLabel={`offerTiers[${offerTier.id}].price`}
                placeholder="Price"
                className="mb-0"
                disabled={!isEditable}
                loading={loading}
              />
            </td>
            <td>
              {/* all currency fields are targeting same unique value */}
              <HorizontalFormDropdownField
                controlId={`currency`}
                controlLabel={`currency`}
                options={currencyDropdownOptions()}
                className="mb-0"
                disabled={true}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OfferTiersForm;
