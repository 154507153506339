import React from 'react';
import type { UserPaymentProfile } from './types';

type Props = {
  profile: UserPaymentProfile,
};

const StripePaymentProfile = ({ profile }: Props) => {
  const customerLink = `${window._env_.REACT_APP_STRIPE_BASE_URL}/customers/${profile.providerId}`;
  const account = `${profile.country} - Users`;

  return (
    <div className="col-12 col-md-6">
      <div className="mb-2">
        <label className="fw-bold">Provider:</label>
        <span className="mx-2">Stripe</span>
      </div>
      <div className="mb-2">
        <label className="fw-bold">Account:</label>
        <span className="mx-2">{account}</span>
      </div>
      <div className="mb-2">
        <label className="fw-bold">Customer:</label>
        <a href={customerLink} target="_blank" rel="noopener noreferrer" className="mx-2">
          {profile.providerId}
        </a>
      </div>
    </div>
  );
};

export default StripePaymentProfile;
