import React from 'react';
import { usePaymentsDashboard } from '../PaymentsDashboardContext';
import Decimal from 'decimal.js';

const TransactionProgressBar = () => {
  const { filteredStats } = usePaymentsDashboard();

  const processedCharges = filteredStats
    .filter(aggregation => aggregation.status !== 'NEW')
    .reduce((acc, aggregation) => acc + aggregation.count, 0);
  const totalCharges = filteredStats.reduce((acc, aggregation) => acc + aggregation.count, 0);
  const ratio = new Decimal(processedCharges).dividedBy(totalCharges);
  const progress = ratio.times(100).toFixed(2);

  return (
    <>
      <div className="my-2 d-flex align-items-center">
        <span>Progress:</span>
        <div className="progress flex-grow-1 ms-1">
          {progress > 5 ? (
            <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}>
              {progress}%
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center flex-grow-1">
              {progress}%
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionProgressBar;
