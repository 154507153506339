// @flow
import React from 'react';
import type { Gym, SalesForceGym } from 'app/ui/types';

type GymHeaderRowType = {
  propertyName: string,
  eGymValue: string,
  sfValue: string,
};

type Props = {
  gym: Gym,
  salesForceGym: SalesForceGym,
};

const GymHeaderRow = ({ propertyName, eGymValue, sfValue }: GymHeaderRowType) => (
  <div className="row">
    <div className="col-3 px-1 text-primary">{propertyName}</div>
    <div className="col-5 px-1">{eGymValue}</div>
    <div className="col-4 px-1">{sfValue}</div>
  </div>
);

const GymHeader = ({ gym, salesForceGym, sfErrorMsg }: Props) => {
  if (gym) {
    return (
      <div className="mb-4">
        <div className="row">
          <div className="col-3 px-1">&nbsp;</div>
          <div className="col-5 px-1 text-primary">eGym</div>
          <div className="col-4 px-1 text-primary">Salesforce</div>
        </div>
        <GymHeaderRow
          propertyName="Gym ID:"
          eGymValue={gym.gymId}
          sfValue={salesForceGym ? salesForceGym.Gym_ID__c : sfErrorMsg}
        />
        <GymHeaderRow
          propertyName="Gym Name:"
          eGymValue={gym.alias}
          sfValue={salesForceGym && salesForceGym.Name}
        />
        <GymHeaderRow
          propertyName="Street &amp; Nr.:"
          eGymValue={`${gym.address.street}, ${gym.address.streetNumber}`}
          sfValue={salesForceGym && salesForceGym.BillingAddress.street}
        />
        <GymHeaderRow
          propertyName="Zip Code &amp; City:"
          eGymValue={`${gym.address.zipCode}, ${gym.address.city}`}
          sfValue={
            salesForceGym &&
            `${salesForceGym.BillingAddress.postalCode}, ${salesForceGym.BillingAddress.city}`
          }
        />
      </div>
    );
  } else {
    return '';
  }
};

export default GymHeader;
