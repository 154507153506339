// @flow
import React, { useEffect, useMemo, useState, createContext } from 'react';
import { httpGet } from '../../../../service/http';
import type { NetworkSettings } from '../offer-details/types';
import type { MembershipOfferTier } from 'app/utils/offer/offerUtil';

type NetworkSettingsContextType = {
  networkSettings: NetworkSettings | undefined,
  tiersById: Map<string, MembershipOfferTier>,
  isLoading: boolean,
};

const NetworkSettingsContext = createContext<NetworkSettingsContextType | undefined>(undefined);

const NetworkSettingsContextProvider = ({ children }) => {
  const [networkSettings, setNetworkSettings] = useState<NetworkSettings | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadNetworkSettings = async () => {
      try {
        setIsLoading(true);
        const networkSettingsResponse = await httpGet(`/admin/v1/network-settings`);
        setNetworkSettings(networkSettingsResponse);
      } catch (error) {
        setNetworkSettings(undefined);
      } finally {
        setIsLoading(false);
      }
    };

    loadNetworkSettings();
  }, []);

  const tiersById = useMemo(() => {
    if (!isLoading && networkSettings) {
      const allTiers = Object.values(networkSettings)
        .flatMap(network => Object.values(network.offers || {}))
        .flatMap(offer => offer.membershipTiers || []);
      return new Map(allTiers.map(tier => [tier.id, tier]));
    }
    return new Map();
  }, [networkSettings, isLoading]);

  const contextValue = useMemo(
    () => ({ networkSettings, tiersById, isLoading }),
    [networkSettings, tiersById, isLoading]
  );

  return (
    <NetworkSettingsContext.Provider value={contextValue}>
      {children}
    </NetworkSettingsContext.Provider>
  );
};

export { NetworkSettingsContextProvider };
export default NetworkSettingsContext;
