import React, { useState } from 'react';
import { transactionStatuses } from './transactionStatuses';

const StatusLegend = () => {
  const [legendExpanded, setLegendExpanded] = useState(false);

  return (
    <div>
      <button
        className="btn btn-sm btn-link p-0 pt-1"
        onClick={() => setLegendExpanded(legendExpanded => !legendExpanded)}
      >
        See Transactions Legend
        <i className={`fa fa-angle-${legendExpanded ? 'up' : 'down'} ps-2`} />
      </button>
      <div className={`collapse ${legendExpanded ? 'show' : ''}`} id="legendCollapse">
        <div className="card card-body">
          {Object.entries(transactionStatuses).map(([status, details]) => (
            <div className="me-2" key={status}>
              <span className={`text-${details.level}`}>
                <i className={`${details.icon}`} />
                <u className="ps-2">{details.label}:</u>
              </span>
              <span className="ps-1">
                <small>{details.description}</small>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatusLegend;
