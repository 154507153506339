// @flow
import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { byCountryAndStatus } from '../utils';
import type { ChargeStatsAggregate } from './types';

type Props = {
  data: ChargeStatsAggregate[],
  selector: ({ count: number, volume: number }) => string,
  title: string,
};

const TransactionBarChart = ({ data, selector, title }: Props) => {
  const aggregate = useMemo(() => byCountryAndStatus(data), [data]);

  const chartData = useMemo(() => {
    if (!aggregate || !Object.keys(aggregate).length) {
      return [];
    }

    return Object.entries(aggregate).map(([country, statuses]) => ({
      country,
      ...Object.fromEntries(
        Object.entries(statuses).map(([status, values]) => [status, selector(values)])
      ),
    }));
  }, [aggregate, selector]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <div className="text-center">
        <b>{title}</b>
      </div>
      <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="country" />
        <YAxis />
        <Tooltip />

        <Bar dataKey="SUCCEEDED" stackId="a" fill="#82ca9d" />
        <Bar dataKey="FAILED" stackId="a" fill="#ff7f0e" />
        <Bar dataKey="CHARGED_BACK" stackId="a" fill="#d62728" />
        <Bar dataKey="ERROR" stackId="a" fill="#9467bd" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TransactionBarChart;
