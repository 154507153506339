import type { MembershipOfferFrontendModel } from '../../../types';
import validator from 'validator';
import {
  combineValidators,
  validateDate,
  validateDateNotInPast,
  validateIsBlank,
  validateLength,
  validateDateIsBeforeComparableDate,
  validateRequired,
} from '../../../../validation/common/commonValidators';
import { validateIsSfIdFormatCorrect } from '../../../../validation/common/sfIdValidator';
import {
  VALIDATION_INVALID_MEMBERSHIP_OFFER_PRICE,
  VALIDATION_INVALID_MONETARY_AMOUNT,
  VALIDATION_INVALID_REGEX,
  VALIDATION_REQUIRED_FIELD,
} from '../../../../validation/common/errorCodes';
import moment from 'moment';
import { dateToCET } from '../../../../utils/date/dateUtil';

export const validate = (
  editMode: boolean,
  values: MembershipOfferFrontendModel,
  originalOffer: MembershipOfferFrontendModel
) => {
  const errors = {};
  errors.employeeInternalIdentifierLabel = combineValidators(
    values.employeeInternalIdentifierLabel,
    false
  )(validateLength(0, 100));
  errors.sfAccountCanonicalId = combineValidators(values.sfAccountCanonicalId, true)(
    validateLength(1, 64),
    validateIsBlank,
    validateIsSfIdFormatCorrect
  );
  errors.sfAccountCountryCode = combineValidators(
    values.sfAccountCountryCode,
    true
  )(validateIsBlank);
  errors.customTermsDescription = combineValidators(
    values.customTermsDescription,
    false
  )(validateLength(0, 5000));
  errors.b2cPayment = validateB2CPayment(values.b2cPayment, values.type);
  errors.availableFrom = combineValidators(values.availableFrom, true)(
    validateDate('DD-MM-YYYY HH:mm'),
    validateIsBlank
  );
  errors.availableTo = combineValidators(values.availableTo, false)(
    ...getCommonAvailabilityValidators(values.availableTo, originalOffer.availableTo),
    validateDateIsBeforeComparableDate(values.availableFrom, 'DD-MM-YYYY HH:mm')
  );
  errors.employeeInternalIdentifierValidationRegex = validateRegex(
    values.employeeInternalIdentifierValidationRegex
  );
  if (!editMode) {
    errors.network = validateRequired(values.network);
  } else {
    errors.amount = validatePriceAmount(values.amount, values.b2cPayment);
  }
  return errors;
};

const validatePriceAmount = (amount, isMandatory) => {
  const required = isMandatory === true || isMandatory === 'true';
  // when received from the server the amount is a decimal value, so we have to convert it to string in order for the validation to work
  return combineValidators(amount, required)(
    validateLength(0, 21),
    validateMonetaryAmount(),
    validatePositiveAmount(required)
  );
};

export const validateMonetaryAmount = () => {
  return (value: string) => {
    if (value && !validator.isDecimal(value, { decimal_digits: '1,2' })) {
      return { code: VALIDATION_INVALID_MONETARY_AMOUNT, args: {} };
    }
    return undefined;
  };
};

const validatePositiveAmount = (required: boolean) => {
  return (value: string) => {
    if (required && value && Math.sign(value) === -1) {
      return { code: VALIDATION_INVALID_MEMBERSHIP_OFFER_PRICE, args: {} };
    }
    return undefined;
  };
};

const validateB2CPayment = (b2cValue, offerTypeValue) => {
  if (offerTypeValue !== 'FREE' && b2cValue === undefined) {
    return { code: VALIDATION_REQUIRED_FIELD, args: {} };
  }
  return undefined;
};

const validateRegex = regexValue => {
  if (regexValue) {
    try {
      new RegExp(regexValue);
    } catch (e) {
      return { code: VALIDATION_INVALID_REGEX, args: {} };
    }
  }
};

const getCommonAvailabilityValidators = (
  currentValue: string,
  previousValue: string
): Array<Function> => {
  const validators = [validateDate('DD-MM-YYYY HH:mm')];

  if (!previousValue || !isAvailabilityDateChanged(currentValue, previousValue)) {
    validators.push(validateDateNotInPast('DD-MM-YYYY', 'day'));
  }

  return validators;
};

const isAvailabilityDateChanged = (currentValue: string, previousValue: string) => {
  if (!currentValue) {
    return false;
  }

  const currentDate = moment(currentValue, 'DD-MM-YYYY HH:mm');
  return moment(dateToCET(previousValue), 'DD-MM-YYYY HH:mm').isSame(currentDate);
};
