import React from 'react';
import { formatISOZonedDateTimeToCETDate } from 'app/utils/format/dateTimeFormatter';
import type { StripePaymentDetails } from './types';

type StripePaymentMethodProps = {
  paymentDetails: StripePaymentDetails,
};

const StripePaymentMethod = ({ paymentDetails }: StripePaymentMethodProps) => {
  const { externalId, createdAt } = paymentDetails;
  const stripeCustomerLink = `${window._env_.REACT_APP_STRIPE_BASE_URL}/customers/${externalId}`;

  return (
    <div className="col-12 col-md-6">
      <div className="mb-2">
        <label className="fw-bold">Customer:</label>
        <span>
          <a href={stripeCustomerLink} target="_blank" rel="noopener noreferrer">
            {externalId}
          </a>
        </span>
      </div>
      <div className="mb-2">
        <label className="fw-bold">Created:</label>
        <span>{formatISOZonedDateTimeToCETDate(createdAt)}</span>
      </div>
    </div>
  );
};

export default StripePaymentMethod;
