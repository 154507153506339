// @flow
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { httpGet, httpPost } from 'app/service/http';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import Joke from '../components/joke/Joke';
import WellpassLogo from '../components/WellpassLogo';
import styles from './Login.module.scss';
import { validatePassword } from '../../validation/common/commonValidators';

const DEFAULT_LOGIN_LANDING_PAGE = '/'; // Home page

const Login = () => {
  const location = useLocation();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [displayWeakPasswordMessage, setDisplayWeakPasswordMessage] = React.useState(false);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { login, isAuthenticated } = React.useContext(AuthenticationContext);

  const tryToLogin = event => {
    event.preventDefault();

    const postBody = {
      username: username,
      password: password,
    };
    if (!validatePassword(password)) {
      setDisplayWeakPasswordMessage(true);
      return;
    }
    return executeWithSpinner(
      httpPost('/v1/login', postBody)
        .then(() =>
          httpGet('/v1/user-profile').then(user => {
            login(user);
          })
        )
        .catch(() => {
          setErrorMessage('Invalid Username or Password');
        })
    );
  };

  const weakPasswordMessage = React.useMemo(() => {
    if (displayWeakPasswordMessage) {
      return (
        <div className="alert alert-danger">
          Login denied. Please try to{' '}
          <a href={`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/password-reset`}>
            reset your password{' '}
          </a>
          or ask someone from ops team leads to get an account that fulfills our security standards.
        </div>
      );
    }
    return null;
  }, [displayWeakPasswordMessage]);

  // If already authenticated, go to the welcome page or the page that was originally requested
  if (isAuthenticated()) {
    const { redirectUrl } = location.state || {
      redirectUrl: DEFAULT_LOGIN_LANDING_PAGE,
    };

    return <Navigate to={redirectUrl} />;
  }

  return (
    <div>
      <div className="row">
        <div className={'col-sm-6 d-flex px-1'}>
          <div className={'w-100'}>
            <WellpassLogo variant={'dark'} />
            <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
              <div className="container">
                <div className={'d-flex justify-content-center mt-2'}>
                  <form onSubmit={tryToLogin} className={styles.formWidth}>
                    <h1 className={'d-flex justify-content-center text-dark'}>Login</h1>
                    <div className="mb-3">
                      <label htmlFor="username">Email</label>
                      <input
                        type="text"
                        id="username"
                        value={username}
                        autoComplete="username"
                        className="form-control"
                        onChange={e => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        className="form-control"
                        autoComplete="current-password"
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    {weakPasswordMessage}
                    <div className="mt-5 d-flex justify-content-between align-items-center">
                      <button className="btn btn-primary" type="submit">
                        Login
                        <i className="fas fa-arrow-right ms-3" />
                      </button>
                      <a
                        href={`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/password-reset`}
                      >
                        Forgot password?
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Joke />
      </div>
    </div>
  );
};

export default withPageTemplate(Login);
