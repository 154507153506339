//@flow
import React from 'react';
import moment from 'moment';
import { createActivationLettersPdf } from 'app/utils/pdf/activationLetterCreatorPdf';
import { exportNewlyAddedMembersToCsv } from 'app/utils/csv/addedMembersCsvExporter';
import AddMembersInputField from './AddMembersInputField';
import AddMembershipOfferInfoForm from './AddMembersOfferInfoForm';
import AddMembersMultipleOfferInfoForm from './AddMembersMultipleOfferInfoForm';

export type Member = {
  rowNumber: number,
  firstName: string,
  lastName: string,
  gender: string,
  dateOfBirth: string,
  email: string,
  membershipStartDate: string,
  membershipOfferId: number,
  emailSent: boolean,
};

type Props = {
  offerInfo: string,
  signupPageUrls: string,
  signupPageNames: string,
  members: Array<Member>,
  membersOnChange: Function,
  removeMember: Function,
  addMembers: Function,
  uploadedMembersTotalCount?: number,
  uploadedMembersFailureCount?: number,
  errorResult?: string,
  companyName: string,
  setCurrentRow: Function,
  currentRow: number,
  updateMember: Function,
  validate: Function,
  edited: boolean,
  isMultipleOffersUpload: boolean,
};

const AddMembersForm = ({
  offerInfo,
  signupPageUrls,
  signupPageNames,
  members,
  membersOnChange,
  removeMember,
  addMembers,
  uploadedMembersTotalCount,
  uploadedMembersFailureCount,
  errorResult,
  companyName,
  setCurrentRow,
  currentRow,
  updateMember,
  validate,
  edited,
  isMultipleOffersUpload,
}: Props) => {
  const alertRef = React.useRef(null);

  const createAndPrintActivationLetters = () => {
    if (!companyName) {
      companyName = '';
    }
    createActivationLettersPdf(members, companyName).save(generatePdfFileName());
  };

  const generatePdfFileName = () => {
    const baseName = `${moment().format('YYYYMMDD_HHMM')}`;
    if (isMultipleOffersUpload) {
      return `${baseName}_members_batch_upload.pdf`;
    }
    return `${baseName}_${offerInfo.id}.pdf`;
  };

  const hasInternalIdentifier =
    offerInfo &&
    offerInfo.employeeInternalIdentifierLabel &&
    offerInfo.employeeInternalIdentifierLabel.trim() !== '';

  const csvTemplateFileUrl = isMultipleOffersUpload
    ? 'https://storage.googleapis.com/qualitrain-admin-assets/add_member_multiple_offers.csv'
    : hasInternalIdentifier
    ? 'https://storage.googleapis.com/qualitrain-admin-assets/add_member_identifier.csv'
    : 'https://storage.googleapis.com/qualitrain-admin-assets/add_member.csv';

  const addMembershipColumnValue = (rowNumber, defaultValue, fieldName) => {
    return currentRow === rowNumber ? (
      <AddMembersInputField
        defaultValue={defaultValue}
        onChange={e => updateMember(rowNumber, fieldName, e.target.value)}
      />
    ) : (
      defaultValue
    );
  };

  return (
    <>
      {!isMultipleOffersUpload && offerInfo ? (
        <AddMembershipOfferInfoForm
          offerInfo={offerInfo}
          companyName={companyName}
          signupPageUrls={signupPageUrls}
          signupPageNames={signupPageNames}
        />
      ) : (
        <AddMembersMultipleOfferInfoForm />
      )}

      <div className="row">
        <div className="col-12 col-md-8 px-1">
          <div className="mb-1">
            <p>
              Please find a example csv sheet to upload members here:&nbsp;
              <a className="fst-italic" href={csvTemplateFileUrl}>
                csv template file
              </a>
            </p>
          </div>
          <div className="mb-2">
            <input
              className="text-primary me-2"
              type="file"
              // select csv file with new members from your computer
              onChange={membersOnChange}
            />
          </div>
        </div>
      </div>
      {members && (
        <div className="row">
          <div className="col-12 px-1">
            {offerInfo && (
              <div className="mb-1">
                <span className="text-primary fw-bold me-1">
                  VERIFY MEMBERS YOU WANT TO ADD TO {offerInfo.name}, OfferID: {offerInfo.id}
                </span>
              </div>
            )}
            <div className="table-responsive-xl text-bold mb-1">
              <table className="table text-start">
                <thead>
                  <tr>
                    <th width="10%">First Name</th>
                    <th width="10%">Last Name</th>
                    <th width="5%">Gender</th>
                    <th width="10%">Date of Birth</th>
                    {isMultipleOffersUpload || hasInternalIdentifier ? (
                      <>
                        <th width="10%">Email (optional)</th>
                        <th width="5%">Personal Identifier</th>
                      </>
                    ) : (
                      <th width="15%">Email (optional)</th>
                    )}
                    <th width="10%">Membership Start Date</th>
                    {isMultipleOffersUpload && <th width="13%">Offer ID</th>}
                    <th width="13%">Errors and warnings</th>
                    <th width="10%" />
                    <th width="12%">Upload result</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map(member => (
                    <tr
                      key={member.rowNumber}
                      className={
                        member.errors && member.errors.length > 0
                          ? 'alert alert-danger'
                          : member.warnings && member.warnings.length > 0
                          ? 'alert alert-warning'
                          : ''
                      }
                    >
                      <td onClick={() => setCurrentRow(member.rowNumber)}>
                        {addMembershipColumnValue(member.rowNumber, member.firstName, 'firstName')}
                      </td>
                      <td onClick={() => setCurrentRow(member.rowNumber)}>
                        {addMembershipColumnValue(member.rowNumber, member.lastName, 'lastName')}
                      </td>
                      <td onClick={() => setCurrentRow(member.rowNumber)}>
                        {addMembershipColumnValue(member.rowNumber, member.gender, 'gender')}
                      </td>
                      <td onClick={() => setCurrentRow(member.rowNumber)}>
                        {addMembershipColumnValue(
                          member.rowNumber,
                          member.dateOfBirth,
                          'dateOfBirth'
                        )}
                      </td>
                      <td onClick={() => setCurrentRow(member.rowNumber)}>
                        {addMembershipColumnValue(member.rowNumber, member.email, 'email')}
                      </td>
                      {(isMultipleOffersUpload || hasInternalIdentifier) && (
                        <td onClick={() => setCurrentRow(member.rowNumber)}>
                          {addMembershipColumnValue(
                            member.rowNumber,
                            member.identifierValue,
                            'identifierValue'
                          )}
                        </td>
                      )}
                      <td onClick={() => setCurrentRow(member.rowNumber)}>
                        {addMembershipColumnValue(
                          member.rowNumber,
                          member.membershipStartDate,
                          'membershipStartDate'
                        )}
                      </td>
                      {isMultipleOffersUpload && (
                        <td onClick={() => setCurrentRow(member.rowNumber)}>
                          {addMembershipColumnValue(
                            member.rowNumber,
                            member.membershipOfferId,
                            'membershipOfferId'
                          )}
                        </td>
                      )}
                      <td className="add-member-issues">
                        {member.warnings &&
                          member.warnings.map((warning, index) => (
                            <div className="mb-2" key={index}>
                              {warning}
                            </div>
                          ))}
                        {member.errors &&
                          member.errors.map((error, index) => (
                            <div className="mb-2" key={index}>
                              {error}
                            </div>
                          ))}
                      </td>
                      <td>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => removeMember(member.rowNumber)}
                        >
                          Remove
                        </button>
                      </td>
                      <td
                        className={
                          member.uploaded
                            ? member.uploadErrors && member.uploadErrors.length > 0
                              ? 'alert alert-danger'
                              : 'alert alert-success'
                            : ''
                        }
                      >
                        {member.uploaded
                          ? (member.uploadErrors && member.uploadErrors.length > 0
                              ? `X ${member.uploadErrors}`
                              : `✓uploaded - activation code: ${member.activationCode}`) +
                            (member.emailSent ? '✓activation email sent to user.' : '')
                          : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {edited && (
            <div className="col-12 col-md-8 px-1">
              <div className="mb-2">
                <button
                  className="btn btn-secondary me-5"
                  type="button"
                  onClick={() => {
                    setCurrentRow(null);
                    validate(members);
                  }}
                >
                  CHECK FOR DUPLICATES
                </button>
              </div>
            </div>
          )}

          {!edited && uploadedMembersTotalCount === 0 && members.length > 0 && (
            <div className="col-12 col-md-8 px-1">
              <div className="mb-2">
                <button
                  className="btn btn-secondary me-5"
                  type="button"
                  onClick={() => addMembers(false)}
                >
                  UPLOAD
                </button>
                <button
                  className="btn btn-secondary me-5"
                  type="button"
                  onClick={() => addMembers(true)}
                >
                  UPLOAD & SEND WELCOME EMAIL
                </button>
              </div>
            </div>
          )}

          {uploadedMembersTotalCount > 0 && (
            <div className="col-12 mb-2 px-1">
              <p>
                {uploadedMembersTotalCount} of {members.length} uploaded:&nbsp;
                {uploadedMembersTotalCount - uploadedMembersFailureCount} members with memberships
                were successfully added. {uploadedMembersFailureCount} members failed.
              </p>
              <button
                className="btn btn-secondary me-5"
                type="button"
                onClick={() => exportNewlyAddedMembersToCsv(members, companyName)}
              >
                Export list as CSV
              </button>

              {uploadedMembersTotalCount > 0 && uploadedMembersTotalCount >= members.length && (
                <button className="btn btn-secondary" onClick={createAndPrintActivationLetters}>
                  Download PDF activation codes
                </button>
              )}
            </div>
          )}
        </div>
      )}
      <div ref={alertRef} className="row">
        {errorResult && <div className="alert alert-primary">{errorResult}</div>}
      </div>
    </>
  );
};

export default AddMembersForm;
