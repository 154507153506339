import React from 'react';
import SepaPaymentMethod from './SepaPaymentMethod';
import StripePaymentMethod from './StripePaymentMethod';
import type { StripePaymentDetails, SepaPaymentDetails } from './types';
import StripePaymentProfile from './StripePaymentProfile';
import AdyenPaymentProfile from './AdyenPaymentProfile';

type PaymentDetailsProps = {
  paymentDetails: SepaPaymentDetails | StripePaymentDetails,
};

const PaymentMethod = ({ paymentDetails }: PaymentDetailsProps) => {
  const { profile } = paymentDetails;
  const paymentDetailsComponent = {
    sepadirectdebit: SepaPaymentMethod,
    stripe: StripePaymentMethod,
  };
  const profileDetailsComponent = {
    STRIPE: StripePaymentProfile,
    ADYEN: AdyenPaymentProfile,
  };
  const provider = profile?.provider || paymentDetails.provider;
  const PaymentDetailsComponent = paymentDetailsComponent[paymentDetails.type];
  const PaymentProfileComponent = profileDetailsComponent[provider];

  return (
    <>
      <div className="card mb-3">
        {paymentDetails.status === 'ACTIVE' && <div className="card-header fw-bold">Active</div>}
        <div className="card-body">
          <div className="row">
            <PaymentDetailsComponent paymentDetails={paymentDetails} />
            <PaymentProfileComponent profile={profile} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
