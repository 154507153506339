import React from 'react';
import Modal from '../../../../../common/modal/Modal';

const MatchPlanModal = ({ isOpen, onClose, onAction }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} modalTitle="Step3/4: Match Planing">
      <div className="mb-1">
        <label className={'text-primary'}>
          Download a csv file containing the match between the employees in allowlist and the
          corporate employees in HRIS system.
        </label>
      </div>

      <div className="d-flex flex-wrap mx-1 mb-3">
        <div className="col-sm-9 px-1" />
        <button className="btn btn-secondary col-sm-3 px-1" type="button" onClick={onAction}>
          Match
        </button>
      </div>
    </Modal>
  );
};

export default MatchPlanModal;
