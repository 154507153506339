// @flow
import * as React from 'react';

type Props = {
  isOpen: boolean,
  onRequestClose: Function,
  modalTitle: string,
  children: any,
  modalSizeClass?: string, // Use one of: "modal-sm", "modal-lg" or leave it empty
};

const Modal = ({ isOpen = false, onRequestClose, modalTitle, children, modalSizeClass }: Props) => {
  const modalClassName = `modal fade ${isOpen ? 'show' : ''}`;
  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    backgroundColor: 'rgba(0,0,0,0.3)',
    overflowX: 'hidden',
    overflowY: 'auto',
  };
  return (
    <div className={modalClassName} tabIndex="-1" role="dialog" style={modalStyle}>
      <div className={`modal-dialog modal-dialog-centered ${modalSizeClass}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-primary">{modalTitle}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onRequestClose}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer" />
        </div>
      </div>
    </div>
  );
};

export default Modal;
