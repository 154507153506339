import React, { useCallback } from 'react';
import { usePaymentsDashboard } from './PaymentsDashboardContext';
import moment from 'moment/moment';
import { httpGet } from '../../../service/http';
import SpinnerContext from '../../common/spinner/SpinnerContext';

const PaymentPeriodSelector = () => {
  const { period, setPeriod, setChargeStats, setErrorMessage } = usePaymentsDashboard();
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const getPayments = useCallback(
    period => {
      if (period && !moment(period, 'YYYY-MM', true).isValid()) {
        setErrorMessage('Invalid date, expected date format MM-YYYY');
        return;
      }

      executeWithSpinner(
        httpGet(`/v1/reporting/charges/stats?period=${period}`)
          .then(chargeStats => {
            setChargeStats({
              period,
              ...chargeStats,
            });
          })
          .catch(error => {
            setErrorMessage(error);
          })
      );
    },
    [executeWithSpinner, setChargeStats, setErrorMessage]
  );

  const submit = useCallback(
    event => {
      event.preventDefault();
      getPayments(period);
    },
    [period, getPayments]
  );

  return (
    <form onSubmit={submit}>
      <div className="d-flex flex-wrap">
        <div className="col-12">
          <div className="mb-3">
            <div className="input-group">
              <input
                id="period"
                type="month"
                className="form-control"
                value={period}
                onChange={event => setPeriod(event.target.value)}
              />
              <button className="btn btn-secondary" type="submit" disabled={!period}>
                Show B2C Transaction Sums
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PaymentPeriodSelector;
