// @flow
import React, { useState } from 'react';
import { httpGet } from 'app/service/http';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { extractRequestParameter } from 'app/service/http/requestUtil';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const SearchGymsPage = () => {
  const [egymGymId, setEgymGymId] = useState('');
  const [alias, setAlias] = useState('');
  const [result, setResult] = useState(null);
  const [errorResult, setErrorResult] = useState(null);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  React.useEffect(() => {
    const searchGyms = (egymGymId, alias) => {
      let baseUrl = '/v1/gym';

      if (egymGymId) {
        baseUrl += `/${egymGymId}`;
      }

      let defaultQueryParamsString = '?';

      if (!egymGymId) {
        defaultQueryParamsString += 'trainingFilter=QUALITRAIN&minified=true&';
      }

      return executeWithSpinner(
        httpGet(`${baseUrl}${defaultQueryParamsString + buildQueryParamString(null, alias)}`)
          .then(gyms => {
            //in case we did the id call we only receive one gym not an array.
            if (gyms && !Array.isArray(gyms)) {
              gyms = [gyms];
            }
            setResult(gyms);
          })
          .catch(error => {
            setErrorResult(error && error.message);
          })
      );
    };

    const egymGymId = extractRequestParameter('egymGymId');
    setEgymGymId(egymGymId ? egymGymId : ''); //empty string needed otherwise field is not cleared when navigating back

    const alias = extractRequestParameter('alias');
    setAlias(alias ? alias : ''); //empty string needed otherwise field is not cleared when navigating back

    setResult(null);
    setErrorResult(null);

    if (egymGymId || alias) {
      searchGyms(egymGymId, alias);
    }
  }, [executeWithSpinner, location.search]);

  const buildQueryParamString = (egymGymId, alias) => {
    const queryParams = [];

    if (egymGymId) {
      queryParams.push(`egymGymId=${encodeURIComponent(egymGymId)}`);
    }

    if (alias) {
      queryParams.push(`alias=${encodeURIComponent(alias)}`);
    }

    return queryParams.join('&');
  };

  const submitSearchForm = event => {
    event.preventDefault();

    if (!egymGymId && !alias) {
      setErrorResult('Please fill any of the search fields');
      return;
    }

    navigate(`/gyms?${buildQueryParamString(egymGymId, alias)}`);
  };

  return (
    <>
      <form onSubmit={submitSearchForm}>
        <div className="d-flex flex-wrap mx-1">
          <div className="col-12 px-1">
            <div className="mb-3">
              <div className="input-group">
                <input
                  id="egymGymId"
                  type="number"
                  className="form-control"
                  value={egymGymId}
                  placeholder="eGym Gym Id"
                  onChange={event => setEgymGymId(event.target.value)}
                />
                <input
                  id="alias"
                  type="text"
                  className="form-control"
                  value={alias}
                  placeholder="Gym name"
                  onChange={event => setAlias(event.target.value)}
                />
                <button className="btn btn-secondary" type="submit">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>
        {errorResult && <div className="alert alert-primary">{errorResult}</div>}
        <label>Search Results</label>
        {result && (
          <table className="table">
            <thead className="table-light">
              <tr>
                <th>eGym Gym Id</th>
                <th>Gym name</th>
                <th>Address</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {result.map(r => (
                <tr key={r.gymId}>
                  <td>{r.gymId}</td>
                  <td>
                    <Link to={`/gyms/gym/${r.gymId}/qr-codes`}>{r.alias}</Link>
                  </td>
                  <td>
                    {r.address.street} {r.address.streetNumber}, {r.address.zipCode}{' '}
                    {r.address.city}
                  </td>
                  <td>
                    <Link className="btn btn-primary" to={`/gyms/gym/${r.gymId}/qr-codes`}>
                      QR Codes
                    </Link>
                  </td>
                  <td>
                    {isEditable && (
                      <Link className="btn btn-primary" to={`/gyms/gym/${r.gymId}/add-checkins`}>
                        Add check-ins
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default withPageTemplate(SearchGymsPage, { headerTitle: 'Gym Search Manager' });
