import React, { useEffect, useMemo } from 'react';
import { usePaymentsDashboard } from './PaymentsDashboardContext';
import CheckboxGroup from './components/CheckboxGroup';
import PaymentSelect from './components/PaymentSelect';

const providerOptions = [
  {
    id: 'all',
    label: 'All',
    value: '',
  },
  {
    id: 'stripe',
    label: 'Stripe',
    value: 'STRIPE',
  },
  {
    id: 'adyen',
    label: 'Adyen',
    value: 'ADYEN',
  },
];

const PaymentFilters = () => {
  const {
    selectedCountries,
    setSelectedCountries,
    selectedProvider,
    setSelectedProvider,
    chargeStats,
  } = usePaymentsDashboard();

  const countries = useMemo(() => {
    if (!chargeStats) {
      return [];
    }

    return [...new Set(chargeStats.aggregations.map(aggregation => aggregation.country))].map(
      code => ({
        value: code,
        label: code,
      })
    );
  }, [chargeStats]);

  const providers = useMemo(() => {
    if (!chargeStats || !selectedCountries) {
      return [];
    }

    return [
      ...new Set(
        chargeStats.aggregations
          .filter(aggregation => selectedCountries.includes(aggregation.country))
          .map(aggregation => aggregation.provider)
      ),
    ];
  }, [chargeStats, selectedCountries]);

  useEffect(() => {
    if (providers.length <= 1) {
      setSelectedProvider('');
    }
  }, [providers, setSelectedProvider]);

  return (
    <div>
      {chargeStats && (
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span className="px-1">Countries:</span>
              <CheckboxGroup
                options={countries}
                selected={selectedCountries}
                onChange={setSelectedCountries}
              />
            </div>
            <div className="d-flex align-items-center">
              <span className="col-form-label px-1">Provider:</span>
              {providers.length > 1 && (
                <PaymentSelect
                  value={selectedProvider}
                  options={providerOptions}
                  onChange={setSelectedProvider}
                />
              )}
              {providers.length === 1 && <b className="text-primary">{providers[0]}</b>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentFilters;
