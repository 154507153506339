const currencyFormatter = Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

export const formatMoneyAmount = volume => {
  const volumeNumber = Number(volume);
  if (Number.isNaN(volumeNumber)) {
    return 'N/A';
  }
  return currencyFormatter.format(volumeNumber);
};
