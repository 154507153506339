import useLocalStorage from 'app/ui/common/hooks/useLocalStorage';
import { createContext, useMemo, useEffect } from 'react';
import { networkStringRepresentation } from '../payout/PayoutUtils';

export const NETWORK_LOCAL_STORAGE_KEY = 'network';

export const NetworkType = {
  DACH: 'DACH',
  FR_BE: 'FR_BE',
  US: 'US',
};

const PayoutContext = createContext({
  network: '',
  setNetwork: val => undefined,
  removeNetwork: () => undefined,
});

const PayoutContextProvider = ({ children }) => {
  const [network, setNetwork, removeNetwork] = useLocalStorage(NETWORK_LOCAL_STORAGE_KEY, '');

  const value = useMemo(
    () => ({ network, setNetwork, removeNetwork }),
    [network, removeNetwork, setNetwork]
  );

  useEffect(() => {
    return () => removeNetwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const titleElement = document.querySelector('#title');

    if (titleElement) {
      titleElement.innerHTML = network
        ? `${titleElement.innerHTML} (${networkStringRepresentation(network)})`
        : 'Gym Payout Calculation';
    }
  }, [network]);

  return <PayoutContext.Provider value={value}>{children}</PayoutContext.Provider>;
};

export { PayoutContextProvider };
export default PayoutContext;
