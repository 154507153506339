import React from 'react';
import { useNavigate } from 'react-router-dom';
import Joke from '../components/joke/Joke';
import WellpassLogo from '../components/WellpassLogo';
import styles from './Logout.module.scss';
const LoggedOut = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="row">
        <div className={'col-sm-6 d-flex vh-100 px-1'}>
          <div className={'w-100'}>
            <WellpassLogo variant={'dark'} />
            <div
              className={`container-fluid d-flex justify-content-center align-items-center ${styles.messageContainer}`}
            >
              <div className={`container ${styles.headerContainer}`}>
                <h2 className={`d-flex justify-content-center ${styles.messageHeader}`}>
                  You are logged out
                </h2>
                <div className={`text-center ${styles.loginMessage}`}>
                  To use the OPS Tool, please log in again. In the meantime, enjoy our dad joke of
                  the day.
                </div>
                <div className={'d-flex justify-content-center mt-3'}>
                  <button className="btn btn-primary px-4" onClick={() => navigate('/login')}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Joke />
      </div>
    </div>
  );
};

export default LoggedOut;
