// @flow
import React from 'react';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';
import { Task } from '../UploadTasksTable';

type Props = {
  task: Task,
  onConfirm: Function,
  onCancel: Function,
};

const RevertCheckinsConfirmationModal = ({ task, onConfirm, onCancel }: Props) => (
  <ConfirmationModal
    isOpen={true}
    modalTitle="Revert Checkins?"
    yesButtonText="Revert Checkins"
    yesButtonDisabled={task.persistedCheckinsCount === 0}
    noButtonText="Close"
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    <p className="text-center text-black-50">
      <>
        Do you want to revert <strong>{task.persistedCheckinsCount} Check-ins </strong> submitted
        with the <strong>{task.uploadedFileName}</strong> file?
      </>
    </p>
  </ConfirmationModal>
);

export default RevertCheckinsConfirmationModal;
