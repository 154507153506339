// @flow
import React from 'react';
import { dateTimeToDateTimeWithSecondsCET } from '../../../../utils/date/dateUtil';
import {
  formatISOZonedDateTimeToCETDate,
  formatISOZonedDateTimeToUTCDateTime,
} from '../../../../utils/format/dateTimeFormatter';

type Props = {
  chargeRequest: any,
};

const PaymentLinkDetailsTable = ({ chargeRequest }: Props) => {
  const providerReference = linkExternalId =>
    chargeRequest.provider === 'ADYEN' ? (
      <a
        href={`${window._env_.REACT_APP_ADYEN_ADMIN_FRONTEND_BASE_URL}/ca/ca/transactions/payment-link-details.shtml?d=${linkExternalId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkExternalId}
      </a>
    ) : (
      '[on-demand]'
    );

  return (
    <table className="table table-borderless payment-links-table">
      <thead style={{ fontWeight: 'bold' }}>
        <tr>
          <td>Provider Id</td>
          <td>Status</td>
          <td>Payment Id</td>
          <td>Payment status</td>
          <td>Expires</td>
          <td>Email sent</td>
          <td>Last Clicked</td>
          <td>Updated</td>
        </tr>
      </thead>
      <tbody>
        {chargeRequest.paymentLinks.map(link => (
          <tr key={link.externalId}>
            <td>{providerReference(link.externalId)}</td>
            <td>{link.status}</td>
            <td>
              {link.pspReference && (
                <a
                  href={`${window._env_.REACT_APP_ADYEN_ADMIN_FRONTEND_BASE_URL}/ca/ca/accounts/showTx.shtml?pspReference=${link.pspReference}&txType=Payment`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.pspReference}
                </a>
              )}
            </td>
            <td>{link.paymentStatus}</td>
            <td>{formatISOZonedDateTimeToCETDate(link.expirationDate)}</td>
            <td>{dateTimeToDateTimeWithSecondsCET(link.emailSentAt)}</td>
            <td>{formatISOZonedDateTimeToUTCDateTime(link.lastCheckoutCreatedAt) || '-'}</td>
            <td>{formatISOZonedDateTimeToCETDate(link.updatedAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PaymentLinkDetailsTable;
