// @flow
import * as React from 'react';

import { withPageTemplate } from 'app/ui/layout/PageTemplate';

import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  QUALITRAIN_OPS_VIEWER,
  isOpsUser,
  userHasAnyRole,
  userHasRole,
} from '../common/authentication/roles';
import LoggedOut from '../logout/LoggedOut';

const Home = () => {
  const { isAuthenticated, user } = React.useContext(AuthenticationContext);

  const isOpsViewer =
    !userHasAnyRole(user, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]) &&
    userHasRole(user, QUALITRAIN_OPS_VIEWER);

  return (
    <>
      {isAuthenticated() && (
        <>
          <div className="welcome-container">
            <div className="row justify-content-center welcome-text">
              {`Welcome, ${user.firstName} ${user.lastName}`}
              {isOpsViewer && (
                <div className="mt-5 alert alert-warning fs-6">
                  You were authorized as <b>QUALITRAIN OPS VIEWER</b>. User with such role has only{' '}
                  <b>read</b> access. Some operation like user creation and update are prohibited.
                </div>
              )}
            </div>
            {!isOpsUser(user) && (
              <div className="row justify-content-center">
                <div className="mt-5 alert alert-warning">
                  You are currently not authorized to use this tool.
                </div>
              </div>
            )}

            {isOpsUser(user) && (
              <>
                <div className="row mt-5">
                  <img
                    className="welcome-image"
                    src="/awesome_meme.png"
                    alt="Great customer service because of people like you"
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}

      {!isAuthenticated() && <LoggedOut></LoggedOut>}
    </>
  );
};

export default withPageTemplate(Home, { headerTitle: null });
