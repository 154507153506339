import React, { useState } from 'react';
import TransactionStatistics from './statistics/TransactionStatistics';
import FailedCharges from './failed-charges/FailedCharges';

const PaymentTabs = () => {
  const [activeTab, setActiveTab] = useState('statistics');

  const handleTabClick = (tab, event) => {
    event.preventDefault();
    setActiveTab(tab);
  };

  return (
    <div>
      <nav className="nav nav-tabs flex-column flex-sm-row mt-3">
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${activeTab === 'statistics' ? 'active' : ''}`}
            onClick={e => handleTabClick('statistics', e)}
          >
            Statistics
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${activeTab === 'failed' ? 'active' : ''}`}
            onClick={e => handleTabClick('failed', e)}
          >
            Failed Payments
          </button>
        </li>
      </nav>

      <div className="tab-content mt-3">
        {activeTab === 'statistics' && <TransactionStatistics />}
        {activeTab === 'failed' && <FailedCharges />}
      </div>
    </div>
  );
};

export default PaymentTabs;
