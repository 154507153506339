// @flow
import React from 'react';
import Modal from 'app/ui/common/modal/Modal';
import { httpGet } from 'app/service/http';
import MembershipOfferInfo from 'app/ui/common/offer-modal/MembershipOfferInfo';
import { isPositiveInteger } from 'app/ui/user-manager/user/memberships/validator';

type Props = {
  modalTitle: string,
  confirmButtonText: string,
  offerSelectionWarningText?: string,
  offerSelectionInfoText?: string,
  allowSelectingOfferWithB2CPayment: boolean,
  wasB2COffer: boolean,
  onRequestClose: Function,
  onSelectOffer: Function, // callback function that has one parameter: the selected offerId
  sfAccountCountryCode: string,
  offerSelectionCountryErrorText: string,
};

const isInTheSameCountry = (membershipOffer, newCountryCode) => {
  return membershipOffer?.sfAccountCountryCode === newCountryCode;
};
/**
 * This Modal is used to select a Membership Offer.
 *
 * Apart from searching for an offer, it doesn't do anything else.
 *
 * It does however receive a callback function as a prop (onSelectOffer),
 * which it invokes when the user confirms the newly selected offer (i.e. after clicking the Save/Select button).
 *
 * Two arguments are passed to the callback function:
 * 1) the id of the selected offer
 * 2) a wrapper object that contains the selected offer and all the signup pages related to the offer
 */
const MembershipOfferModal = ({
  modalTitle = 'Change Offer',
  confirmButtonText = 'Save',
  offerSelectionWarningText = undefined,
  offerSelectionInfoText = undefined,
  allowSelectingOfferWithB2CPayment = false,
  wasB2COffer,
  onRequestClose,
  onSelectOffer,
  sfAccountCountryCode = null,
  offerSelectionCountryErrorText = undefined,
}: Props) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchError, setSearchError] = React.useState(undefined);

  const [membershipOffer, setMembershipOffer] = React.useState(undefined);
  const [membershipOfferSignupPagesWrapperDto, setMembershipOfferSignupPagesWrapperDto] =
    React.useState(undefined);

  const searchForMembershipOffer = async event => {
    event.preventDefault();

    // validate input: should be integer
    if (!isPositiveInteger(searchTerm)) {
      setSearchError('The ID should contain only digits.');
      return;
    }

    try {
      const membershipOffer = await httpGet(`/v1/membership-offer/${searchTerm}`);
      const membershipOfferWithSignupPages = await httpGet(
        `/v1/signup-page?offerId=${membershipOffer.id}`
      );
      setMembershipOfferSignupPagesWrapperDto(membershipOfferWithSignupPages);
      setMembershipOffer(membershipOffer);
      setSearchError(undefined);
    } catch (error) {
      setSearchError('Could not find any offer for the given id.');
      setMembershipOffer(undefined);
      setMembershipOfferSignupPagesWrapperDto(undefined);
    }
  };

  const isTheNewOfferInTheSameCountry = sfAccountCountryCode
    ? isInTheSameCountry(membershipOffer, sfAccountCountryCode)
    : true;

  const canSelectOffer = () =>
    membershipOffer &&
    (allowSelectingOfferWithB2CPayment || !membershipOffer.b2cPayment) &&
    isTheNewOfferInTheSameCountry;

  const shouldShowWarningThatOfferIsB2C = () =>
    offerSelectionWarningText &&
    membershipOffer &&
    (membershipOffer.b2cPayment || wasB2COffer) &&
    isTheNewOfferInTheSameCountry;

  const shouldShowInfoThatOfferCanBeSelected = () =>
    offerSelectionInfoText && canSelectOffer() && isTheNewOfferInTheSameCountry;

  return (
    <Modal isOpen={true} onRequestClose={onRequestClose} modalTitle={modalTitle}>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <label className="col h6">Search for offer</label>
          </div>
          <form onSubmit={searchForMembershipOffer}>
            <div className="mb-3 input-group">
              <input
                type="number"
                value={searchTerm}
                placeholder="Offer ID"
                className="form-control"
                onChange={event => setSearchTerm(event.target.value)}
              />
              <button type="submit" disabled={!searchTerm} className="btn btn-secondary">
                Search
              </button>
            </div>
          </form>
          {searchError && (
            <div className="d-flex flex-wrap mx-1 alert alert-danger">{searchError}</div>
          )}
          {membershipOffer && (
            <MembershipOfferInfo
              membershipOffer={membershipOffer}
              membershipOfferSignupPagesWrapperDto={membershipOfferSignupPagesWrapperDto}
            />
          )}
        </div>
      </div>
      <div className="container">
        {membershipOffer && !isTheNewOfferInTheSameCountry && (
          <div className="row alert alert-danger mt-3">{offerSelectionCountryErrorText}</div>
        )}
        {shouldShowInfoThatOfferCanBeSelected() && (
          <div className="row alert alert-info mt-3">{offerSelectionInfoText}</div>
        )}
        {shouldShowWarningThatOfferIsB2C() && (
          <div className="row alert alert-warning mt-3">{offerSelectionWarningText}</div>
        )}
        <div className="row mt-3">
          <button
            className="col btn offset-9 col-3 px-1 btn-secondary"
            onClick={() => {
              onRequestClose();
              onSelectOffer(membershipOffer.id, membershipOfferSignupPagesWrapperDto);
            }}
            disabled={!canSelectOffer()}
          >
            {confirmButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MembershipOfferModal;
