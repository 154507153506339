import { formatMoneyAmount } from '../../../../utils/format/currencyFormatter';
import TransactionProgressBar from './TransactionProgressBar';
import React, { useMemo } from 'react';
import { usePaymentsDashboard } from '../PaymentsDashboardContext';
import StatusLegend from './StatusLegend';
import ChargeStatsTable from './ChargeStatsTable';
import FailedTransactionStats from './FailedTransactionStats';
import Decimal from 'decimal.js';
import type { Record } from './types';

const TransactionStatistics = () => {
  const { filteredStats } = usePaymentsDashboard();

  const transactionStats = useMemo(() => {
    const records: Record[] = Object.entries(
      filteredStats.reduce((acc, { status, count, volume }) => {
        if (!acc[status]) {
          acc[status] = { count: 0, volume: new Decimal(0) };
        }
        acc[status].count += count;
        acc[status].volume = acc[status].volume.plus(new Decimal(volume));
        return acc;
      }, {})
    );

    const total: Record = records.reduce(
      (acc, [, { count, volume }]) => ({
        count: acc.count + count,
        volume: acc.volume.plus(volume),
      }),
      { count: 0, volume: new Decimal(0) }
    );

    return { records, total };
  }, [filteredStats]);

  return (
    <>
      {transactionStats.records.length ? (
        <div className="my-3">
          <div className="my-1">
            Total number of transactions:
            <strong className="ms-2">{transactionStats.total.count}</strong>
          </div>
          <div className="my-1">
            Total transaction volume:
            <strong className="ms-2">
              {formatMoneyAmount(transactionStats.total.volume.toFixed(2))}{' '}
            </strong>
          </div>
          <TransactionProgressBar />
          <ChargeStatsTable {...transactionStats} />
          <StatusLegend />

          <FailedTransactionStats />
        </div>
      ) : (
        <div className="text-center">No charges were found for the selected period.</div>
      )}
    </>
  );
};

export default TransactionStatistics;
