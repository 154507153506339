// @flow
import React from 'react';

import type {
  MembershipOfferFrontendModel,
  MembershipOfferSignupPagesWrapperDto,
} from 'app/ui/types';
import { isActiveMembershipOffer } from 'app/utils/offer/offerUtil';

type Props = {
  membershipOffer: MembershipOfferFrontendModel,
  membershipOfferSignupPagesWrapperDto: MembershipOfferSignupPagesWrapperDto,
};

const MembershipOfferInfo = ({ membershipOffer, membershipOfferSignupPagesWrapperDto }: Props) => (
  <dl className="row">
    <dt className="col-4 px-1">Country Code:</dt>
    <dd className="col-8 px-1">{membershipOffer.sfAccountCountryCode}</dd>
    <dt className="col-4 px-1">Status:</dt>
    <dd className="col-8 px-1">
      {isActiveMembershipOffer(membershipOffer) ? 'ACTIVE' : 'NOT ACTIVE'}
    </dd>
    <dt className="col-4 px-1">Price:</dt>
    <dd className="col-8 px-1">
      {membershipOffer.price
        ? `${membershipOffer.price.amount} ${membershipOffer.price.currency}`
        : '0.0'}
    </dd>
    <dt className="col-4 px-1">B2C Payment:</dt>
    <dd className="col-8 px-1">{membershipOffer.b2cPayment ? 'YES' : 'NO'}</dd>
    <dt className="col-4 px-1">Personal ID:</dt>
    <dd className="col-8 px-1">{membershipOffer.employeeInternalIdentifierLabel}</dd>
    {membershipOfferSignupPagesWrapperDto.signupPageDtos &&
    membershipOfferSignupPagesWrapperDto.signupPageDtos.length > 0 ? (
      membershipOfferSignupPagesWrapperDto.signupPageDtos.map(signupPage => (
        <React.Fragment key={signupPage.token}>
          <dt className="col-4 px-1">Signup Page:</dt>
          <dd className="col-8 px-1">{signupPage.name}</dd>
          <dt className="col-4 px-1">Link:</dt>
          <dd className="col-8 px-1">
            <a
              target="blank"
              href={`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup?companyToken=${signupPage.token}`}
            >
              {signupPage.token}
            </a>
          </dd>
        </React.Fragment>
      ))
    ) : (
      <>
        <dt className="col-4 px-1">Signup Page:</dt>
        <dd className="col-8 px-1 alert-warning">No signup pages for this offer</dd>
      </>
    )}
  </dl>
);

export default MembershipOfferInfo;
