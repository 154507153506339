// @flow
import React from 'react';
import navigation from 'app/config/nav/navigation';
import { NavLink, useLocation } from 'react-router-dom';
import type { Menu, MenuItem } from 'app/config/nav/navigation';
import WellpassLogo from '../components/WellpassLogo';

type NavMenuItemProps = {
  menuItem: MenuItem,
  level: number,
};

const NavMenuItem = ({ menuItem, level }: NavMenuItemProps) => {
  const location = useLocation();
  const isActive = (isLink, pathname, isPartialPath) =>
    (isLink && location?.pathname === pathname) ||
    (isLink && isPartialPath && location.pathname.indexOf(pathname) === 0);
  return (
    <li className="nav-item" key={menuItem.path}>
      <NavLink
        to={menuItem.path}
        className={`nav-link ms-1 me-1 mt-1 text-uppercase ${
          isActive(menuItem.isLink, menuItem.path, menuItem.isPartialPath)
            ? 'active bg-primary text-white'
            : 'bg-transparent text-white'
        } ${level === 0 ? ' fw-bold' : ''}`}
        style={{ paddingLeft: `${5 + level * 30}px` }}
      >
        {menuItem.text}
      </NavLink>
      {menuItem.submenu && (
        <NavMenu menu={menuItem.submenu} level={level + 1} location={location} />
      )}
    </li>
  );
};

type NavMenuProps = {
  menu: Menu,
  level: number,
};

const NavMenu = ({ menu, level }: NavMenuProps) => {
  const location = useLocation();
  return (
    <ul className={`nav nav-pills d-flex flex-column`}>
      {menu.map(menuItem => (
        <NavMenuItem key={menuItem.path} menuItem={menuItem} level={level} location={location} />
      ))}
    </ul>
  );
};

const Sidebar = () => {
  const location = useLocation();
  return (
    <>
      <WellpassLogo variant={'light'} centered={true} />
      <NavMenu menu={navigation} level={0} location={location} />
    </>
  );
};

export default Sidebar;
