import { httpGet } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { useEffect, useContext, useState, useCallback, useMemo } from 'react';

const CHANGE_LOGS_API = '/v1/employee-sync-log';

const MAX_SIZE_PER_PAGE = 100;
const MIN_SIZE_PER_PAGE = 1;
const MIN_PAGE = 1;

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE_PER_PAGE = 20;

const DEFAULT_PAGINATION = {
  page: DEFAULT_PAGE,
  size: DEFAULT_SIZE_PER_PAGE,
};

const useLogs = (
  companyId,
  { page = DEFAULT_PAGE, size = DEFAULT_SIZE_PER_PAGE } = DEFAULT_PAGINATION
) => {
  const [changelogs, setChangelogs] = useState([]);
  const [total, setTotal] = useState(0);

  const { executeWithSpinner } = useContext(SpinnerContext);

  const maxPage = useMemo(() => {
    if (total === 0) {
      return 1;
    }
    return Math.ceil(total / Math.max(size, MIN_SIZE_PER_PAGE));
  }, [total, size]);

  const params = useMemo(() => {
    const pageParam = Math.max(Math.min(page, maxPage), MIN_PAGE);
    const sizeParam = Math.max(Math.min(size, MAX_SIZE_PER_PAGE), MIN_SIZE_PER_PAGE);

    return new URLSearchParams({
      companyId,
      page: pageParam,
      size: sizeParam,
    }).toString();
  }, [companyId, page, size, maxPage]);

  const fetchChangeLogs = useCallback(async () => {
    const changeLogs = await httpGet(`${CHANGE_LOGS_API}?${params}`);
    setChangelogs(changeLogs?.content || []);
    setTotal(changeLogs?.totalCount || 0);
  }, [params]);

  const fetchChangeLogsWithSpinner = useCallback(() => {
    executeWithSpinner(fetchChangeLogs());
  }, [executeWithSpinner, fetchChangeLogs]);

  useEffect(fetchChangeLogsWithSpinner, [fetchChangeLogsWithSpinner]);
  return {
    changelogs,
    maxPage,
    maxSize: MAX_SIZE_PER_PAGE,
    forceFetchLogs: fetchChangeLogsWithSpinner,
  };
};

export default useLogs;
