import React from 'react';
import Modal from '../../common/modal/Modal';

type Props = {
  onOfferIdChange: Function,
  offerId: number,
  loadNextPage: Function,
  onCancel: Function,
};

const AcceptOfferIdModal = ({ onOfferIdChange, offerId, loadNextPage, onCancel }: Props) => {
  return (
    <Modal isOpen={true} onRequestClose={onCancel} modalTitle="Move all members to a new offer">
      <div className="mb-3">
        <label htmlFor="offerId" className={'text-primary'}>
          Enter the <strong>new offer id</strong>, where you want to move members to.
        </label>
        <input
          type="number"
          id="offerId"
          value={offerId}
          className="form-control"
          onChange={onOfferIdChange}
        />
      </div>

      <div className="mb-3 d-flex flex-wrap mx-1">
        <div className="col-sm-9 px-1" />
        <button
          className="btn btn-secondary col-sm-3 px-1"
          type="button"
          disabled={!offerId}
          onClick={loadNextPage}
        >
          Next
        </button>
      </div>
    </Modal>
  );
};
export default AcceptOfferIdModal;
