// @flow
import Decimal from 'decimal.js';

type ChargeAggregation = {
  country: string,
  status: string,
  count: number,
  volume: number,
};

export const byCountryAndStatus = (aggregations: ChargeAggregation[]) => {
  return aggregations.reduce((acc, { country, status, count, volume }) => {
    if (!acc[country]) {
      acc[country] = {};
    }

    if (!acc[country][status]) {
      acc[country][status] = { count: new Decimal(0), volume: new Decimal(0) };
    }

    acc[country][status].count = acc[country][status].count.plus(count);
    acc[country][status].volume = acc[country][status].volume.plus(new Decimal(volume));

    return acc;
  }, {});
};

export const byCountry = (aggregations: ChargeAggregation[]) => {
  return aggregations.reduce((acc, { country, count, volume }) => {
    if (!acc[country]) {
      acc[country] = { count: new Decimal(0), volume: new Decimal(0) };
    }
    acc[country].count = acc[country].count.plus(count);
    acc[country].volume = acc[country].volume.plus(new Decimal(volume));

    return acc;
  }, {});
};

export const share = (part: number | Decimal, total: number | Decimal) => {
  if (!total) {
    return 0;
  }

  return new Decimal(part).div(total).times(100).toFixed(2);
};
