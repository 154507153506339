// @flow
import React, { useCallback } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { httpGet, httpPatch } from 'app/service/http';
import {
  cancelSelectingOfferForNewMembership,
  finishChangingMembershipOffer,
  membershipsLoadFailure,
  membershipsLoading,
  membershipsLoadSuccess,
  startSelectingOfferForNewMembership,
  membershipOfferSaveSuccess,
  finishSelectingOfferForNewMembership,
} from 'app/ui/user-manager/user/memberships/actions';
import {
  compareByStartTimestamp,
  isActiveMembership,
  isFutureMembership,
  isPastMembership,
  reverseComparator,
} from 'app/ui/user-manager/user/memberships/membershipUtils';
import FilteredMembershipsSection from 'app/ui/user-manager/user/memberships/FilteredMembershipsSection';
import OverlayContext from 'app/ui/common/modal/OverlayContext';
import UserMembershipsContext from 'app/ui/user-manager/user/memberships/context/UserMembershipsContext';
import ChangeOfferForMembershipModal from 'app/ui/user-manager/user/memberships/offer/ChangeOfferForMembershipModal';
import SelectOfferForNewMembershipModal from 'app/ui/user-manager/user/memberships/offer/SelectOfferForNewMembershipModal';
import type { MembershipType } from '../../../types';
import AuthenticationContext from '../../../common/authentication/AuthenticationContext';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import { useParams } from 'react-router-dom';
import EditChildMembershipModal from './EditChildMembershipModal';

const UserMembershipsPage = () => {
  const { userId } = useParams();
  const { state, dispatch } = React.useContext(UserMembershipsContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { showErrorOverlay, showSuccessOverlay } = React.useContext(OverlayContext);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);

  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  const fetchReferrerMembership = useCallback(
    async membership => {
      try {
        return await httpGet(`/v1/membership/${membership.referrerMembershipUuid}`);
      } catch (error) {
        dispatch(membershipsLoadFailure());
      }
    },
    [dispatch]
  );

  const fetchReferralMembership = useCallback(
    async membership => {
      try {
        return await httpGet(`/v1/membership/referrer/${membership.uuid}`);
      } catch (error) {
        dispatch(membershipsLoadFailure());
      }
    },
    [dispatch]
  );

  const populateRelatedMembership = useCallback(
    (memberships: Array<MembershipType>) => {
      return Promise.all(
        memberships.map(async wrapper => {
          const { membership } = wrapper;
          if (membership.referrerMembershipUuid) {
            const referrerMembership = await fetchReferrerMembership(membership);
            return { ...wrapper, referrerMembership: referrerMembership };
          }
          if (membership.referralLink) {
            const referralMembership = await fetchReferralMembership(membership);
            return { ...wrapper, referralMembership: referralMembership };
          }
          return wrapper;
        })
      );
    },
    [fetchReferralMembership, fetchReferrerMembership]
  );

  React.useEffect(() => {
    const loadMemberships = async () => {
      try {
        dispatch(membershipsLoading(userId));
        const memberships = await httpGet(`/v1/user/${userId}/membership`);
        const populatedByRelatedMemberships = await populateRelatedMembership(memberships);
        dispatch(membershipsLoadSuccess(populatedByRelatedMemberships));
      } catch (error) {
        dispatch(membershipsLoadFailure());
      }
    };
    executeWithSpinner(loadMemberships());
  }, [dispatch, executeWithSpinner, userId, populateRelatedMembership]);

  const futureMemberships = state.memberships
    .filter(m => isFutureMembership(m))
    .sort(reverseComparator(compareByStartTimestamp));
  const activeMemberships = state.memberships
    .filter(m => isActiveMembership(m))
    .sort(reverseComparator(compareByStartTimestamp));
  const pastMemberships = state.memberships
    .filter(m => isPastMembership(m))
    .sort(reverseComparator(compareByStartTimestamp));

  const updateOfferForMembership = newOfferId => {
    const patchMembership = async () => {
      try {
        const patchBody = {
          membershipOfferId: newOfferId,
        };
        const membershipWrapper = await httpPatch(
          `/admin/v1/user/${userId}/membership/${state.membershipUUIDInChangeOfferMode}`,
          patchBody
        );
        dispatch(membershipOfferSaveSuccess(membershipWrapper));
        showSuccessOverlay('The Offer for the Membership changed successfully.');
      } catch (error) {
        showErrorOverlay(error && error.message);
      }
    };
    executeWithSpinner(patchMembership());
  };

  return (
    <>
      <h5>Memberships</h5>
      {state.error && (
        <pre className="alert alert-danger">{JSON.stringify(state.error, null, 2)}</pre>
      )}
      {/* Disable the "Create Membership" button if a creating a new memberships is already in progress. */}
      <button
        className="float-end btn btn-primary"
        type="button"
        disabled={!!state.newMembership || !isEditable}
        onClick={() => dispatch(startSelectingOfferForNewMembership())}
      >
        Create Membership
      </button>
      <div className="clearfix" />
      {state.newMembership && (
        <FilteredMembershipsSection
          title="New Membership"
          membershipWrappers={[state.newMembership]}
          chronology="ACTIVE"
          state={state}
          dispatch={dispatch}
        />
      )}
      <FilteredMembershipsSection
        title="Future Memberships"
        membershipWrappers={futureMemberships}
        chronology="FUTURE"
        isEditable={isEditable}
      />
      <FilteredMembershipsSection
        title="Active Membership"
        membershipWrappers={activeMemberships}
        chronology="ACTIVE"
        isEditable={isEditable}
      />
      <FilteredMembershipsSection
        title="Past Memberships"
        membershipWrappers={pastMemberships}
        chronology="PAST"
        isEditable={isEditable}
      />
      {state.membershipUUIDInChangeOfferMode && (
        <ChangeOfferForMembershipModal
          onRequestClose={() => dispatch(finishChangingMembershipOffer())}
          onSelectOffer={updateOfferForMembership}
          wasB2COffer={state.b2cPayment}
          sfAccountCountryCode={state.sfAccountCountryCode}
        />
      )}
      {state.selectOfferForNewMembership && (
        <SelectOfferForNewMembershipModal
          onRequestClose={() => dispatch(cancelSelectingOfferForNewMembership())}
          onSelectOffer={(newOfferId, membershipOfferSignupPagesWrapperDto) =>
            dispatch(finishSelectingOfferForNewMembership(membershipOfferSignupPagesWrapperDto))
          }
        />
      )}
      {state.editChildMembershipModalOpen && (
        <EditChildMembershipModal childMembershipUserId={state.childMembershipUserId} />
      )}
    </>
  );
};

export default UserMembershipsPage;
