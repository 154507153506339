import React, { useCallback, useMemo, useState } from 'react';
import PaymentSelect from '../components/PaymentSelect';
import TransactionBarChart from './TransactionBarChart';
import TransactionPieChart from './TransactionPieChart';
import { usePaymentsDashboard } from '../PaymentsDashboardContext';

const options = [
  {
    id: 'count',
    label: 'Counts',
    value: 'count',
  },
  {
    id: 'volume',
    label: 'Volume',
    value: 'volume',
  },
];

const precisions = {
  count: 0,
  volume: 2,
};

const excludedStatuses = ['NEW', 'PROCESSING', 'SUCCEEDED'];

const FailedTransactionStats = () => {
  const { filteredStats } = usePaymentsDashboard();
  const [option, setOption] = useState(options[0].value);

  const data = useMemo(() => {
    return filteredStats.filter(aggregation => !excludedStatuses.includes(aggregation.status));
  }, [filteredStats]);

  const selector = useCallback(values => values[option].toFixed(precisions[option]), [option]);

  return data && data.length ? (
    <>
      <div className="d-flex justify-content-between align-items-end mt-2">
        <b className="text-secondary">Failed Payments</b>
        <PaymentSelect value={option} options={options} onChange={setOption} />
      </div>
      <div className="d-flex my-5">
        <TransactionBarChart title="Failures By Status" data={data} selector={selector} />
        <TransactionPieChart title="Failures By Country" data={data} selector={selector} />
      </div>
    </>
  ) : null;
};

export default FailedTransactionStats;
