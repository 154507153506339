// @flow
import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { byCountry } from '../utils';
import type { ChargeStatsAggregate } from './types';

type Props = {
  data: ChargeStatsAggregate[],
  selector: ({ count: number, volume: number }) => string,
  title: string,
};

const COLORS = [
  '#FF6384', // Red/Pink
  '#36A2EB', // Blue
  '#FFCE56', // Yellow
  '#1f77b4', // Dark Blue
  '#2ca02c', // Green
  '#8c564b', // Brown
  '#7f7f7f', // Gray
  '#17becf', // Cyan
];

const TransactionPieChart = ({ data, selector, title }: Props) => {
  const aggregate = useMemo(() => byCountry(data), [data]);

  const chartData = useMemo(() => {
    if (!aggregate || !Object.keys(aggregate).length) {
      return [];
    }

    return Object.entries(aggregate).map(([country, values]) => ({
      name: country,
      value: parseFloat(selector(values)),
    }));
  }, [aggregate, selector]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <div className="text-center">
        <b>{title}</b>
      </div>
      <PieChart>
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label={({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
            const RADIAN = Math.PI / 180;
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
              <text
                x={x}
                y={y}
                fill="#8884d8"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
              >
                {chartData[index].name}
              </text>
            );
          }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default TransactionPieChart;
