import React, { useCallback, useEffect, useState } from 'react';
import FailedChargesTable from './FailedChargesTable';
import { httpGet, httpPost } from '../../../../service/http';
import { usePaymentsDashboard } from '../PaymentsDashboardContext';
import NewPaymentLinkButton from '../../../user-manager/user/NewPaymentLinkButton';
import { notificationService } from '../../../../service/notification';
import PaymentSelect from '../components/PaymentSelect';

const failedChargeOptions = [
  {
    id: 'errorCharges',
    label: 'Errors',
    value: 'ERROR',
  },
  {
    id: 'failedCharges',
    label: 'Failed',
    value: 'FAILED,FAILED_RETRIES_EXHAUSTED',
  },
  {
    id: 'chargeBackCharges',
    label: 'Charged back',
    value: 'CHARGED_BACK',
  },
];

const LIMIT = 1000;

const FailedCharges = () => {
  const [chargesStatus, setChargesStatus] = useState(failedChargeOptions[0].value);
  const [charges, setCharges] = useState([]);
  const [loadingFailedCharges, setLoadingFailedCharges] = useState(false);
  const { period, selectedCountries, selectedProvider, setErrorMessage } = usePaymentsDashboard();

  const getCharges = useCallback(
    chargeStatus => {
      setLoadingFailedCharges(true);
      setErrorMessage('');

      const params = new URLSearchParams();
      params.append('period', period);
      selectedCountries.forEach(country => params.append('country', country));
      chargeStatus.split(',').forEach(status => params.append('status', status));
      if (selectedProvider) {
        params.append('provider', selectedProvider);
      }
      params.append('pageSize', LIMIT);

      httpGet(`/v1/payment/charge?${params.toString()}`)
        .then(charges => {
          setCharges(charges.content);
          setLoadingFailedCharges(false);
        })
        .catch(error => {
          setErrorMessage(error);
          setLoadingFailedCharges(false);
        });
    },
    [
      period,
      selectedCountries,
      selectedProvider,
      setLoadingFailedCharges,
      setCharges,
      setErrorMessage,
    ]
  );

  const retryChargeActions = {
    ERROR: ({ charge }) => (
      <button
        className="btn btn-primary"
        onClick={() => {
          if (charge) {
            httpPost(`/v1/payment/charge/${charge.chargeId}/trigger`).then(() => {
              getCharges('ERROR');
            });
          }
        }}
        disabled={charge.provider === 'STRIPE'}
      >
        Retry
      </button>
    ),
    CHARGED_BACK: ({ charge }) => (
      <NewPaymentLinkButton
        chargeRequest={charge}
        onComplete={() => {
          getCharges('CHARGED_BACK');
          notificationService.push({
            message: `Payment link for charge ${charge.chargeId} has been created`,
            variant: 'info',
          });
        }}
      />
    ),
  };

  useEffect(() => {
    getCharges(chargesStatus);
  }, [chargesStatus, getCharges]);

  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <b className="text-primary mx-2">
          List of {charges.length} charges (max. {LIMIT})
        </b>
        <div className="d-flex align-items-center">
          <div className="d-inline mx-3">Status: </div>
          <PaymentSelect
            value={chargesStatus}
            options={failedChargeOptions}
            onChange={setChargesStatus}
          />
        </div>
      </div>
      {charges && (
        <FailedChargesTable
          charges={charges}
          loading={loadingFailedCharges}
          action={retryChargeActions[chargesStatus]}
        />
      )}
    </>
  );
};

export default FailedCharges;
