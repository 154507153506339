import React from 'react';
import Modal from '../../common/modal/Modal';

type Props = {
  onCancel: Function,
  errorMessage: string,
};

const MoveMembershipOfferFailureModal = ({ onCancel, errorMessage }: Props) => {
  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      modalTitle="Unable to move memberships to new offer"
    >
      <p className="text-black-50">
        Unfortunately the memberships were not moved at this time, please try again later. <br />
        {errorMessage && <span className={'text-danger'}>Reason: {errorMessage}</span>}
      </p>

      <div className="mb-3 d-flex flex-wrap mx-1">
        <div className="col-sm-9 px-1" />
        <button className="btn btn-secondary col-sm-3 px-1" type="button" onClick={onCancel}>
          Close
        </button>
      </div>
    </Modal>
  );
};
export default MoveMembershipOfferFailureModal;
