import React from 'react';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';

type Props = {
  onConfirm: Function,
  onCancel: Function,
};

const RevokeMembershipModal = ({ onConfirm, onCancel }: Props) => (
  <ConfirmationModal
    isOpen={true}
    modalTitle="Warning!"
    yesButtonText="Confirm"
    noButtonText="Close"
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    <div>
      <div className="d-flex text">
        <div className="col-12 row-cols-md-3 mb-2 px-1">
          <label className="col-md-12 px-1">
            This membership has a check-in which will not be paid if you revoke the membership!
          </label>
        </div>
      </div>
    </div>
  </ConfirmationModal>
);

export default RevokeMembershipModal;
