import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import HorizontalFormRow from '../../../../components/horizontal-form/HorizontalFormRow';
import { handleErrorResponse, httpGet, httpPost, httpPut } from '../../../../../service/http';
import SpinnerContext from '../../../../common/spinner/SpinnerContext';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const verificationOptions = [
  {
    value: 'WORK_EMAIL',
    label: 'Work Email',
    description: {
      visible: false,
    },
  },
  {
    value: 'PERSONAL_EMAIL',
    label: 'Personal Email',
    description: {
      visible: false,
    },
  },
  {
    value: 'EMPLOYEE_NUMBER',
    label: 'Employee Number',
    description: {
      visible: true,
      placeholder:
        'Enter a short hint text that explains to the member where they can find their employee number(optional)',
    },
  },
];

const VerificationOptionsForm = ({ integrationScopeId }) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [verificationOption, setVerificationOption] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!integrationScopeId || verificationOption) return;
    const getIntegrationScope = async () => {
      try {
        setLoading(true);
        await httpGet(
          `/v1/additional-verification?integrationScopeId=${integrationScopeId}`,
          null,
          handleCustomError
        )
          .then(data => {
            setVerificationOption({
              id: data?.id,
              verificationOption: formatFetchResponse(data?.verificationOptions),
              employeeDescription: findDescriptionFromResponse(
                'EMPLOYEE_NUMBER',
                data?.verificationOptions
              ),
            });
          })
          .catch(error => {
            console.log(error);
          });
      } finally {
        setLoading(false);
      }
    };
    executeWithSpinner(getIntegrationScope());
  }, [integrationScopeId, verificationOption, setVerificationOption, executeWithSpinner]);

  const formatFetchResponse = response => {
    return response?.map(item => {
      return item.type;
    });
  };

  const findDescriptionFromResponse = (type, verificationOption) => {
    return verificationOption?.find(item => item.type === type)?.description || '';
  };

  const handleCustomError = response => {
    if (response.ok) {
      return response.json();
    } else if (response.status !== 404) {
      handleErrorResponse(response);
    }
    return Promise.resolve();
  };

  const pristineValidator = (initialValues, currentValues) => {
    const initialVerificationOption = initialValues?.verificationOption;
    const currentVerificationOption = currentValues?.verificationOption;

    if (initialValues.employeeDescription !== currentValues.employeeDescription) {
      return false;
    }

    if (initialVerificationOption?.length !== currentVerificationOption?.length) {
      return false;
    }
    return initialVerificationOption
      ?.slice()
      .sort()
      .every((element, index) => element === currentVerificationOption?.slice().sort()[index]);
  };

  const createRequestBody = values => {
    const { verificationOption, employeeDescription } = values;
    return {
      integrationScopeId: integrationScopeId,
      verificationOptions: verificationOption?.map(option => ({
        type: option,
        description: option === 'EMPLOYEE_NUMBER' ? employeeDescription : '',
      })),
    };
  };

  const onSubmit = async values => {
    const body = createRequestBody(values);
    const endpoint = verificationOption?.id
      ? `/v1/additional-verification/${verificationOption.id}`
      : '/v1/additional-verification';

    try {
      const data = await executeWithSpinner(
        verificationOption?.id ? httpPut(endpoint, body) : httpPost(endpoint, body)
      );

      setVerificationOption({
        id: data?.id,
        verificationOption: formatFetchResponse(data?.verificationOptions),
        employeeDescription: findDescriptionFromResponse(
          'EMPLOYEE_NUMBER',
          data?.verificationOptions
        ),
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    !loading && (
      <Form
        initialValues={verificationOption}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => {
          const customPristineChecker = pristineValidator(verificationOption, values);
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <h5>Additional verification options</h5>
                <br />
                {verificationOptions.map(option => (
                  <div key={option.value}>
                    <HorizontalFormRow>
                      <label className={`col-md-3 px-1`}>
                        <Field
                          name="verificationOption"
                          component="input"
                          type="checkbox"
                          value={option.value}
                          className={`simple-check-box me-3`}
                          disabled={!isEditable}
                        />
                        {option.label}
                      </label>
                      {option.description.visible && (
                        <div className={`col-md-6 px-1`}>
                          <Field
                            name="employeeDescription"
                            component="textarea"
                            className={`form-control`}
                            disabled={
                              !values.verificationOption?.includes(option.value) || !isEditable
                            }
                            placeholder={option.description.placeholder}
                          />
                        </div>
                      )}
                      <br />
                    </HorizontalFormRow>
                  </div>
                ))}
              </div>
              <div className="col-sm-3">
                <button
                  className="btn d-grid w-100 btn-secondary"
                  type="submit"
                  disabled={customPristineChecker || !isEditable}
                >
                  Save Verification Options
                </button>
              </div>
              <br />
            </form>
          );
        }}
      />
    )
  );
};

export default VerificationOptionsForm;
