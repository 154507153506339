import React from 'react';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';

type Props = {
  onConfirm: Function,
  onCancel: Function,
};

const UnlinkCorporateEmployeeModal = ({ onConfirm, onCancel }: Props) => (
  <ConfirmationModal
    isOpen={true}
    modalTitle="Warning!"
    yesButtonText="Confirm"
    noButtonText="Close"
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    <div>
      <div className="d-flex text">
        <div className="col-12 row-cols-md-3 mb-2 px-1">
          <label className="col-md-12 px-1">
            Are you sure you want to unlink the hris record for this user?
            <p className="mt-2 mb-0">
              Please be aware, that also all other memberships that are linked to the same HRIS
              Employee ID will be unlinked.
            </p>
          </label>
        </div>
      </div>
    </div>
  </ConfirmationModal>
);

export default UnlinkCorporateEmployeeModal;
