// @flow
import React from 'react';
import type { User } from 'app/ui/user-manager/types';

type Props = {
  user: User,
};

const UserHeader = ({ user }: Props) => (
  <h5 className="row justify-content-center w-100 fw-normal" style={{ marginTop: -45 }}>
    {user && `${user.firstName} ${user.lastName}`}
  </h5>
);

export default UserHeader;
