// @flow
import React from 'react';
import yoga from '../../styles/img/error/error-page-yoga.svg';

type Props = {
  children: any,
};

type State = {
  hasError: boolean,
};

export default class DefaultErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="row max-vh-100 justify-content-center ie-fix">
          <div className="col col-md-10 text-center text-white align-self-center mt-5 mb-5 px-1">
            <div className="row justify-content-center">
              <div className="col-sm-8 mb-4 px-1">
                <img style={{ height: '300px' }} src={yoga} alt={'Yoga'} />
              </div>
              <div className="col-sm-8 mb-4 px-1">
                <h3 className="fw-bold text-dark text-uppercase text-break">ERROR ON PAGE</h3>
              </div>
              <div className="col-sm-8 mb-4 px-1">
                <h5 className="text-dark text-break">Contact Dev team if issue continue.</h5>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return children;
    }
  }
}
