import React, { useState, useEffect } from 'react';
import styles from '../self-signup-manager/SelfSignupForm.module.scss';
import { dateToCET } from 'app/utils/date/dateUtil';
import { OfferTiersList } from './offerTiers/OfferTiersList';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  isActiveMembershipOffer,
  offerPrice,
  isFutureMembershipOffer,
} from 'app/utils/offer/offerUtil';

type Props = {
  offers: MembershipOffer[],
  withRemoveButton: Boolean,
  unlinkSignupPageAndOffer?: Function,
};

export const OfferExpandableList = ({
  offers,
  withRemoveButton,
  unlinkSignupPageAndOffer,
}: Props) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const [expandedOffer, setExpandedOffer] = useState(null);

  const toggleOfferTiers = offerId => {
    setExpandedOffer(expandedOffer === offerId ? null : offerId);
  };

  useEffect(() => {
    setExpandedOffer(offers?.length ? offers[0].id : null);
  }, [offers]);

  return (
    <>
      {offers &&
        offers.map(offer => (
          <React.Fragment key={offer.id + offer.sfAccountCanonicalId}>
            <tr
              onClick={() => toggleOfferTiers(offer.id)}
              className={classNames(styles.offerRow, {
                [styles.selectedOfferRow]: expandedOffer === offer.id,
                [styles.inactiveRow]:
                  !isActiveMembershipOffer(offer) && !isFutureMembershipOffer(offer),
              })}
            >
              <td>{offer.id}</td>
              <td>{offer.sfAccountCanonicalId}</td>
              <td>{offer.sfAccountCanonicalName}</td>
              <td>{offerPrice(offer)}</td>
              <td>{offer.type}</td>
              <td>{dateToCET(offer.availableFrom)}</td>
              <td>{dateToCET(offer.availableTo)}</td>
              <td>
                <Link
                  className="btn btn-primary"
                  to={`/companies/offer-manager/${offer.id}/details`}
                >
                  {isEditable ? 'Edit Offer' : 'View Offer'}
                </Link>
              </td>
              {withRemoveButton && (
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => unlinkSignupPageAndOffer(offer.id)}
                    disabled={!isEditable}
                  >
                    Remove
                  </button>
                </td>
              )}
            </tr>
            {expandedOffer === offer.id && (
              <OfferTiersList offerTiers={offer.membershipOfferTiers} />
            )}
          </React.Fragment>
        ))}
    </>
  );
};
