// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import { resolveErrorCode } from 'app/validation/errorCodeResolver';

import type { FormFieldProps } from 'app/ui/components/types';

const HorizontalFormDropdownField = ({
  controlId,
  controlLabel,
  columnWidth,
  helpTextAfter,
  options,
  className = 'mb-3',
  loading = false,
  disabled = false,
}: FormFieldProps) => (
  <div key={controlId} className={`col-sm-${columnWidth} px-1`}>
    <Field name={controlId}>
      {({ input, meta }) => {
        const shouldDisplayError = meta.submitError || (meta.touched && meta.error);
        return (
          <div className={className}>
            <div className="position-relative">
              <select
                {...input}
                className={`form-control${shouldDisplayError ? ' is-invalid' : ''}`}
                aria-describedby={`${controlId}-help-text-after`}
                disabled={disabled}
              >
                {options.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>

              {loading && (
                <div className="inline-dropdown-spinner">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              )}
            </div>
            {helpTextAfter && (
              <small className={`${controlId}-help-text-after`}>{helpTextAfter}</small>
            )}
            {shouldDisplayError && (
              <div className="invalid-feedback d-block">
                {resolveErrorCode(meta.submitError || meta.error, controlLabel)}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  </div>
);

export default HorizontalFormDropdownField;
