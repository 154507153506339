import { useContext, useCallback, useEffect } from 'react';
import PayoutContext, { NetworkType } from '../../context/PayoutContext';
import { networkStringRepresentation } from '../PayoutUtils';
import { useNavigate } from 'react-router-dom';

const NetworkSelectionPage = () => {
  const { setNetwork, removeNetwork } = useContext(PayoutContext);
  const navigate = useNavigate();

  useEffect(() => {
    removeNetwork();
  }, [removeNetwork]);

  const setNetowrkCb = useCallback(
    network => {
      setNetwork(network);
      navigate('overview');
    },
    [navigate, setNetwork]
  );

  return (
    <div className="flex-h">
      <div className="flex flex-column align-items-center">
        <h5 className="text-black">Choose network</h5>
        <div className="flex align-items-center">
          <button
            className="outlined-btn text-lg cursor-pointer"
            onClick={() => setNetowrkCb(NetworkType.DACH)}
          >
            {networkStringRepresentation(NetworkType.DACH)}
          </button>
          <button
            className="outlined-btn text-lg cursor-pointer"
            onClick={() => setNetowrkCb(NetworkType.FR_BE)}
          >
            {networkStringRepresentation(NetworkType.FR_BE)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NetworkSelectionPage;
