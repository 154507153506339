export const transactionStatuses = {
  NEW: {
    label: 'New',
    level: 'info',
    icon: 'fa fa-clock',
    description: `Transactions that we haven't initiated yet.`,
  },
  PROCESSING: {
    label: 'In Progress',
    level: 'info',
    icon: 'fa fa-spinner fa-spin',
    description:
      'Transactions that are currently being processed and have not yet reached a final state',
  },
  SUCCEEDED: {
    label: 'Successful',
    level: 'success',
    icon: 'fa fa-check',
    description: 'Transactions that were completed successfully and funds were processed',
  },
  ERROR: {
    label: 'Error',
    level: 'danger',
    icon: 'fa fa-times',
    description:
      'Transactions that encountered unexpected errors preventing processing (eg. missing payment method or technical issues)',
  },
  FAILED: {
    label: 'Failed',
    level: 'danger',
    icon: 'fa fa-exclamation-triangle',
    description:
      'Transactions that were attempted but could not be completed successfully due to errors or other issues on the adyen/customer side',
  },
  FAILED_RETRIES_EXHAUSTED: {
    label: 'Failed Retries Exhausted',
    level: 'danger',
    icon: 'fa fa-exclamation-triangle',
    description: 'Transactions that have failed and have exhausted all retry attempts',
  },
  CHARGED_BACK: {
    label: 'Charged Back',
    level: 'warning',
    icon: 'fa fa-undo',
    description: 'Transactions that were charged back by the customer/bank',
  },
};
