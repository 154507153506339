import * as React from 'react';
import { formatMoneyAmount } from 'app/utils/format/currencyFormatter';
import { share } from '../utils';
import { transactionStatuses } from './transactionStatuses';
import { useMemo } from 'react';
import type { Record } from './types';

type Props = {
  records: [string, Record][],
  total: Record,
};

const ChargeStatsTable = ({ records, total }: Props) => {
  const sortedRecords = useMemo(
    () =>
      records.sort(([statusA], [statusB]) => {
        const indexA = Object.keys(transactionStatuses).indexOf(statusA);
        const indexB = Object.keys(transactionStatuses).indexOf(statusB);

        if (indexA === -1) return 1; // statusA is not in transactionStatuses, move it to the end
        if (indexB === -1) return -1; // statusB is not in transactionStatuses, move it to the end

        return indexA - indexB; // sort according to the order in transactionStatuses
      }),
    [records]
  );

  return (
    <>
      <table className="table text-end mb-1">
        <thead>
          <tr>
            <th className="text-start">Status</th>
            <th>Count</th>
            <th>Share</th>
            <th>Volume</th>
            <th>Volume Share</th>
          </tr>
        </thead>
        <tbody>
          {sortedRecords.map(([status, { count, volume }]) => (
            <tr key={status}>
              <td className="text-start">{transactionStatuses[status]?.label || status}</td>
              <td>{count}</td>
              <td>{share(count, total.count)}%</td>
              <td>{formatMoneyAmount(volume)}</td>
              <td>{share(volume, total.volume)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ChargeStatsTable;
