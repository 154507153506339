import React from 'react';
import { formatISOZonedDateTimeToCETDate } from 'app/utils/format/dateTimeFormatter';
import type { SepaPaymentDetails } from './types';

type Props = {
  paymentDetails: SepaPaymentDetails,
};

const SepaPaymentMethod = ({ paymentDetails }: Props) => {
  const { ibanNumber, ownerName, createdAt } = paymentDetails;

  return (
    <div className="col-12 col-md-6">
      <div className="mb-2">
        <label className="fw-bold">IBAN:</label>
        <span className="mx-2">{ibanNumber}</span>
      </div>
      <div className="mb-2">
        <label className="fw-bold">Holder:</label>
        <span className="mx-2">{ownerName}</span>
      </div>
      <div className="mb-2">
        <label className="fw-bold">Created:</label>
        <span className="mx-2">{formatISOZonedDateTimeToCETDate(createdAt)}</span>
      </div>
    </div>
  );
};

export default SepaPaymentMethod;
