// @flow
import { NetworkType } from '../../gyms/gym/context/PayoutContext';
import { networkStringRepresentation } from '../../gyms/gym/payout/PayoutUtils';
import { Network } from '../../companies/offer-manager/offer-details/types';

export const daysDropdownOptions = (() => {
  const options = [];
  for (let day = -31; day <= 31; ++day) {
    const dayString = Math.abs(day) % 10 === 1 ? 'day' : 'days';
    options.push({ value: `${day}`, label: `${day} ${dayString}` });
  }
  return () => options;
})();

export const monthsDropdownOptions = (() => {
  const options = [];
  for (let month = -6; month <= 0; ++month) {
    const monthString = Math.abs(month) % 10 === 1 ? 'month' : 'months';
    options.push({ value: `${month}`, label: `${month} ${monthString}` });
  }
  return () => options;
})();

export const currencyDropdownOptions = (() => {
  const options = [];
  options.push({ value: 'EUR', label: 'EUR' });
  options.push({ value: 'USD', label: 'USD' });
  return () => options;
})();

export const eligibilityRulesOptions = (() => {
  const options = [{ value: '', label: 'select...' }];
  options.push({ value: 'include', label: 'include' });
  options.push({ value: 'exclude', label: 'exclude' });
  return () => options;
})();

export const allowlistIdentifierOptions = (() => {
  const options = [
    { value: 'EMAIL', label: 'EMAIL' },
    { value: 'EMPLOYEE_NUMBER', label: 'EMPLOYEE_NUMBER' },
  ];
  return () => options;
})();

export const genderDropdownOptions = (() => {
  const options = [{ value: '', label: 'Gender...' }];
  options.push({ value: 'F', label: 'Female' });
  options.push({ value: 'M', label: 'Male' });
  options.push({ value: 'D', label: 'Non-Binary' });
  return () => options;
})();

export const requestUserPaymentDetailsOptions = (() => {
  const options = [];
  options.push({ value: '', label: 'Please select an option' });
  options.push({ value: true, label: 'Yes, show payment data fields' });
  options.push({ value: false, label: 'No, hide payment data fields' });
  return () => options;
})();

export const offerTypeOptions = (
  isUserOpsMaster: false,
  isOfferEditable: true,
  network: Network
): any => {
  const options = [];
  options.push({ value: 'STANDARD', label: 'Standard' });
  if (network !== NetworkType.FR_BE) {
    options.push({ value: 'FREE', label: 'Free' });
  }
  if (isUserOpsMaster || !isOfferEditable) {
    options.push({ value: 'PLUS_ONE', label: 'Plus One' });
  }
  return options;
};

export const offerNetworkOptions = () => [
  { value: '', label: 'Please select a network' },
  { value: NetworkType.DACH, label: networkStringRepresentation(NetworkType.DACH) },
  { value: NetworkType.FR_BE, label: networkStringRepresentation(NetworkType.FR_BE) },
  { value: NetworkType.US, label: networkStringRepresentation(NetworkType.US) },
];

export const inviteColleagueEnabledOptions = (() => {
  const options = [];
  options.push({ value: true, label: 'Active' });
  options.push({ value: false, label: 'Disabled' });
  return () => options;
})();
