//@flow
import { dateTimeToDateTimeCET } from 'app/utils/date/dateUtil';
import { downloadFileFromUrl } from 'app/utils/file/fileUtil';
import React from 'react';
import { Link } from 'react-router-dom';
import UploadTaskReportLink from './components/UploadTaskReportLink';
import RevertCheckinsConfirmationModal from './components/RevertCheckinsConfirmationModal';

export type Task = {
  id: string,
  state: string,
  failureReason: string,
  uploadedFileName: String,
  persistedCheckinsCount: number,
  failedCheckinsCount: number,
  createdAt: string,
  completedAt: string,
};

type Props = {
  tasks: Array<Task>,
  onClickRevertTask: Function,
};

const UploadTasksTable = ({ tasks, onClickRevertTask }: Props) => {
  const [isRevertCheckinsConfirmationModalOpen, setRevertCheckinsConfirmationModalOpen] =
    React.useState(false);

  const [taskSelected, setTaskSelected] = React.useState(null);

  const downloadCheckins = (taskId, filename, exportedFileName) => {
    downloadFileFromUrl(`/v1/checkin/upload-task/${taskId}/export/${filename}`, exportedFileName);
  };
  const openRevertCheckinsDialog = task => {
    setTaskSelected(task);
    setRevertCheckinsConfirmationModalOpen(true);
  };
  const onConfirmRevertCheckinsDialog = () => {
    closeRevertCheckinsDialog();
    onClickRevertTask(taskSelected);
  };
  const closeRevertCheckinsDialog = () => {
    setRevertCheckinsConfirmationModalOpen(false);
    setTaskSelected(null);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Submitted file</th>
            <th>Created at</th>
            <th>State</th>
            <th>Reports</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id}>
              <td>
                <Link
                  to="#"
                  onClick={() => downloadCheckins(task.id, 'submitted.csv', task.uploadedFileName)}
                >
                  {task.uploadedFileName}
                </Link>
              </td>
              <td>{dateTimeToDateTimeCET(task.createdAt)}</td>
              <td>
                {task.state}
                &nbsp;
                {task.failedCheckinsCount > 0 && task.state === 'COMPLETED' && (
                  <i
                    className="fa fa-exclamation-triangle warning-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Some checkins had errors. Please check the failed report."
                  />
                )}
                {task.state === 'FAILED' && (
                  <i
                    className="fas fa-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={task.failureReason}
                  />
                )}
              </td>
              <td>
                {task.persistedCheckinsCount !== 0 && (
                  <UploadTaskReportLink
                    className="success-report-link"
                    taskId={task.id}
                    reportMessage={`${task.persistedCheckinsCount} check-ins uploaded`}
                    filename={'successful.csv'}
                    exportedFilename={`Report_success_${task.uploadedFileName}`}
                  />
                )}
                {task.failedCheckinsCount !== 0 && (
                  <UploadTaskReportLink
                    className="failed-report-link"
                    taskId={task.id}
                    reportMessage={`${task.failedCheckinsCount} check-ins failed`}
                    filename={'failed.csv'}
                    exportedFilename={`Report_failed_${task.uploadedFileName}`}
                  />
                )}
              </td>
              <td>
                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={task.state !== 'COMPLETED' || task.persistedCheckinsCount <= 0}
                  onClick={() => openRevertCheckinsDialog(task)}
                >
                  Revert
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isRevertCheckinsConfirmationModalOpen && (
        <RevertCheckinsConfirmationModal
          task={taskSelected}
          onConfirm={onConfirmRevertCheckinsDialog}
          onCancel={closeRevertCheckinsDialog}
        />
      )}
    </>
  );
};

export default UploadTasksTable;
