import React from 'react';
import Modal from '../../../../../common/modal/Modal';
import EligibilityRulesForm from '../EligibilityRulesForm/EligibilityRulesForm';

const EligibilityRulesModal = ({ isOpen, onClose, integrationScope, onAction }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      modalTitle={'Step2/4: Fill Eligibility Rules'}
      modalSizeClass={'modal-lg'}
    >
      <EligibilityRulesForm
        integrationScope={integrationScope}
        redirect={onAction}
        styles={'justify-content-end'}
        forceEnableSubmit={true}
      />
    </Modal>
  );
};

export default EligibilityRulesModal;
