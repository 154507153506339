//@flow
import React, { useEffect, useState, useCallback } from 'react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import TextFormField from '../../components/TextFormField';
import HorizontalFormRow from '../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from '../../components/horizontal-form/HorizontalFormLabel';
import HorizontalFormTextField from '../../components/horizontal-form/HorizontalFormTextField';
import { validate } from './validator';
import { notificationService } from 'app/service/notification';
import LinkedSignupPageItem from './LinkedSignupPage';
import FileUploadFormField from '../../components/FileUploadFormField';
import type { LinkedSignupPageFrontendModel } from '../../types';
import {
  convertFrontendModelToMultiEntitySignupPage,
  convertMultiEntitySignupPageToFrontendModel,
} from './converter';
import { generateRandomToken } from 'app/utils/random-generator/randomGenerator';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type Props = {
  multiEntitySignupPage: MultiEntitySignupPage,
  getMultiEntitySignupPageBySlugFn: (slug: string) => MultiEntitySignupPage,
  getSignupPageByTokenFn: (token: string) => SignupPage,
  onSubmit: (multiEntitySignupPage: MultiEntitySignupPage) => void,
};

const MultiEntitySignupPageForm = ({
  multiEntitySignupPage,
  getMultiEntitySignupPageBySlugFn,
  getSignupPageByTokenFn,
  onSubmit,
}: Props) => {
  const [formModel, setFormModel] = useState({});
  const [pageId, setPageId] = useState(multiEntitySignupPage?.id);
  const [savedSlugModified, setSavedSlugModified] = useState(false);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  useEffect(() => {
    if (multiEntitySignupPage) {
      setFormModel(convertMultiEntitySignupPageToFrontendModel(multiEntitySignupPage));
      setPageId(multiEntitySignupPage.id);
    }
  }, [multiEntitySignupPage]);

  const generateSlug = useCallback(() => {
    return generateRandomToken(30);
  }, []);

  const onSubmitForm = useCallback(
    newFormModel => {
      if (onSubmit) {
        onSubmit(
          convertFrontendModelToMultiEntitySignupPage(newFormModel),
          newFormModel.logo?.file
        );
      }
    },
    [onSubmit]
  );

  const validateSlug = useCallback(
    async value => {
      if (value) {
        const existingPage = await getMultiEntitySignupPageBySlugFn(value);
        if (existingPage && pageId !== existingPage.id) {
          notificationService.push({
            message: 'There is already another Multi-Entity Signup Page with the same slug',
            variant: 'error',
          });
        } else {
          notificationService.push({
            message: 'Valid slug',
            variant: 'success',
          });
        }
      }
    },
    [getMultiEntitySignupPageBySlugFn, pageId]
  );

  const isSignupPageTokenPresent = useCallback(
    (token: string) => {
      return formModel.linkedSignupPages.findIndex(e => e.token === token) > -1;
    },
    [formModel]
  );

  const updateLinkedSignupPagesModel = useCallback(
    (model: LinkedSignupPageFrontendModel, index: number) => {
      const newLinkedSignupPages = formModel.linkedSignupPages;
      newLinkedSignupPages[index] = model;
      setFormModel({
        ...formModel,
        linkedSignupPages: newLinkedSignupPages,
      });
    },
    [formModel]
  );

  const validateLinkedSignupPage = useCallback(
    async (model: LinkedSignupPageFrontendModel, index: number) => {
      if (!model?.token || isSignupPageTokenPresent(model.token)) {
        return;
      }

      const signupPage = await getSignupPageByTokenFn(model.token);
      if (!signupPage) {
        model.isValid = false;
      } else {
        model.label = signupPage.name;
        model.isValid = true;
      }

      updateLinkedSignupPagesModel(model, index);
    },
    [getSignupPageByTokenFn, isSignupPageTokenPresent, updateLinkedSignupPagesModel]
  );

  return (
    <div className="mt-4">
      <Form
        onSubmit={onSubmitForm}
        validate={values => validate(values)}
        mutators={{
          ...arrayMutators,
          setSlug: (args, state, { changeValue }) => {
            changeValue(state, 'slug', generateSlug);
          },
        }}
        initialValues={{
          ...formModel,
        }}
        render={({
          handleSubmit,
          submitting,
          values,
          form: {
            mutators: { push, setSlug },
            change,
          },
        }) => (
          <form onSubmit={handleSubmit}>
            <h4 className="text-secondary md-2">
              {pageId ? 'Update multi entity sign up page' : 'Create multi entity sign up page'}
            </h4>

            {/* Name */}
            <div className="d-flex col-12 mb-2 px-1">
              <label className="col-md-4 px-3">Name</label>
              <div className="col-md-6 px-3">
                <TextFormField controlId="name" label="Name" validateFields={['name']} />
              </div>
              <div className="col-md-2 mb-4 px-3">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={setSlug}
                  disabled={!isEditable}
                >
                  Generate slug
                </button>
              </div>
            </div>

            {/* Slug */}
            <div className="d-flex col-12 px-1 row-cols-md-4 mb-2">
              <label className="col-md-4 px-3">Slug</label>
              <div className="col-md-6 px-3">
                <TextFormField controlId="slug" label="Slug" />
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values }) => {
                    if (
                      multiEntitySignupPage &&
                      multiEntitySignupPage.id &&
                      multiEntitySignupPage.slug
                    ) {
                      setSavedSlugModified(values.slug !== multiEntitySignupPage.slug);
                    }
                  }}
                />
                {savedSlugModified && (
                  <div className="mt-3 offset-0">
                    <div className="alert alert-warning ">
                      <p className="mb-0">
                        <strong>IMPORTANT!</strong>
                      </p>
                      <p>
                        The old link will not forward users to this new link when you change it.
                        Make sure the old link hasn’t been shared with the company yet - or they
                        must replace it everywhere!
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-2 mb-4 px-3">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => validateSlug(values.slug)}
                  disabled={!isEditable}
                >
                  Validate slug
                </button>
              </div>
            </div>

            {/* Link to the multi entity signup page with slug */}
            {multiEntitySignupPage.slug && (
              <div className="offset-4">
                <div className="d-flex col-12 px-1 mb-2">
                  <div className="alert alert-success col-md-12 px-3">
                    <span>
                      <a
                        className="d-block"
                        target="blank"
                        href={`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup/${multiEntitySignupPage.slug}`}
                      >
                        Link to the Multi Entity Signup Page
                      </a>
                      {`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup/${multiEntitySignupPage.slug}`}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Dropdown Label */}
            <div className="d-flex col-12 px-1 mb-2">
              <label className="col-md-4 px-3">Dropdown Label</label>
              <div className="col-md-6 px-3">
                <TextFormField
                  controlId="dropdownLabel"
                  helpTextAfter="Example: subsidiary"
                  disabled={!isEditable}
                />
              </div>
            </div>

            {/* Logo */}
            <div className="d-flex col-12 px-1 mb-2">
              <label className="col-md-4 px-3">Company Logo</label>
              <div className="col-md-8 px-3">
                <FileUploadFormField
                  controlId="logo"
                  helpTextAfter="Please check that the logo matches the given requirements: The logo should
                        be white with transparent background and min. 360 pixels wide (.png .svg or .jpg format)"
                  accept="image/jpeg, image/jpg, image/svg+xml, image/png"
                  onupdatefiles={event => {
                    const files = event.target.files;
                    if (files.length > 0) {
                      change('logo.url', null);
                      change('logo.file', files[0]);
                      formModel.logo.url = null;
                      formModel.logo.file = files[0];
                    } else {
                      change('logo.url', null);
                      change('logo.file', null);
                      formModel.logo.url = null;
                      formModel.logo.file = null;
                    }
                  }}
                  disabled={!isEditable}
                />
              </div>
            </div>

            {/* Expiration */}
            <div className="d-flex col-12 px-1 mb-2">
              <label className="col-md-4 px-3">Expiration</label>
              <div className="mt-3 offset-0 col-md-8 px-3">
                <div className="alert alert-warning ">
                  <HorizontalFormRow>
                    <HorizontalFormLabel controlLabel="Expiration timestamp CET" columnWidth="4" />
                    <HorizontalFormTextField
                      controlId="expiration"
                      controlLabel="Expiration timestamp"
                      placeholder="DD-MM-YYYY HH:mm"
                      columnWidth="6"
                      disabled={!isEditable}
                    />
                  </HorizontalFormRow>
                  <p className="mb-0">
                    <strong>IMPORTANT!</strong>
                  </p>
                  <p>
                    After this timestamp, the multi-entity signup page will show an error until it
                    is manually re-activated again.
                  </p>
                </div>
              </div>
            </div>

            {/* Linked signup pages */}
            <div className="mb-2">
              <label className="col-md-4 px-1">Linked signup pages</label>
            </div>

            <FieldArray name="linkedSignupPages">
              {({ fields }) =>
                fields.map((name, index) => (
                  <LinkedSignupPageItem
                    key={name}
                    name={name}
                    index={index}
                    model={fields.value[index]}
                    validate={model => validateLinkedSignupPage(model, index)}
                    remove={() => {
                      fields.remove(index);
                      formModel.linkedSignupPages.splice(index, 1);
                    }}
                  />
                ))
              }
            </FieldArray>

            {/* Add new linked signup page */}
            <div className="row">
              <div className="flex offset-4 col-md-8 px-1">
                <button
                  className="btn btn-secondary ms-3"
                  type="button"
                  onClick={() => push('linkedSignupPages', { isValid: false })}
                  disabled={!isEditable}
                >
                  Add new link page
                </button>
              </div>
            </div>

            <button
              className="btn btn-secondary me-2 mb-4"
              type="submit"
              disabled={submitting || !isEditable}
            >
              Save
            </button>
          </form>
        )}
      />
    </div>
  );
};

export default MultiEntitySignupPageForm;
