import * as React from 'react';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import { useCallback, useEffect } from 'react';
import { httpGet, httpPatch } from '../../../../service/http';
import { downloadBlob } from '../../../../utils/file/fileUtil';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import { allowlistIdentifierOptions } from '../../../common/util/dropdownUtil';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

const EditAllowlist = () => {
  const sfAccountCanonicalId = useParams().sfAccountCanonicalId;
  const { executeWithSpinner, isSpinnerShown } = React.useContext(SpinnerContext);
  const [allowlistCount, setAllowlistCount] = React.useState(0);
  const [integrationScope, setIntegrationScope] = React.useState();

  const getIntegrationScope = useCallback(async () => {
    try {
      if (!sfAccountCanonicalId) {
        return;
      }
      const integrationScope = await httpGet(
        `/v1/integration-scope?externalId=${sfAccountCanonicalId}`
      );
      if (!integrationScope) {
        return;
      }
      setIntegrationScope(integrationScope);
      setAllowlistCount(integrationScope?.eligibleEmployeesCount);
      return integrationScope;
    } catch (error) {
      console.error('Error fetching the latest integration scope', error);
    }
  }, [sfAccountCanonicalId]);

  useEffect(() => {
    const fetchCompanyAndScope = async () => {
      getIntegrationScope();
    };
    executeWithSpinner(fetchCompanyAndScope());
  }, [sfAccountCanonicalId, executeWithSpinner, getIntegrationScope]);

  const downloadEmployees = async () => {
    try {
      if (!sfAccountCanonicalId) {
        return;
      }
      const response = await fetch(
        `/v1/integration-scope/allowlist?externalId=${sfAccountCanonicalId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/octet-stream',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      downloadBlob(blob, 'allowlist.csv');
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const onSubmit = async values => {
    if (values.allowlistIdentifier !== integrationScope?.allowlistIdentifier) {
      const patchBody = {
        allowlistIdentifierType: values.allowlistIdentifier,
      };
      const updatedIntegrationScope = await httpPatch(
        `/v1/integration-scope/${integrationScope?.id}`,
        patchBody
      );
      setIntegrationScope(updatedIntegrationScope);
    }
  };

  if (isSpinnerShown) {
    return <div />;
  }

  return (
    <Form
      initialValues={{ allowlistIdentifier: integrationScope?.allowlistIdentifierType }}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div>
              <h4 className="mt-4 mb-3">Allowlist</h4>
              <h6>General Info</h6>
              <div className="row mt-3">
                <label className="col-md-3 px-1">CurrentAllowlist</label>
                <label className="col-md-3 px-1">{allowlistCount} employees</label>
                <button
                  className="col-md-2 btn btn-primary px-1"
                  type="button"
                  onClick={downloadEmployees}
                >
                  Download
                </button>
              </div>
              <div className="d-flex mt-2">
                <label className="col-md-3 px-1">Identifier</label>
                <HorizontalFormDropdownField
                  controlId="allowlistIdentifier"
                  controlLabel="allowlistIdentifier"
                  options={allowlistIdentifierOptions()}
                  columnWidth={4}
                  disabled={allowlistCount > 0}
                />
              </div>
              <h6>Edit</h6>
              <div className="d-flex mt-2 d-flex align-items-center">
                <label className="col-md-3 px-1">Update Allowlist</label>
                <a
                  className="col-md-3 col-lg-4 col-xl-2 px-1 btn btn-primary"
                  href={`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/nav/admin/allowlist?companyId=${sfAccountCanonicalId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Go to Company-Portal
                </a>
              </div>
              <div className="col-sm-2">
                <button
                  className="btn d-grid w-100 btn-secondary mt-2 px-1"
                  type="submit"
                  disabled={pristine}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

export default EditAllowlist;
