// @flow
import * as React from 'react';
import type { MembershipOfferFrontendModel } from '../../../types';

type OfferContextState = {
  offer: MembershipOfferFrontendModel | undefined,
  setOffer: (offer: MembershipOfferFrontendModel | undefined) => void,
};

const OfferContext = React.createContext<OfferContextState>({
  offer: undefined,
  setOffer: () => {},
});

const OfferContextProvider = ({ children }) => {
  const [offer, setOffer] = React.useState<MembershipOfferFrontendModel | undefined>(undefined);

  const contextValue = React.useMemo(() => ({ offer, setOffer }), [offer]);

  return <OfferContext.Provider value={contextValue}>{children}</OfferContext.Provider>;
};

export { OfferContextProvider };
export default OfferContext;
