import React from 'react';

type Props = {
  options: { label: string, value: string }[],
  selected: string[],
  onChange: (string[]) => void,
};

const CheckboxGroup = ({ options, selected, onChange }: Props) => {
  const handleCheckboxChange = e => {
    const { value, checked } = e.target;
    const newSelected = checked ? [...selected, value] : selected.filter(item => item !== value);
    if (onChange) {
      onChange(newSelected);
    }
  };

  if (!options.length || !selected) {
    return null;
  }

  return (
    <div className="d-inline-flex">
      {options.map(option => (
        <div key={option.value} className="form-check form-switch mx-2">
          <input
            className="form-check-input"
            type="checkbox"
            id={`flexSwitchCheckDefault${option.value}`}
            value={option.value}
            checked={selected.includes(option.value)}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label" form={`flexSwitchCheckDefault${option.value}`}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;
