import React, { useCallback } from 'react';

const PaymentSelect = ({ options, value, onChange }) => {
  const handleChange = useCallback(
    e => {
      const selectedValue = e.target.value;
      const selectedOption = options.find(option => option.value === selectedValue);
      if (selectedOption) {
        onChange(selectedOption.value);
      }
    },
    [onChange, options]
  );

  return (
    <div className="d-flex">
      <select
        className="form-select rounded-0 border-0 border-bottom"
        onChange={handleChange}
        value={value}
      >
        {options.map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PaymentSelect;
