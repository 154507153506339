import * as React from 'react';
import TextFormField from '../../components/TextFormField';
import type { LinkedSignupPageFrontendModel } from '../../types';
import { Link } from 'react-router-dom';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type Props = {
  name: string,
  model: LinkedSignupPageFrontendModel,
  validate: (model: LinkedSignupPageFrontendModel) => void,
  remove: Function,
};

const LinkedSignupPageItem = ({ name, model, validate, remove }: Props) => {
  const isSignupPageTokenValid = React.useCallback(() => {
    return model?.isValid;
  }, [model?.isValid]);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  return (
    <>
      <div className="row">
        <div className="flex offset-4 col-md-8 px-1">
          {/* Label */}
          <div className="col-md-4 px-1">
            <TextFormField
              placeholder="Label"
              controlId={`${name}.label`}
              disabled={!isSignupPageTokenValid()}
              validateFields={[`${name}.label`]}
            />
          </div>
          {/* Company token */}
          <div className="col-md-4 px-1">
            <TextFormField
              placeholder="Company Token"
              controlId={`${name}.token`}
              disabled={isSignupPageTokenValid()}
            />
          </div>
          {/* Validate linked signup page */}
          <div className="col-md-2 px-1">
            {isSignupPageTokenValid() ? (
              <Link target={'_blank'} to={`/companies/self-signup-manager?token=${model.token}`}>
                Signup Page Link
              </Link>
            ) : (
              <button
                className="btn btn-secondary me-2"
                type="button"
                onClick={() => validate(model)}
              >
                Validate
              </button>
            )}
          </div>
          {/* Remove linked signup page */}
          <div className="col-md-2 px-1">
            <button
              className="btn btn-primary me-2"
              type="button"
              onClick={() => remove()}
              disabled={!isEditable}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkedSignupPageItem;
