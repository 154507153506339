// @flow
import * as React from 'react';
import { useState, useCallback, useContext } from 'react';
import { httpGet, httpPost } from 'app/service/http/index';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import moment from 'moment-timezone';
import { dateTimeToDateTimeCET } from 'app/utils/date/dateUtil';
import { useNavigate } from 'react-router-dom';
import PayoutContext from '../../context/PayoutContext';

type Props = {
  step: any,
};

const PayoutCalculationPage = ({ step }: Props) => {
  const { network } = useContext(PayoutContext);
  const [runPeriod, setRunPeriod] = useState(moment().format('YYYY-MM'));
  const [nonFinalCalculations, setNonFinalCalculations] = useState(null);
  const [finalCalculations, setFinalCalculations] = useState(null);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [errorResult, setErrorResult] = useState(null);
  const [periodDropdownOptions, setPeriodDropdownOptions] = useState([]);
  const navigate = useNavigate();

  const sortByPeriod = useCallback((a, b) => {
    if (a.period < b.period) {
      return 1;
    } else if (a.period > b.period) {
      return -1;
    } else {
      return sortByCreatedAt(a, b);
    }
  }, []);

  const sortByCreatedAt = (a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    } else if (a.createdAt > b.createdAt) {
      return -1;
    } else {
      return 0;
    }
  };

  const processCalculationResponse = useCallback(
    responseCalculations => {
      const calculations = responseCalculations ? responseCalculations : [];

      const finalCalculations = calculations
        ? calculations.filter(c => c.payoutConfirmedAt).sort(sortByPeriod)
        : null;
      setFinalCalculations(finalCalculations);

      const nonFinalCalculations = calculations
        .filter(c => !c.payoutConfirmedAt)
        .sort(sortByPeriod);
      setNonFinalCalculations(nonFinalCalculations);
    },
    [sortByPeriod]
  );

  React.useEffect(() => {
    const searchPayoutCalculations = network => {
      return executeWithSpinner(
        httpGet(`/v1/payout/calculation?network=${network}`)
          .then(responseCalculations => {
            processCalculationResponse(responseCalculations);
          })
          .catch(error => {
            setErrorResult(error && error.message);
          })
      );
    };
    network && searchPayoutCalculations(network);
  }, [executeWithSpinner, sortByPeriod, processCalculationResponse, network]);

  React.useEffect(() => {
    const calcPeriodDropdownOptions = () => {
      const result = [];
      for (let i = 0; i < 6; i++) {
        const period = moment().subtract(i, 'month').format('YYYY-MM');
        result.push(period);
      }

      setPeriodDropdownOptions(result);
    };
    calcPeriodDropdownOptions();
  }, [finalCalculations]);

  const startCalculationRun = () => {
    executeWithSpinner(
      httpPost(`/v1/payout/calculation/run`, {
        network,
        period: runPeriod,
      })
        .then(calculation_run_id =>
          navigate(
            `/gyms/payout-calculation/${calculation_run_id}/${step.to_fetch_salesforce_data}`
          )
        )
        .catch(error => {
          setErrorResult(error && error.message);
        })
    );
  };

  const startCalculationRunAndCopyAdjustments = (copyFrom, period) => {
    executeWithSpinner(
      httpPost(`/v1/payout/calculation/run`, {
        network,
        period: period,
        calculationIdForAdjustmentCopy: copyFrom,
      }).then(() => {
        executeWithSpinner(
          httpGet(`/v1/payout/calculation?network=${network}`)
            .then(responseCalculations => {
              processCalculationResponse(responseCalculations);
            })
            .catch(error => {
              setErrorResult(error && error.message);
            })
        );
      })
    );
  };

  const goReviewPayoutCalculation = calculationRunId => {
    navigate(`/gyms/payout-calculation/${calculationRunId}/${step.to_payout_result}`);
  };

  return (
    <>
      <div className="mb-3 d-flex flex-wrap mx-1">
        <div className="col-12 px-1">
          {errorResult && <div className="alert alert-primary">{errorResult}</div>}
          {periodDropdownOptions && periodDropdownOptions.length > 0 && (
            <div className="d-flex flex-wrap mx-1 mt-2 mb-3">
              <div className="col-3 me-3 px-1">
                <select
                  id="selectPeriodId"
                  className="form-control"
                  placeholder="Period"
                  onChange={event => setRunPeriod(event.target.value)}
                >
                  {periodDropdownOptions.map(period => (
                    <option key={period}>{period}</option>
                  ))}
                </select>
              </div>
              <button className="btn btn-primary col-8 px-1" onClick={startCalculationRun}>
                Start new payout calculation run
              </button>
            </div>
          )}
          {nonFinalCalculations && nonFinalCalculations.length > 0 && (
            <div className="d-flex flex-wrap mx-1 mt-2">
              <table className="table">
                <thead>
                  <tr>
                    <th>Payout Period</th>
                    <th>Payout Run ID</th>
                    <th>Created Timestamp</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {nonFinalCalculations.map(c => (
                    <tr key={c.id}>
                      <td>{c.period}</td>
                      <td>{c.id}</td>
                      <td>{dateTimeToDateTimeCET(c.createdAt)}</td>
                      <td>
                        {c.dockerImage ? (
                          <button
                            onClick={() =>
                              navigate(`/gyms/payout-calculation/${c.id}/${step.to_payout_result}`)
                            }
                            className="btn btn-primary"
                          >
                            View
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              navigate(`/gyms/payout-calculation/${c.id}/${step.to_adjust_revenue}`)
                            }
                            className="btn btn-primary"
                          >
                            Continue
                          </button>
                        )}
                      </td>
                      <td>
                        {c.id && (
                          <button
                            className="btn btn-primary"
                            onClick={() => startCalculationRunAndCopyAdjustments(c.id, c.period)}
                          >
                            Copy revenues to new calculation run
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {finalCalculations &&
            finalCalculations.map(c => (
              <div className="row mt-2" key={c.id}>
                <label className="col-3 px-1">{c.period}</label>
                <button
                  className="btn btn-secondary col-8 px-1"
                  onClick={() => goReviewPayoutCalculation(c.id)}
                >
                  Review Payout Calculation
                </button>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default PayoutCalculationPage;
