//@flow
import React from 'react';
import { withPageTemplate } from '../../layout/PageTemplate';

const AddMembersMultipleOfferInfoForm = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="fw-bold mb-1">
            <span className="text-primary me-1">ADD MEMBERSHIPS TO MULTIPLE OFFERS</span>
          </div>
          <div className="mb-2">
            <span className="text-secondary me-2">
              With this tool you can upload members or memberships to multiple offers of multiple
              companies. It should be used with caution since validation rules do not cover every
              potential edge case. The csv to upload members/memberships can maximum contain 100
              entries. We do not validate if the given offerID is still active or if the related
              company has still an active contract with us. If you create memberships where B2C
              payment is required but the users don't have payment details set, please inform those
              users to set payment details here https://app.qualitrain.net/payment-details and
              ensure they do so till the next payment cycle.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default withPageTemplate(AddMembersMultipleOfferInfoForm, {
  headerTitle: 'Batch Upload Memberships',
});
