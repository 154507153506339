// @flow
import * as React from 'react';
import { httpGet, httpDelete } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { formatISOZonedDateTimeToZonedDateTime } from 'app/utils/format/dateTimeFormatter';
import { useState } from 'react';
import moment from 'moment';
import {
  openDeleteCheckinModal,
  closeDeleteCheckinModal,
  deleteCheckinSuccess,
  deleteCheckinFailure,
} from 'app/ui/user-manager/user/checkins/actions';
import { initialValues, reducer } from 'app/ui/user-manager/user/checkins/reducer';
import DeleteCheckinModal from './DeleteCheckinModal';
import AuthenticationContext from '../../../common/authentication/AuthenticationContext';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from '../../../common/authentication/roles';
import { useParams } from 'react-router-dom';

const CheckinsPage = () => {
  const { userId } = useParams();
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [state, dispatch] = React.useReducer(reducer, initialValues);
  const [checkins, setCheckins] = useState(null);
  const [errorResult, setErrorResult] = useState(null);
  const [lastPayout, setLastPayout] = useState(null);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);

  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  const loadCheckins = () => {
    const startDateParam = encodeURIComponent('1970-01-02T15:04:05Z');
    const endDateParam = encodeURIComponent('2200-01-02T15:04:05Z');
    const url = `/v1/user/${userId}/checkin/history?start=${startDateParam}&end=${endDateParam}`;
    const lastPayoutUrl = `/v1/payout/calculation/latest-final/info`;

    executeWithSpinner(
      httpGet(lastPayoutUrl)
        .then(lastPayoutResponse => setLastPayout(lastPayoutResponse))
        .catch(error => {
          if (error && error.status === 404) {
            setLastPayout(null);
          } else {
            setErrorResult(error && error.message);
          }
        }),
      httpGet(url)
        .then(checkinsResponse => setCheckins(checkinsResponse.items))
        .catch(error => setErrorResult(error && error.message))
    );
  };

  const deleteCheckin = (checkinId: number) => {
    executeWithSpinner(
      httpDelete(`/v1/user/${userId}/checkin/${checkinId}`)
        .then(() => {
          dispatch(deleteCheckinSuccess(checkinId));
          loadCheckins();
        })
        .catch(error => dispatch(deleteCheckinFailure(error && error.message)))
    );
  };

  React.useEffect(loadCheckins, [executeWithSpinner, userId]);

  const canDeleteCheckin = function (checkin, lastPayout) {
    const checkinTime = moment(checkin.timestamp);
    return (
      (checkin.source === 'QT_OPS_ADMIN_PAPER_LIST' || checkin.source === 'QT_EGYM_ONE_MMS') &&
      moment(lastPayout.period).endOf('month').isBefore(checkinTime)
    );
  };

  return (
    <>
      {checkins && (
        <table className="table">
          <thead>
            <tr>
              <th>Check-in Details</th>
              <th>Gym Name</th>
              <th>Gym Address</th>
              <th>Source</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {checkins.map(checkin => (
              <tr key={checkin.timestamp}>
                <td>
                  {formatISOZonedDateTimeToZonedDateTime(
                    checkin.timestamp,
                    checkin.gymLocation.timezone
                  )}
                </td>
                <td>{checkin.gymLocation.name}</td>
                <td>{checkin.gymLocation.address}</td>
                <td>{checkin.source}</td>
                <td>
                  {lastPayout && canDeleteCheckin(checkin, lastPayout) && isEditable && (
                    <button
                      type="button"
                      className="btn d-grid w-100 btn-secondary"
                      onClick={() => dispatch(openDeleteCheckinModal(checkin.uuid))}
                    >
                      Delete checkin
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div>{errorResult && <div className="alert alert-primary">{errorResult}</div>}</div>
      {state.isDeleteCheckinModalOpen && (
        <DeleteCheckinModal
          isOpen={true}
          onConfirm={() => deleteCheckin(state.checkinIdForDelete)}
          onCancel={() => dispatch(closeDeleteCheckinModal)}
        />
      )}
    </>
  );
};

export default CheckinsPage;
